/* eslint-disable camelcase */
import { ASPECT_RATIO } from '../../../../components/Ecommerce/Ecwid/Custom/constants';
import Ecommerce from '../../../ecommerce/ecwid/custom';
import dom from '../../../wrapper/DomWrapper';
import { getProductMinHeight } from '../../Ecommerce/ecwid/custom/ProductsWidget';
import SingleProduct from '../../Ecommerce/ecwid/custom/SingleProduct';
import { getImageSize } from '../../Ecommerce/ecwid/custom/utils';

import { mountItemsInView, mountWidgetsInView } from './utils';

const preInit = async () => ({
  provider: Ecommerce.provider,
});

const afterInit = (elContainer) => {
  mountWidgetsInView(elContainer);
};

/**
 * this - LayoutController bound to the function
 * @returns product
 */
// eslint-disable-next-line func-style
function preRender(product, index, elContainer) { // don't use arrow func, this func called with context
  const {
    layoutSettings: {
      grid: { items_per_row } = {},
      layoutType,
    },
  } = this.settings;
  let imageSize = 'imageOriginalUrl';

  if (elContainer && layoutType === 'grid' && items_per_row) {
    imageSize = getImageSize(dom.getElementWidth(elContainer) / items_per_row);
  }

  return {
    product,
    withGallery: product.images.length > 1,
    imageSize,
  };
}

// eslint-disable-next-line func-style
function postRender(element, product, index) { // don't use arrow func, this func called with context
  (new SingleProduct(dom.getElement('div.product', element), product)).init(index);

  return element;
}

// eslint-disable-next-line no-unused-vars,func-style
function didMount(elContainer) { // don't use arrow func, this func called with context
  const {
    layoutSettings: {
      grid: { total_rows = 1 } = {},
      aspectRatio,
      display,
    },
  } = this.settings;
  const width = this.getElementWidth();
  const minHeight = total_rows * getProductMinHeight(width, display, ASPECT_RATIO[aspectRatio]);

  dom.updateStyle(elContainer, { minHeight: `${minHeight}px` });
  mountItemsInView(elContainer);
}

export default {
  preInit,
  afterInit,
  preRender,
  postRender,
  didMount,
};
