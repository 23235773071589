import isEmpty from 'lodash/isEmpty';

import dom from '../../../wrapper/DomWrapper';

import {
  GALLERY_CONTAINER_SELECTOR,
  IMAGE_CONTAINER_SELECTOR,
  IMAGE_GALLERY_CONTAINER_SELECTOR,
  IMAGE_INNER_BORDER_SELECTOR, IMAGE_SELECTOR,
} from './constants';

const convertBorderRadius = (container, borderRadius = 0) => {
  const containerWidth = dom.getElementWidth(container);
  const containerHeight = dom.getElementHeight(container);

  return Math.min(containerWidth, containerHeight) * (borderRadius / 100);
};

/**
 * Util for setting inline border radius to an image container
 * Shows element after calculation
 * @param {Element} image
 */
export const setImageBorderRadius = (image) => {
  if (!image) return;

  const container = image.closest(IMAGE_CONTAINER_SELECTOR);

  if (!container || !container.dataset?.settings) return;

  const settings = JSON.parse(container.dataset?.settings);

  if (isEmpty(settings)) return;

  const { borderRadius } = settings;

  if (!borderRadius) return;

  const innerBorder = dom.getElement(IMAGE_INNER_BORDER_SELECTOR, container);
  const borderRadiusInPx = convertBorderRadius(container, borderRadius);

  dom.updateStyle(container, { borderRadius: `${borderRadiusInPx}px` });
  if (innerBorder) dom.updateStyle(innerBorder, { borderRadius: `${borderRadiusInPx}px` });

  dom.showOpacity(container);
};

/**
 * Util for rechecking border size (used on resize event)
 */
export const recalculateImageBorder = () => {
  const elImages = dom.getCollection(IMAGE_SELECTOR);

  if (isEmpty(elImages)) return;

  elImages.forEach((image) => {
    setImageBorderRadius(image);
  });
};

export const setGalleryImageBorderRadius = (image) => {
  if (!image) return;

  const gallery = image.closest(GALLERY_CONTAINER_SELECTOR);
  const wrapper = image.closest(IMAGE_GALLERY_CONTAINER_SELECTOR);

  if (!gallery || !gallery.dataset?.settings) return;

  const settings = JSON.parse(gallery.dataset?.settings);

  if (isEmpty(settings)) return;

  const { borderRadius, showCaption } = settings;

  if (!borderRadius) return;

  const borderRadiusInPx = convertBorderRadius(wrapper, borderRadius);

  dom.updateStyle(wrapper, {
    borderRadius: `${borderRadiusInPx}px`,
    margin: (borderRadius > 0 && showCaption) ? '8px' : null,
  });
};
