export default {
  // #region SHARED STRINGS
  // sh.te => shared strings / text editor
  'sh.te.input-placeholder': 'Write your text here',
  'sh.vr.file-wrapper-validation_not-allowed-ext':
    'File extension not allowed.\nPlease use .svg, .jpg, .png, .bmp.',
  'sh.vr.file-wrapper-validation_size_limit':
    'File size exceeds allowed limit.',
  'sh.vr.file-wrapper-validation_img_dimension_min':
    'Sorry, your image is too small!\nMin available {{dimension}} pixels.',
  'sh.vr.file-wrapper-validation_img_dimension_max':
    'Sorry, your image is too big!\nMax available {{dimension}} pixels.',

  // #region SHARED STRINGS: MEMORY UNITS
  'sh.mu.bytes': 'Bytes',
  'sh.mu.kb': 'KB',
  'sh.mu.mb': 'MB',
  'sh.mu.gb': 'GB',
  'sh.mu.tb': 'TB',
  // #endregion SHARED STRINGS: MEMORY UNITS

  // #region BACKEND VALIDATION
  'sh.be.DOMAIN_NOT_FOUND': 'User domain not found',
  'sh.be.DOMAIN_LENGTH_ERROR':
    'Site address is too long (maximum is 63 characters).',
  'sh.be.CANT_CANCEL_DOMAIN_CHANGE': 'Cannot cancel change of domain name',
  'sh.be.DOMAIN_NAME_TAKEN': 'This domain name is already taken.',
  'sh.be.CANT_CHANGE_DOMAIN_NAME':
    'Cannot change domain name due to time limit restriction.',
  'sh.be.DOMAIN_VALIDATION_LENGTH':
    'Domain name must be more than 3 characters.',
  'sh.be.DOMAIN_EXISTS': 'Domain already exists',
  'sh.be.CANT_DELETE_NOT_CUSTOM_DOMAIN':
    'Only custom domain could be deleted',
  'sh.be.NOT_VALID': 'Please enter a real domain name.',
  // #endregion SHARED STRINGS

  // #region SHARED STRINGS: link styles
  'sh.fi.link-style_1': 'Style 1',
  'sh.fi.link-style_2': 'Style 2',
  'sh.fi.link-style_3': 'Style 3',
  'sh.fi.link-style_4': 'Style 4',
  'sh.fi.link-style_5': 'Style 5',
  'sh.fi.link-style_6': 'Style 6',
  // #endregion SHARED STRINGS: link styles

  // #region SHARED STRINGS: quote styles
  'sh.fi.quote-style_1': 'Style 1',
  'sh.fi.quote-style_2': 'Style 2',
  'sh.fi.quote-style_3': 'Style 3',
  'sh.fi.quote-style_4': 'Style 4',
  'sh.fi.quote-style_5': 'Style 5',
  'sh.fi.quote-style_6': 'Style 6',
  'sh.fi.quote-style_7': 'Style 7',
  'sh.fi.quote-style_8': 'Style 8',
  'sh.fi.quote-style_9': 'Style 9',
  'sh.fi.quote-style_10': 'Style 10',
  'sh.fi.quote-style_11': 'Style 11',
  'sh.fi.quote-style_12': 'Style 12',
  'sh.fi.quote-style_13': 'Style 13',
  'sh.fi.quote-style_14': 'Style 14',
  'sh.fi.quote-style_15': 'Style 15',
  'sh.fi.quote-style_16': 'Style 16',
  'sh.fi.quote-style_17': 'Style 17',
  'sh.fi.quote-style_18': 'Style 18',
  'sh.fi.quote-style_19': 'Style 19',
  'sh.fi.quote-style_20': 'Style 20',
  // #endregion SHARED STRINGS: quote styles

  // #region SHARED STRINGS: CURRENCIES

  'sh.fi.currencies_USD': 'US Dollar',
  'sh.fi.currencies_USD_plural': 'US dollars',
  'sh.fi.currencies_CAD': 'Canadian Dollar',
  'sh.fi.currencies_CAD_plural': 'Canadian dollars',
  'sh.fi.currencies_EUR': 'Euro',
  'sh.fi.currencies_EUR_plural': 'euros',
  'sh.fi.currencies_AED': 'United Arab Emirates Dirham',
  'sh.fi.currencies_AED_plural': 'UAE dirhams',
  'sh.fi.currencies_AFN': 'Afghan Afghani',
  'sh.fi.currencies_AFN_plural': 'Afghan Afghanis',
  'sh.fi.currencies_ALL': 'Albanian Lek',
  'sh.fi.currencies_ALL_plural': 'Albanian lekë',
  'sh.fi.currencies_AMD': 'Armenian Dram',
  'sh.fi.currencies_AMD_plural': 'Armenian drams',
  'sh.fi.currencies_ARS': 'Argentine Peso',
  'sh.fi.currencies_ARS_plural': 'Argentine pesos',
  'sh.fi.currencies_AUD': 'Australian Dollar',
  'sh.fi.currencies_AUD_plural': 'Australian dollars',
  'sh.fi.currencies_AZN': 'Azerbaijani Manat',
  'sh.fi.currencies_AZN_plural': 'Azerbaijani manats',
  'sh.fi.currencies_BAM': 'Bosnia-Herzegovina Convertible Mark',
  'sh.fi.currencies_BAM_plural': 'Bosnia-Herzegovina convertible marks',
  'sh.fi.currencies_BDT': 'Bangladeshi Taka',
  'sh.fi.currencies_BDT_plural': 'Bangladeshi takas',
  'sh.fi.currencies_BGN': 'Bulgarian Lev',
  'sh.fi.currencies_BGN_plural': 'Bulgarian leva',
  'sh.fi.currencies_BHD': 'Bahraini Dinar',
  'sh.fi.currencies_BHD_plural': 'Bahraini dinars',
  'sh.fi.currencies_BIF': 'Burundian Franc',
  'sh.fi.currencies_BIF_plural': 'Burundian francs',
  'sh.fi.currencies_BND': 'Brunei Dollar',
  'sh.fi.currencies_BND_plural': 'Brunei dollars',
  'sh.fi.currencies_BOB': 'Bolivian Boliviano',
  'sh.fi.currencies_BOB_plural': 'Bolivian bolivianos',
  'sh.fi.currencies_BRL': 'Brazilian Real',
  'sh.fi.currencies_BRL_plural': 'Brazilian reals',
  'sh.fi.currencies_BWP': 'Botswanan Pula',
  'sh.fi.currencies_BWP_plural': 'Botswanan pulas',
  'sh.fi.currencies_BYR': 'Belarusian Ruble',
  'sh.fi.currencies_BYR_plural': 'Belarusian rubles',
  'sh.fi.currencies_BZD': 'Belize Dollar',
  'sh.fi.currencies_BZD_plural': 'Belize dollars',
  'sh.fi.currencies_CDF': 'Congolese Franc',
  'sh.fi.currencies_CDF_plural': 'Congolese francs',
  'sh.fi.currencies_CHF': 'Swiss Franc',
  'sh.fi.currencies_CHF_plural': 'Swiss francs',
  'sh.fi.currencies_CLP': 'Chilean Peso',
  'sh.fi.currencies_CLP_plural': 'Chilean pesos',
  'sh.fi.currencies_CNY': 'Chinese Yuan',
  'sh.fi.currencies_CNY_plural': 'Chinese yuan',
  'sh.fi.currencies_COP': 'Colombian Peso',
  'sh.fi.currencies_COP_plural': 'Colombian pesos',
  'sh.fi.currencies_CRC': 'Costa Rican Colón',
  'sh.fi.currencies_CRC_plural': 'Costa Rican colóns',
  'sh.fi.currencies_CVE': 'Cape Verdean Escudo',
  'sh.fi.currencies_CVE_plural': 'Cape Verdean escudos',
  'sh.fi.currencies_CZK': 'Czech Republic Koruna',
  'sh.fi.currencies_CZK_plural': 'Czech Republic korunas',
  'sh.fi.currencies_DJF': 'Djiboutian Franc',
  'sh.fi.currencies_DJF_plural': 'Djiboutian francs',
  'sh.fi.currencies_DKK': 'Danish Krone',
  'sh.fi.currencies_DKK_plural': 'Danish kroner',
  'sh.fi.currencies_DOP': 'Dominican Peso',
  'sh.fi.currencies_DOP_plural': 'Dominican pesos',
  'sh.fi.currencies_DZD': 'Algerian Dinar',
  'sh.fi.currencies_DZD_plural': 'Algerian dinars',
  'sh.fi.currencies_EEK': 'Estonian Kroon',
  'sh.fi.currencies_EEK_plural': 'Estonian kroons',
  'sh.fi.currencies_EGP': 'Egyptian Pound',
  'sh.fi.currencies_EGP_plural': 'Egyptian pounds',
  'sh.fi.currencies_ERN': 'Eritrean Nakfa',
  'sh.fi.currencies_ERN_plural': 'Eritrean nakfas',
  'sh.fi.currencies_ETB': 'Ethiopian Birr',
  'sh.fi.currencies_ETB_plural': 'Ethiopian birrs',
  'sh.fi.currencies_GBP': 'British Pound Sterling',
  'sh.fi.currencies_GBP_plural': 'British pounds sterling',
  'sh.fi.currencies_GEL': 'Georgian Lari',
  'sh.fi.currencies_GEL_plural': 'Georgian laris',
  'sh.fi.currencies_GHS': 'Ghanaian Cedi',
  'sh.fi.currencies_GHS_plural': 'Ghanaian cedis',
  'sh.fi.currencies_GNF': 'Guinean Franc',
  'sh.fi.currencies_GNF_plural': 'Guinean francs',
  'sh.fi.currencies_GTQ': 'Guatemalan Quetzal',
  'sh.fi.currencies_GTQ_plural': 'Guatemalan quetzals',
  'sh.fi.currencies_HKD': 'Hong Kong Dollar',
  'sh.fi.currencies_HKD_plural': 'Hong Kong dollars',
  'sh.fi.currencies_HNL': 'Honduran Lempira',
  'sh.fi.currencies_HNL_plural': 'Honduran lempiras',
  'sh.fi.currencies_HRK': 'Croatian Kuna',
  'sh.fi.currencies_HRK_plural': 'Croatian kunas',
  'sh.fi.currencies_HUF': 'Hungarian Forint',
  'sh.fi.currencies_HUF_plural': 'Hungarian forints',
  'sh.fi.currencies_IDR': 'Indonesian Rupiah',
  'sh.fi.currencies_IDR_plural': 'Indonesian rupiahs',
  'sh.fi.currencies_ILS': 'Israeli New Sheqel',
  'sh.fi.currencies_ILS_plural': 'Israeli new sheqels',
  'sh.fi.currencies_INR': 'Indian Rupee',
  'sh.fi.currencies_INR_plural': 'Indian rupees',
  'sh.fi.currencies_IQD': 'Iraqi Dinar',
  'sh.fi.currencies_IQD_plural': 'Iraqi dinars',
  'sh.fi.currencies_IRR': 'Iranian Rial',
  'sh.fi.currencies_IRR_plural': 'Iranian rials',
  'sh.fi.currencies_ISK': 'Icelandic Króna',
  'sh.fi.currencies_ISK_plural': 'Icelandic krónur',
  'sh.fi.currencies_JMD': 'Jamaican Dollar',
  'sh.fi.currencies_JMD_plural': 'Jamaican dollars',
  'sh.fi.currencies_JOD': 'Jordanian Dinar',
  'sh.fi.currencies_JOD_plural': 'Jordanian dinars',
  'sh.fi.currencies_JPY': 'Japanese Yen',
  'sh.fi.currencies_JPY_plural': 'Japanese yen',
  'sh.fi.currencies_KES': 'Kenyan Shilling',
  'sh.fi.currencies_KES_plural': 'Kenyan shillings',
  'sh.fi.currencies_KHR': 'Cambodian Riel',
  'sh.fi.currencies_KHR_plural': 'Cambodian riels',
  'sh.fi.currencies_KMF': 'Comorian Franc',
  'sh.fi.currencies_KMF_plural': 'Comorian francs',
  'sh.fi.currencies_KRW': 'South Korean Won',
  'sh.fi.currencies_KRW_plural': 'South Korean won',
  'sh.fi.currencies_KWD': 'Kuwaiti Dinar',
  'sh.fi.currencies_KWD_plural': 'Kuwaiti dinars',
  'sh.fi.currencies_KZT': 'Kazakhstani Tenge',
  'sh.fi.currencies_KZT_plural': 'Kazakhstani tenges',
  'sh.fi.currencies_LBP': 'Lebanese Pound',
  'sh.fi.currencies_LBP_plural': 'Lebanese pounds',
  'sh.fi.currencies_LKR': 'Sri Lankan Rupee',
  'sh.fi.currencies_LKR_plural': 'Sri Lankan rupees',
  'sh.fi.currencies_LTL': 'Lithuanian Litas',
  'sh.fi.currencies_LTL_plural': 'Lithuanian litai',
  'sh.fi.currencies_LVL': 'Latvian Lats',
  'sh.fi.currencies_LVL_plural': 'Latvian lati',
  'sh.fi.currencies_LYD': 'Libyan Dinar',
  'sh.fi.currencies_LYD_plural': 'Libyan dinars',
  'sh.fi.currencies_MAD': 'Moroccan Dirham',
  'sh.fi.currencies_MAD_plural': 'Moroccan dirhams',
  'sh.fi.currencies_MDL': 'Moldovan Leu',
  'sh.fi.currencies_MDL_plural': 'Moldovan lei',
  'sh.fi.currencies_MGA': 'Malagasy Ariary',
  'sh.fi.currencies_MGA_plural': 'Malagasy Ariaries',
  'sh.fi.currencies_MKD': 'Macedonian Denar',
  'sh.fi.currencies_MKD_plural': 'Macedonian denari',
  'sh.fi.currencies_MMK': 'Myanmar Kyat',
  'sh.fi.currencies_MMK_plural': 'Myanmar kyats',
  'sh.fi.currencies_MOP': 'Macanese Pataca',
  'sh.fi.currencies_MOP_plural': 'Macanese patacas',
  'sh.fi.currencies_MUR': 'Mauritian Rupee',
  'sh.fi.currencies_MUR_plural': 'Mauritian rupees',
  'sh.fi.currencies_MXN': 'Mexican Peso',
  'sh.fi.currencies_MXN_plural': 'Mexican pesos',
  'sh.fi.currencies_MYR': 'Malaysian Ringgit',
  'sh.fi.currencies_MYR_plural': 'Malaysian ringgits',
  'sh.fi.currencies_MZN': 'Mozambican Metical',
  'sh.fi.currencies_MZN_plural': 'Mozambican meticals',
  'sh.fi.currencies_NAD': 'Namibian Dollar',
  'sh.fi.currencies_NAD_plural': 'Namibian dollars',
  'sh.fi.currencies_NGN': 'Nigerian Naira',
  'sh.fi.currencies_NGN_plural': 'Nigerian nairas',
  'sh.fi.currencies_NIO': 'Nicaraguan Córdoba',
  'sh.fi.currencies_NIO_plural': 'Nicaraguan córdobas',
  'sh.fi.currencies_NOK': 'Norwegian Krone',
  'sh.fi.currencies_NOK_plural': 'Norwegian kroner',
  'sh.fi.currencies_NPR': 'Nepalese Rupee',
  'sh.fi.currencies_NPR_plural': 'Nepalese rupees',
  'sh.fi.currencies_NZD': 'New Zealand Dollar',
  'sh.fi.currencies_NZD_plural': 'New Zealand dollars',
  'sh.fi.currencies_OMR': 'Omani Rial',
  'sh.fi.currencies_OMR_plural': 'Omani rials',
  'sh.fi.currencies_PAB': 'Panamanian Balboa',
  'sh.fi.currencies_PAB_plural': 'Panamanian balboas',
  'sh.fi.currencies_PEN': 'Peruvian Nuevo Sol',
  'sh.fi.currencies_PEN_plural': 'Peruvian nuevos soles',
  'sh.fi.currencies_PHP': 'Philippine Peso',
  'sh.fi.currencies_PHP_plural': 'Philippine pesos',
  'sh.fi.currencies_PKR': 'Pakistani Rupee',
  'sh.fi.currencies_PKR_plural': 'Pakistani rupees',
  'sh.fi.currencies_PLN': 'Polish Zloty',
  'sh.fi.currencies_PLN_plural': 'Polish zlotys',
  'sh.fi.currencies_PYG': 'Paraguayan Guarani',
  'sh.fi.currencies_PYG_plural': 'Paraguayan guaranis',
  'sh.fi.currencies_QAR': 'Qatari Rial',
  'sh.fi.currencies_QAR_plural': 'Qatari rials',
  'sh.fi.currencies_RON': 'Romanian Leu',
  'sh.fi.currencies_RON_plural': 'Romanian lei',
  'sh.fi.currencies_RSD': 'Serbian Dinar',
  'sh.fi.currencies_RSD_plural': 'Serbian dinars',
  'sh.fi.currencies_RUB': 'Russian Ruble',
  'sh.fi.currencies_RUB_plural': 'Russian rubles',
  'sh.fi.currencies_RWF': 'Rwandan Franc',
  'sh.fi.currencies_RWF_plural': 'Rwandan francs',
  'sh.fi.currencies_SAR': 'Saudi Riyal',
  'sh.fi.currencies_SAR_plural': 'Saudi riyals',
  'sh.fi.currencies_SDG': 'Sudanese Pound',
  'sh.fi.currencies_SDG_plural': 'Sudanese pounds',
  'sh.fi.currencies_SEK': 'Swedish Krona',
  'sh.fi.currencies_SEK_plural': 'Swedish kronor',
  'sh.fi.currencies_SGD': 'Singapore Dollar',
  'sh.fi.currencies_SGD_plural': 'Singapore dollars',
  'sh.fi.currencies_SOS': 'Somali Shilling',
  'sh.fi.currencies_SOS_plural': 'Somali shillings',
  'sh.fi.currencies_SYP': 'Syrian Pound',
  'sh.fi.currencies_SYP_plural': 'Syrian pounds',
  'sh.fi.currencies_THB': 'Thai Baht',
  'sh.fi.currencies_THB_plural': 'Thai baht',
  'sh.fi.currencies_TND': 'Tunisian Dinar',
  'sh.fi.currencies_TND_plural': 'Tunisian dinars',
  'sh.fi.currencies_TOP': 'Tongan Paʻanga',
  'sh.fi.currencies_TOP_plural': 'Tongan paʻanga',
  'sh.fi.currencies_TRY': 'Turkish Lira',
  'sh.fi.currencies_TRY_plural': 'Turkish Lira',
  'sh.fi.currencies_TTD': 'Trinidad and Tobago Dollar',
  'sh.fi.currencies_TTD_plural': 'Trinidad and Tobago dollars',
  'sh.fi.currencies_TWD': 'New Taiwan Dollar',
  'sh.fi.currencies_TWD_plural': 'New Taiwan dollars',
  'sh.fi.currencies_TZS': 'Tanzanian Shilling',
  'sh.fi.currencies_TZS_plural': 'Tanzanian shillings',
  'sh.fi.currencies_UAH': 'Ukrainian Hryvnia',
  'sh.fi.currencies_UAH_plural': 'Ukrainian hryvnias',
  'sh.fi.currencies_UGX': 'Ugandan Shilling',
  'sh.fi.currencies_UGX_plural': 'Ugandan shillings',
  'sh.fi.currencies_UYU': 'Uruguayan Peso',
  'sh.fi.currencies_UYU_plural': 'Uruguayan pesos',
  'sh.fi.currencies_UZS': 'Uzbekistan Som',
  'sh.fi.currencies_UZS_plural': 'Uzbekistan som',
  'sh.fi.currencies_VEF': 'Venezuelan Bolívar',
  'sh.fi.currencies_VEF_plural': 'Venezuelan bolívars',
  'sh.fi.currencies_VND': 'Vietnamese Dong',
  'sh.fi.currencies_VND_plural': 'Vietnamese dong',
  'sh.fi.currencies_XAF': 'CFA Franc BEAC',
  'sh.fi.currencies_XAF_plural': 'CFA francs BEAC',
  'sh.fi.currencies_XOF': 'CFA Franc BCEAO',
  'sh.fi.currencies_XOF_plural': 'CFA francs BCEAO',
  'sh.fi.currencies_YER': 'Yemeni Rial',
  'sh.fi.currencies_YER_plural': 'Yemeni rials',
  'sh.fi.currencies_ZAR': 'South African Rand',
  'sh.fi.currencies_ZAR_plural': 'South African rand',
  'sh.fi.currencies_ZMK': 'Zambian Kwacha',
  'sh.fi.currencies_ZMK_plural': 'Zambian kwachas',

  // #endregion SHARED STRINGS: CURRENCIES

  // #region SHARED STRINGS: COUNTRIES
  'sh.fi.country.af': 'Afghanistan',
  'sh.fi.country.ax': 'Aland',
  'sh.fi.country.al': 'Albania',
  'sh.fi.country.dz': 'Algeria',
  'sh.fi.country.as': 'American Samoa',
  'sh.fi.country.ad': 'Andorra',
  'sh.fi.country.ao': 'Angola',
  'sh.fi.country.ai': 'Anguilla',
  'sh.fi.country.aq': 'Antarctica',
  'sh.fi.country.ag': 'Antigua and Barbuda',
  'sh.fi.country.ar': 'Argentina',
  'sh.fi.country.am': 'Armenia',
  'sh.fi.country.aw': 'Aruba',
  'sh.fi.country.au': 'Australia',
  'sh.fi.country.at': 'Austria',
  'sh.fi.country.az': 'Azerbaijan',
  'sh.fi.country.bs': 'Bahamas',
  'sh.fi.country.bh': 'Bahrain',
  'sh.fi.country.bd': 'Bangladesh',
  'sh.fi.country.bb': 'Barbados',
  'sh.fi.country.by': 'Belarus',
  'sh.fi.country.be': 'Belgium',
  'sh.fi.country.bz': 'Belize',
  'sh.fi.country.bj': 'Benin',
  'sh.fi.country.bm': 'Bermuda',
  'sh.fi.country.bt': 'Bhutan',
  'sh.fi.country.bo': 'Bolivia',
  'sh.fi.country.bq': 'Bonaire',
  'sh.fi.country.ba': 'Bosnia and Herzegovina',
  'sh.fi.country.bw': 'Botswana',
  'sh.fi.country.bv': 'Bouvet Island',
  'sh.fi.country.br': 'Brazil',
  'sh.fi.country.io': 'British Indian Ocean Territory',
  'sh.fi.country.vg': 'British Virgin Islands',
  'sh.fi.country.bn': 'Brunei',
  'sh.fi.country.bg': 'Bulgaria',
  'sh.fi.country.bf': 'Burkina Faso',
  'sh.fi.country.bi': 'Burundi',
  'sh.fi.country.kh': 'Cambodia',
  'sh.fi.country.cm': 'Cameroon',
  'sh.fi.country.ca': 'Canada',
  'sh.fi.country.cv': 'Cape Verde',
  'sh.fi.country.ky': 'Cayman Islands',
  'sh.fi.country.cf': 'Central African Republic',
  'sh.fi.country.td': 'Chad',
  'sh.fi.country.cl': 'Chile',
  'sh.fi.country.cn': 'China',
  'sh.fi.country.cx': 'Christmas Island',
  'sh.fi.country.cc': 'Cocos (Keeling) Islands',
  'sh.fi.country.co': 'Colombia',
  'sh.fi.country.km': 'Comoros',
  'sh.fi.country.ck': 'Cook Islands',
  'sh.fi.country.cr': 'Costa Rica',
  'sh.fi.country.hr': 'Croatia',
  'sh.fi.country.cu': 'Cuba',
  'sh.fi.country.cw': 'Curacao',
  'sh.fi.country.cy': 'Cyprus',
  'sh.fi.country.cz': 'Czech Republic',
  'sh.fi.country.cd': 'Democratic Republic of the Congo',
  'sh.fi.country.dk': 'Denmark',
  'sh.fi.country.dj': 'Djibouti',
  'sh.fi.country.dm': 'Dominica',
  'sh.fi.country.do': 'Dominican Republic',
  'sh.fi.country.tl': 'East Timor',
  'sh.fi.country.ec': 'Ecuador',
  'sh.fi.country.eg': 'Egypt',
  'sh.fi.country.sv': 'El Salvador',
  'sh.fi.country.gq': 'Equatorial Guinea',
  'sh.fi.country.er': 'Eritrea',
  'sh.fi.country.ee': 'Estonia',
  'sh.fi.country.et': 'Ethiopia',
  'sh.fi.country.fk': 'Falkland Islands',
  'sh.fi.country.fo': 'Faroe Islands',
  'sh.fi.country.fj': 'Fiji',
  'sh.fi.country.fi': 'Finland',
  'sh.fi.country.fr': 'France',
  'sh.fi.country.gf': 'French Guiana',
  'sh.fi.country.pf': 'French Polynesia',
  'sh.fi.country.tf': 'French Southern Territories',
  'sh.fi.country.ga': 'Gabon',
  'sh.fi.country.gm': 'Gambia',
  'sh.fi.country.ge': 'Georgia',
  'sh.fi.country.de': 'Germany',
  'sh.fi.country.gh': 'Ghana',
  'sh.fi.country.gi': 'Gibraltar',
  'sh.fi.country.gr': 'Greece',
  'sh.fi.country.gl': 'Greenland',
  'sh.fi.country.gd': 'Grenada',
  'sh.fi.country.gp': 'Guadeloupe',
  'sh.fi.country.gu': 'Guam',
  'sh.fi.country.gt': 'Guatemala',
  'sh.fi.country.gg': 'Guernsey',
  'sh.fi.country.gn': 'Guinea',
  'sh.fi.country.gw': 'Guinea-Bissau',
  'sh.fi.country.gy': 'Guyana',
  'sh.fi.country.ht': 'Haiti',
  'sh.fi.country.hm': 'Heard Island and McDonald Islands',
  'sh.fi.country.hn': 'Honduras',
  'sh.fi.country.hk': 'Hong Kong',
  'sh.fi.country.hu': 'Hungary',
  'sh.fi.country.is': 'Iceland',
  'sh.fi.country.in': 'India',
  'sh.fi.country.id': 'Indonesia',
  'sh.fi.country.ir': 'Iran',
  'sh.fi.country.iq': 'Iraq',
  'sh.fi.country.ie': 'Ireland',
  'sh.fi.country.im': 'Isle of Man',
  'sh.fi.country.il': 'Israel',
  'sh.fi.country.it': 'Italy',
  'sh.fi.country.ci': 'Ivory Coast',
  'sh.fi.country.jm': 'Jamaica',
  'sh.fi.country.jp': 'Japan',
  'sh.fi.country.je': 'Jersey',
  'sh.fi.country.jo': 'Jordan',
  'sh.fi.country.kz': 'Kazakhstan',
  'sh.fi.country.ke': 'Kenya',
  'sh.fi.country.ki': 'Kiribati',
  'sh.fi.country.xk': 'Kosovo',
  'sh.fi.country.kw': 'Kuwait',
  'sh.fi.country.kg': 'Kyrgyzstan',
  'sh.fi.country.la': 'Laos',
  'sh.fi.country.lv': 'Latvia',
  'sh.fi.country.lb': 'Lebanon',
  'sh.fi.country.ls': 'Lesotho',
  'sh.fi.country.lr': 'Liberia',
  'sh.fi.country.ly': 'Libya',
  'sh.fi.country.li': 'Liechtenstein',
  'sh.fi.country.lt': 'Lithuania',
  'sh.fi.country.lu': 'Luxembourg',
  'sh.fi.country.mo': 'Macao',
  'sh.fi.country.mk': 'Macedonia',
  'sh.fi.country.mg': 'Madagascar',
  'sh.fi.country.mw': 'Malawi',
  'sh.fi.country.my': 'Malaysia',
  'sh.fi.country.mv': 'Maldives',
  'sh.fi.country.ml': 'Mali',
  'sh.fi.country.mt': 'Malta',
  'sh.fi.country.mh': 'Marshall Islands',
  'sh.fi.country.mq': 'Martinique',
  'sh.fi.country.mr': 'Mauritania',
  'sh.fi.country.mu': 'Mauritius',
  'sh.fi.country.yt': 'Mayotte',
  'sh.fi.country.mx': 'Mexico',
  'sh.fi.country.fm': 'Micronesia',
  'sh.fi.country.md': 'Moldova',
  'sh.fi.country.mc': 'Monaco',
  'sh.fi.country.mn': 'Mongolia',
  'sh.fi.country.me': 'Montenegro',
  'sh.fi.country.ms': 'Montserrat',
  'sh.fi.country.ma': 'Morocco',
  'sh.fi.country.mz': 'Mozambique',
  'sh.fi.country.mm': 'Myanmar (Burma)',
  'sh.fi.country.na': 'Namibia',
  'sh.fi.country.nr': 'Nauru',
  'sh.fi.country.np': 'Nepal',
  'sh.fi.country.nl': 'Netherlands',
  'sh.fi.country.nc': 'New Caledonia',
  'sh.fi.country.nz': 'New Zealand',
  'sh.fi.country.ni': 'Nicaragua',
  'sh.fi.country.ne': 'Niger',
  'sh.fi.country.ng': 'Nigeria',
  'sh.fi.country.nu': 'Niue',
  'sh.fi.country.nf': 'Norfolk Island',
  'sh.fi.country.kp': 'North Korea',
  'sh.fi.country.mp': 'Northern Mariana Islands',
  'sh.fi.country.no': 'Norway',
  'sh.fi.country.om': 'Oman',
  'sh.fi.country.pk': 'Pakistan',
  'sh.fi.country.pw': 'Palau',
  'sh.fi.country.ps': 'Palestine',
  'sh.fi.country.pa': 'Panama',
  'sh.fi.country.pg': 'Papua New Guinea',
  'sh.fi.country.py': 'Paraguay',
  'sh.fi.country.pe': 'Peru',
  'sh.fi.country.ph': 'Philippines',
  'sh.fi.country.pn': 'Pitcairn Islands',
  'sh.fi.country.pl': 'Poland',
  'sh.fi.country.pt': 'Portugal',
  'sh.fi.country.pr': 'Puerto Rico',
  'sh.fi.country.qa': 'Qatar',
  'sh.fi.country.cg': 'Republic of the Congo',
  'sh.fi.country.ro': 'Romania',
  'sh.fi.country.ru': 'Russia',
  'sh.fi.country.rw': 'Rwanda',
  'sh.fi.country.re': 'Réunion',
  'sh.fi.country.bl': 'Saint Barthélemy',
  'sh.fi.country.sh': 'Saint Helena',
  'sh.fi.country.kn': 'Saint Kitts and Nevis',
  'sh.fi.country.lc': 'Saint Lucia',
  'sh.fi.country.mf': 'Saint Martin',
  'sh.fi.country.pm': 'Saint Pierre and Miquelon',
  'sh.fi.country.vc': 'Saint Vincent and the Grenadines',
  'sh.fi.country.ws': 'Samoa',
  'sh.fi.country.sm': 'San Marino',
  'sh.fi.country.sa': 'Saudi Arabia',
  'sh.fi.country.sn': 'Senegal',
  'sh.fi.country.rs': 'Serbia',
  'sh.fi.country.sc': 'Seychelles',
  'sh.fi.country.sl': 'Sierra Leone',
  'sh.fi.country.sg': 'Singapore',
  'sh.fi.country.sx': 'Sint Maarten',
  'sh.fi.country.sk': 'Slovakia',
  'sh.fi.country.si': 'Slovenia',
  'sh.fi.country.sb': 'Solomon Islands',
  'sh.fi.country.so': 'Somalia',
  'sh.fi.country.za': 'South Africa',
  'sh.fi.country.gs': 'South Georgia and the South Sandwich Islands',
  'sh.fi.country.kr': 'South Korea',
  'sh.fi.country.ss': 'South Sudan',
  'sh.fi.country.es': 'Spain',
  'sh.fi.country.lk': 'Sri Lanka',
  'sh.fi.country.sd': 'Sudan',
  'sh.fi.country.sr': 'Suriname',
  'sh.fi.country.sj': 'Svalbard and Jan Mayen',
  'sh.fi.country.sz': 'Swaziland',
  'sh.fi.country.se': 'Sweden',
  'sh.fi.country.ch': 'Switzerland',
  'sh.fi.country.sy': 'Syria',
  'sh.fi.country.st': 'São Tomé and Príncipe',
  'sh.fi.country.tw': 'Taiwan',
  'sh.fi.country.tj': 'Tajikistan',
  'sh.fi.country.tz': 'Tanzania',
  'sh.fi.country.th': 'Thailand',
  'sh.fi.country.tg': 'Togo',
  'sh.fi.country.tk': 'Tokelau',
  'sh.fi.country.to': 'Tonga',
  'sh.fi.country.tt': 'Trinidad and Tobago',
  'sh.fi.country.tn': 'Tunisia',
  'sh.fi.country.tr': 'Turkey',
  'sh.fi.country.tm': 'Turkmenistan',
  'sh.fi.country.tc': 'Turks and Caicos Islands',
  'sh.fi.country.tv': 'Tuvalu',
  'sh.fi.country.um': 'U.S. Minor Outlying Islands',
  'sh.fi.country.vi': 'U.S. Virgin Islands',
  'sh.fi.country.ug': 'Uganda',
  'sh.fi.country.ua': 'Ukraine',
  'sh.fi.country.ae': 'United Arab Emirates',
  'sh.fi.country.gb': 'United Kingdom',
  'sh.fi.country.us': 'United States',
  'sh.fi.country.uy': 'Uruguay',
  'sh.fi.country.uz': 'Uzbekistan',
  'sh.fi.country.vu': 'Vanuatu',
  'sh.fi.country.va': 'Vatican City',
  'sh.fi.country.ve': 'Venezuela',
  'sh.fi.country.vn': 'Vietnam',
  'sh.fi.country.wf': 'Wallis and Futuna',
  'sh.fi.country.eh': 'Western Sahara',
  'sh.fi.country.ye': 'Yemen',
  'sh.fi.country.zm': 'Zambia',
  'sh.fi.country.zw': 'Zimbabwe',
  // #endregion

  // #region SHARED STRINGS: TIMEZONES
  'sh.fi.tz.Pacific/Niue': '(GMT-11:00) Niue',
  'sh.fi.tz.Pacific/Pago_Pago': '(GMT-11:00) Pago Pago',
  'sh.fi.tz.Pacific/Midway': '(GMT-11:00) Midway Island',
  'sh.fi.tz.Pacific/Samoa': '(GMT-11:00) Samoa',
  'sh.fi.tz.Pacific/Honolulu': '(GMT-10:00) Hawaii',
  'sh.fi.tz.Pacific/Tahiti': '(GMT-10:00) Tahiti',
  'sh.fi.tz.Pacific/Rarotonga': '(GMT-10:00) Rarotonga',
  'sh.fi.tz.Pacific/Marquesas': '(GMT-09:30) Marquesas',
  'sh.fi.tz.US/Alaska': '(GMT-09:00) Alaska',
  'sh.fi.tz.Pacific/Gambier': '(GMT-09:00) Gambier',
  'sh.fi.tz.America/Anchorage': '(GMT-09:00) Alaska Time',
  'sh.fi.tz.America/Tijuana': '(GMT-08:00) Pacific Time - Tijuana',
  'sh.fi.tz.America/Los_Angeles': '(GMT-08:00) Pacific Time',
  'sh.fi.tz.America/Vancouver': '(GMT-08:00) Pacific Time - Vancouver',
  'sh.fi.tz.America/Whitehorse': '(GMT-08:00) Pacific Time - Whitehorse',
  'sh.fi.tz.Pacific/Pitcairn': '(GMT-08:00) Pitcairn',
  'sh.fi.tz.America/Dawson_Creek': '(GMT-07:00) Mountain Time - Dawson Creek',
  'sh.fi.tz.America/Edmonton': '(GMT-07:00) Mountain Time - Edmonton',
  'sh.fi.tz.America/Yellowknife': '(GMT-07:00) Mountain Time - Yellowknife',
  'sh.fi.tz.US/Mountain': '(GMT-07:00) Mountain Time (US &amp; Canada)',
  'sh.fi.tz.America/Mazatlan':
    '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
  'sh.fi.tz.America/Chihuahua': '(GMT-07:00) Chihuahua',
  'sh.fi.tz.US/Arizona': '(GMT-07:00) Arizona',
  'sh.fi.tz.America/Hermosillo': '(GMT-07:00) Mountain Time - Hermosillo',
  'sh.fi.tz.America/Denver': '(GMT-07:00) Mountain Time',
  'sh.fi.tz.America/Phoenix': '(GMT-07:00) Mountain Time - Arizona',
  'sh.fi.tz.America/Costa_Rica': '(GMT-06:00) Costa Rica',
  'sh.fi.tz.Pacific/Galapagos': '(GMT-06:00) Galapagos',
  'sh.fi.tz.America/Tegucigalpa': '(GMT-06:00) Central Time - Tegucigalpa',
  'sh.fi.tz.America/Regina': '(GMT-06:00) Central Time - Regina',
  'sh.fi.tz.America/Mexico_City': '(GMT-06:00) Mexico City',
  'sh.fi.tz.America/Managua': '(GMT-06:00) Central America',
  'sh.fi.tz.America/Guatemala': '(GMT-06:00) Guatemala',
  'sh.fi.tz.America/El_Salvador': '(GMT-06:00) El Salvador',
  'sh.fi.tz.America/Winnipeg': '(GMT-06:00) Central Time - Winnipeg',
  'sh.fi.tz.America/Chicago': '(GMT-06:00) Central Time',
  'sh.fi.tz.America/Belize': '(GMT-06:00) Belize',
  'sh.fi.tz.US/Central': '(GMT-06:00) Central Time (US &amp; Canada)',
  'sh.fi.tz.America/Monterrey': '(GMT-06:00) Monterrey',
  'sh.fi.tz.Canada/Saskatchewan': '(GMT-06:00) Saskatchewan',
  'sh.fi.tz.America/Jamaica': '(GMT-05:00) Jamaica',
  'sh.fi.tz.America/Lima': '(GMT-05:00) Lima',
  'sh.fi.tz.America/Nassau': '(GMT-05:00) Nassau',
  'sh.fi.tz.America/New_York': '(GMT-05:00) Eastern Time',
  'sh.fi.tz.America/Panama': '(GMT-05:00) Panama',
  'sh.fi.tz.America/Port-au-Prince': '(GMT-05:00) Port-au-Prince',
  'sh.fi.tz.America/Rio_Branco': '(GMT-05:00) Rio Branco',
  'sh.fi.tz.America/Bogota': '(GMT-05:00) Bogota',
  'sh.fi.tz.Pacific/Easter': '(GMT-05:00) Easter Island',
  'sh.fi.tz.America/Cancun': '(GMT-05:00) America Cancun',
  'sh.fi.tz.America/Cayman': '(GMT-05:00) Cayman',
  'sh.fi.tz.America/Guayaquil': '(GMT-05:00) Guayaquil',
  'sh.fi.tz.America/Havana': '(GMT-05:00) Havana',
  'sh.fi.tz.America/Iqaluit': '(GMT-05:00) Eastern Time - Iqaluit',
  'sh.fi.tz.US/Eastern': '(GMT-05:00) Eastern Time (US &amp; Canada)',
  'sh.fi.tz.US/East-Indiana': '(GMT-05:00) Indiana',
  'sh.fi.tz.America/Toronto': '(GMT-05:00) Eastern Time - Toronto',
  'sh.fi.tz.America/Port_of_Spain': '(GMT-04:00) Port of Spain',
  'sh.fi.tz.America/Porto_Velho': '(GMT-04:00) Porto Velho',
  'sh.fi.tz.America/Puerto_Rico': '(GMT-04:00) Puerto Rico',
  'sh.fi.tz.America/Santo_Domingo': '(GMT-04:00) Santo Domingo',
  'sh.fi.tz.America/Thule': '(GMT-04:00) Thule',
  'sh.fi.tz.Atlantic/Bermuda': '(GMT-04:00) Bermuda',
  'sh.fi.tz.America/Barbados': '(GMT-04:00) Barbados',
  'sh.fi.tz.America/Boa_Vista': '(GMT-04:00) Boa Vista',
  'sh.fi.tz.America/Curacao': '(GMT-04:00) Curacao',
  'sh.fi.tz.America/Grand_Turk': '(GMT-04:00) Grand Turk',
  'sh.fi.tz.America/Guyana': '(GMT-04:00) Guyana',
  'sh.fi.tz.America/Halifax': '(GMT-04:00) Atlantic Time - Halifax',
  'sh.fi.tz.Canada/Atlantic': '(GMT-04:00) Atlantic Time',
  'sh.fi.tz.America/Caracas': '(GMT-04:30) Caracas',
  'sh.fi.tz.America/Manaus': '(GMT-04:00) Manaus',
  'sh.fi.tz.America/Martinique': '(GMT-04:00) Martinique',
  'sh.fi.tz.America/Santiago': '(GMT-03:00) Santiago',
  'sh.fi.tz.America/La_Paz': '(GMT-04:00) La Paz',
  'sh.fi.tz.America/Fortaleza': '(GMT-03:00) Fortaleza',
  'sh.fi.tz.America/Godthab': '(GMT-03:00) Greenland',
  'sh.fi.tz.America/Asuncion': '(GMT-03:00) Asuncion',
  'sh.fi.tz.America/Miquelon': '(GMT-03:00) Miquelon',
  'sh.fi.tz.America/Campo_Grande': '(GMT-03:00) Campo Grande',
  'sh.fi.tz.America/Paramaribo': '(GMT-03:00) Paramaribo',
  'sh.fi.tz.America/Recife': '(GMT-03:00) Recife',
  'sh.fi.tz.America/Cuiaba': '(GMT-03:00) Cuiaba',
  'sh.fi.tz.Antarctica/Palmer': '(GMT-03:00) Palmer',
  'sh.fi.tz.Antarctica/Rothera': '(GMT-03:00) Rothera',
  'sh.fi.tz.Atlantic/Stanley': '(GMT-03:00) Stanley',
  'sh.fi.tz.America/Maceio': '(GMT-03:00) Maceio',
  'sh.fi.tz.America/St_Johns': '(GMT-03:30) Newfoundland Time - St. Johns',
  'sh.fi.tz.America/Araguaina': '(GMT-03:00) Araguaina',
  'sh.fi.tz.America/Bahia': '(GMT-03:00) Salvador',
  'sh.fi.tz.America/Belem': '(GMT-03:00) Belem',
  'sh.fi.tz.Canada/Newfoundland': '(GMT-03:30) Newfoundland',
  'sh.fi.tz.America/Cayenne': '(GMT-03:00) Cayenne',
  'sh.fi.tz.America/Sao_Paulo': '(GMT-02:00) Sao Paulo',
  'sh.fi.tz.America/Argentina/Buenos_Aires': '(GMT-03:00) Georgetown',
  'sh.fi.tz.America/Montevideo': '(GMT-03:00) Montevideo',
  'sh.fi.tz.America/Noronha': '(GMT-02:00) Noronha',
  'sh.fi.tz.Atlantic/South_Georgia': '(GMT-02:00) South Georgia',
  'sh.fi.tz.Atlantic/Azores': '(GMT-01:00) Azores',
  'sh.fi.tz.Atlantic/Cape_Verde': '(GMT-01:00) Cape Verde Is.',
  'sh.fi.tz.America/Scoresbysund': '(GMT-01:00) Scoresbysund',
  'sh.fi.tz.Africa/Monrovia': '(GMT+00:00) Monrovia',
  'sh.fi.tz.Europe/Dublin': '(GMT+00:00) Dublin',
  'sh.fi.tz.Etc/GMT': '(GMT+00:00) GMT (no daylight saving)',
  'sh.fi.tz.Atlantic/Reykjavik': '(GMT+00:00) Reykjavik',
  'sh.fi.tz.Etc/Greenwich': '(GMT+00:00) Greenwich Mean Time : Dublin',
  'sh.fi.tz.Atlantic/Canary': '(GMT+00:00) Canary Islands',
  'sh.fi.tz.America/Danmarkshavn': '(GMT+00:00) Danmarkshavn',
  'sh.fi.tz.Africa/El_Aaiun': '(GMT+00:00) El Aaiun',
  'sh.fi.tz.Europe/Lisbon': '(GMT+00:00) Lisbon',
  'sh.fi.tz.Africa/Casablanca': '(GMT+00:00) Casablanca',
  'sh.fi.tz.Africa/Bissau': '(GMT+00:00) Bissau',
  'sh.fi.tz.Africa/Accra': '(GMT+00:00) Accra',
  'sh.fi.tz.Africa/Abidjan': '(GMT+00:00) Abidjan',
  'sh.fi.tz.GMT': '(GMT+00:00) GMT',
  'sh.fi.tz.Europe/London': '(GMT+00:00) London',
  'sh.fi.tz.Atlantic/Faroe': '(GMT+00:00) Faeroe',
  'sh.fi.tz.Africa/Ndjamena': '(GMT+01:00) Ndjamena',
  'sh.fi.tz.Africa/Tunis': '(GMT+01:00) Tunis',
  'sh.fi.tz.Europe/Prague': '(GMT+01:00) Central European Time - Prague',
  'sh.fi.tz.Europe/Amsterdam': '(GMT+01:00) Amsterdam',
  'sh.fi.tz.Europe/Andorra': '(GMT+01:00) Andorra',
  'sh.fi.tz.Europe/Belgrade': '(GMT+01:00) Belgrade',
  'sh.fi.tz.Europe/Berlin': '(GMT+01:00) Bern',
  'sh.fi.tz.Europe/Brussels': '(GMT+01:00) Brussels',
  'sh.fi.tz.Europe/Budapest': '(GMT+01:00) Budapest',
  'sh.fi.tz.Europe/Copenhagen': '(GMT+01:00) Copenhagen',
  'sh.fi.tz.Europe/Gibraltar': '(GMT+01:00) Gibraltar',
  'sh.fi.tz.Europe/Luxembourg': '(GMT+01:00) Luxembourg',
  'sh.fi.tz.Europe/Malta': '(GMT+01:00) Malta',
  'sh.fi.tz.Europe/Monaco': '(GMT+01:00) Monaco',
  'sh.fi.tz.Europe/Oslo': '(GMT+01:00) Oslo',
  'sh.fi.tz.Europe/Rome': '(GMT+01:00) Rome',
  'sh.fi.tz.Europe/Stockholm': '(GMT+01:00) Stockholm',
  'sh.fi.tz.Europe/Tirane': '(GMT+01:00) Tirane',
  'sh.fi.tz.Europe/Vienna': '(GMT+01:00) Vienna',
  'sh.fi.tz.Europe/Warsaw': '(GMT+01:00) Warsaw',
  'sh.fi.tz.Europe/Zurich': '(GMT+01:00) Zurich',
  'sh.fi.tz.Europe/Paris': '(GMT+01:00) Paris',
  'sh.fi.tz.Europe/Madrid': '(GMT+01:00) Madrid',
  'sh.fi.tz.Europe/Ljubljana': '(GMT+01:00) Ljubljana',
  'sh.fi.tz.Europe/Bratislava': '(GMT+01:00) Bratislava',
  'sh.fi.tz.Africa/Algiers': '(GMT+01:00) Algiers',
  'sh.fi.tz.Africa/Ceuta': '(GMT+01:00) Ceuta',
  'sh.fi.tz.Europe/Sarajevo': '(GMT+01:00) Sarajevo',
  'sh.fi.tz.Europe/Skopje': '(GMT+01:00) Skopje',
  'sh.fi.tz.Africa/Lagos': '(GMT+01:00) Lagos',
  'sh.fi.tz.Europe/Zagreb': '(GMT+01:00) Zagreb',
  'sh.fi.tz.Asia/Amman': '(GMT+02:00) Amman',
  'sh.fi.tz.Europe/Sofia': '(GMT+02:00) Sofia',
  'sh.fi.tz.Africa/Cairo': '(GMT+02:00) Cairo',
  'sh.fi.tz.Africa/Johannesburg': '(GMT+02:00) Pretoria',
  'sh.fi.tz.Africa/Maputo': '(GMT+02:00) Maputo',
  'sh.fi.tz.Europe/Helsinki': '(GMT+02:00) Kyiv',
  'sh.fi.tz.Europe/Istanbul': '(GMT+02:00) Istanbul',
  'sh.fi.tz.Europe/Kaliningrad': '(GMT+02:00) Moscow-01 - Kaliningrad',
  'sh.fi.tz.Europe/Kiev': '(GMT+02:00) Kiev',
  'sh.fi.tz.Europe/Riga': '(GMT+02:00) Riga',
  'sh.fi.tz.Europe/Tallinn': '(GMT+02:00) Tallinn',
  'sh.fi.tz.Europe/Vilnius': '(GMT+02:00) Vilnius',
  'sh.fi.tz.Europe/Bucharest': '(GMT+02:00) Bucharest',
  'sh.fi.tz.Europe/Athens': '(GMT+02:00) Athens',
  'sh.fi.tz.Asia/Nicosia': '(GMT+02:00) Nicosia',
  'sh.fi.tz.Asia/Jerusalem': '(GMT+02:00) Jerusalem',
  'sh.fi.tz.Asia/Gaza': '(GMT+02:00) Gaza',
  'sh.fi.tz.Asia/Damascus': '(GMT+02:00) Damascus',
  'sh.fi.tz.Asia/Beirut': '(GMT+02:00) Beirut',
  'sh.fi.tz.Africa/Windhoek': '(GMT+02:00) Windhoek',
  'sh.fi.tz.Africa/Harare': '(GMT+02:00) Harare',
  'sh.fi.tz.Africa/Tripoli': '(GMT+02:00) Tripoli',
  'sh.fi.tz.Europe/Chisinau': '(GMT+02:00) Chisinau',
  'sh.fi.tz.Asia/Baghdad': '(GMT+03:00) Baghdad',
  'sh.fi.tz.Europe/Minsk': '(GMT+03:00) Minsk',
  'sh.fi.tz.Europe/Volgograd': '(GMT+03:00) Volgograd',
  'sh.fi.tz.Asia/Tehran': '(GMT+03:30) Tehran',
  'sh.fi.tz.Africa/Khartoum': '(GMT+03:00) Khartoum',
  'sh.fi.tz.Africa/Nairobi': '(GMT+03:00) Nairobi',
  'sh.fi.tz.Antarctica/Syowa': '(GMT+03:00) Syowa',
  'sh.fi.tz.Asia/Qatar': '(GMT+03:00) Qatar',
  'sh.fi.tz.Asia/Riyadh': '(GMT+03:00) Riyadh',
  'sh.fi.tz.Asia/Kuwait': '(GMT+03:00) Kuwait',
  'sh.fi.tz.Europe/Samara': '(GMT+04:00) Moscow+01 - Samara',
  'sh.fi.tz.Indian/Mahe': '(GMT+04:00) Mahe',
  'sh.fi.tz.Indian/Mauritius': '(GMT+04:00) Mauritius',
  'sh.fi.tz.Indian/Reunion': '(GMT+04:00) Reunion',
  'sh.fi.tz.Asia/Dubai': '(GMT+04:00) Dubai',
  'sh.fi.tz.Asia/Baku': '(GMT+04:00) Baku',
  'sh.fi.tz.Europe/Moscow': '(GMT+03:00) St. Petersburg',
  'sh.fi.tz.Asia/Muscat': '(GMT+04:00) Abu Dhabi',
  'sh.fi.tz.Asia/Tbilisi': '(GMT+04:00) Tbilisi',
  'sh.fi.tz.Asia/Yerevan': '(GMT+04:00) Yerevan',
  'sh.fi.tz.Asia/Kabul': '(GMT+04:30) Kabul',
  'sh.fi.tz.Asia/Katmandu': '(GMT+05:45) Katmandu',
  'sh.fi.tz.Asia/Calcutta': '(GMT+05:30) India Standard Time',
  'sh.fi.tz.Asia/Kolkata': '(GMT+05:30) Kolkata',
  'sh.fi.tz.Indian/Maldives': '(GMT+05:00) Maldives',
  'sh.fi.tz.Indian/Kerguelen': '(GMT+05:00) Kerguelen',
  'sh.fi.tz.Asia/Yekaterinburg': '(GMT+06:00) Ekaterinburg',
  'sh.fi.tz.Asia/Tashkent': '(GMT+05:00) Tashkent',
  'sh.fi.tz.Asia/Dushanbe': '(GMT+05:00) Dushanbe',
  'sh.fi.tz.Asia/Karachi': '(GMT+05:00) Islamabad',
  'sh.fi.tz.Asia/Ashgabat': '(GMT+05:00) Ashgabat',
  'sh.fi.tz.Asia/Aqtobe': '(GMT+05:00) Aqtobe',
  'sh.fi.tz.Asia/Aqtau': '(GMT+05:00) Aqtau',
  'sh.fi.tz.Antarctica/Mawson': '(GMT+05:00) Mawson',
  'sh.fi.tz.Asia/Colombo': '(GMT+05:30) Colombo',
  'sh.fi.tz.Indian/Chagos': '(GMT+06:00) Chagos',
  'sh.fi.tz.Asia/Rangoon': '(GMT+06:30) Rangoon',
  'sh.fi.tz.Asia/Almaty': '(GMT+06:00) Almaty',
  'sh.fi.tz.Asia/Bishkek': '(GMT+06:00) Bishkek',
  'sh.fi.tz.Asia/Omsk': '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk',
  'sh.fi.tz.Asia/Thimphu': '(GMT+06:00) Thimphu',
  'sh.fi.tz.Antarctica/Vostok': '(GMT+06:00) Vostok',
  'sh.fi.tz.Asia/Dhaka': '(GMT+06:00) Dhaka',
  'sh.fi.tz.Indian/Cocos': '(GMT+06:30) Cocos',
  'sh.fi.tz.Asia/Krasnoyarsk': '(GMT+08:00) Krasnoyarsk',
  'sh.fi.tz.Asia/Saigon': '(GMT+07:00) Hanoi',
  'sh.fi.tz.Indian/Christmas': '(GMT+07:00) Christmas',
  'sh.fi.tz.Asia/Jakarta': '(GMT+07:00) Jakarta',
  'sh.fi.tz.Asia/Novosibirsk': '(GMT+07:00) Novosibirsk',
  'sh.fi.tz.Antarctica/Davis': '(GMT+07:00) Davis',
  'sh.fi.tz.Asia/Bangkok': '(GMT+07:00) Bangkok',
  'sh.fi.tz.Asia/Hovd': '(GMT+07:00) Hovd',
  'sh.fi.tz.Asia/Macau': '(GMT+08:00) Macau',
  'sh.fi.tz.Asia/Manila': '(GMT+08:00) Manila',
  'sh.fi.tz.Asia/Urumqi': '(GMT+08:00) Urumqi',
  'sh.fi.tz.Asia/Ulan_Bator': '(GMT+08:00) Ulaan Bataar',
  'sh.fi.tz.Asia/Singapore': '(GMT+08:00) Singapore',
  'sh.fi.tz.Australia/Perth': '(GMT+08:00) Western Time - Perth',
  'sh.fi.tz.Asia/Hong_Kong': '(GMT+08:00) Beijing',
  'sh.fi.tz.Asia/Chongqing': '(GMT+08:00) Chongqing',
  'sh.fi.tz.Asia/Taipei': '(GMT+08:00) Taipei',
  'sh.fi.tz.Antarctica/Casey': '(GMT+08:00) Casey',
  'sh.fi.tz.Asia/Brunei': '(GMT+08:00) Brunei',
  'sh.fi.tz.Asia/Choibalsan': '(GMT+08:00) Choibalsan',
  'sh.fi.tz.Asia/Irkutsk': '(GMT+09:00) Irkutsk',
  'sh.fi.tz.Asia/Kuala_Lumpur': '(GMT+08:00) Kuala Lumpur',
  'sh.fi.tz.Asia/Makassar': '(GMT+08:00) Makassar',
  'sh.fi.tz.Asia/Shanghai': '(GMT+08:00) China Time - Beijing',
  'sh.fi.tz.Asia/Ulaanbaatar': '(GMT+08:00) Ulaanbaatar',
  'sh.fi.tz.Asia/Pyongyang': '(GMT+08:30) Pyongyang',
  'sh.fi.tz.Australia/Darwin': '(GMT+09:30) Darwin',
  'sh.fi.tz.Asia/Tokyo': '(GMT+09:00) Osaka',
  'sh.fi.tz.Asia/Seoul': '(GMT+09:00) Seoul',
  'sh.fi.tz.Asia/Jayapura': '(GMT+09:00) Jayapura',
  'sh.fi.tz.Asia/Dili': '(GMT+09:00) Dili',
  'sh.fi.tz.Australia/Adelaide': '(GMT+10:30) Central Time - Adelaide',
  'sh.fi.tz.Asia/Yakutsk': '(GMT+10:00) Yakutsk',
  'sh.fi.tz.Pacific/Palau': '(GMT+09:00) Palau',
  'sh.fi.tz.Australia/Brisbane': '(GMT+10:00) Eastern Time - Brisbane',
  'sh.fi.tz.Pacific/Guam': '(GMT+10:00) Guam',
  'sh.fi.tz.Pacific/Chuuk': '(GMT+10:00) Truk',
  'sh.fi.tz.Asia/Vladivostok': '(GMT+11:00) Vladivostok',
  'sh.fi.tz.Asia/Magadan': '(GMT+12:00) New Caledonia',
  'sh.fi.tz.Antarctica/DumontDUrville': '(GMT+10:00) Dumont D\'Urville',
  'sh.fi.tz.Pacific/Port_Moresby': '(GMT+10:00) Port Moresby',
  'sh.fi.tz.Australia/Canberra': '(GMT+10:00) Canberra',
  'sh.fi.tz.Australia/Hobart': '(GMT+11:00) Eastern Time - Hobart',
  'sh.fi.tz.Australia/Melbourne': '(GMT+10:00) Melbourne',
  'sh.fi.tz.Australia/Sydney': '(GMT+11:00) Eastern Time - Melbourne, Sydney',
  'sh.fi.tz.Pacific/Efate': '(GMT+11:00) Efate',
  'sh.fi.tz.Pacific/Guadalcanal': '(GMT+11:00) Guadalcanal',
  'sh.fi.tz.Pacific/Kosrae': '(GMT+11:00) Kosrae',
  'sh.fi.tz.Pacific/Noumea': '(GMT+11:00) Noumea',
  'sh.fi.tz.Pacific/Pohnpei': '(GMT+11:00) Ponape',
  'sh.fi.tz.Pacific/Norfolk': '(GMT+11:00) Norfolk',
  'sh.fi.tz.Pacific/Wake': '(GMT+12:00) Wake',
  'sh.fi.tz.Pacific/Wallis': '(GMT+12:00) Wallis',
  'sh.fi.tz.Pacific/Auckland': '(GMT+13:00) Auckland',
  'sh.fi.tz.Asia/Kamchatka': '(GMT+12:00) Kamchatka',
  'sh.fi.tz.Pacific/Funafuti': '(GMT+12:00) Funafuti',
  'sh.fi.tz.Pacific/Kwajalein': '(GMT+12:00) International Date Line West',
  'sh.fi.tz.Pacific/Majuro': '(GMT+12:00) Majuro',
  'sh.fi.tz.Pacific/Tarawa': '(GMT+12:00) Tarawa',
  'sh.fi.tz.Pacific/Fiji': '(GMT+13:00) Fiji',
  'sh.fi.tz.Pacific/Nauru': '(GMT+12:00) Nauru',
  'sh.fi.tz.Pacific/Enderbury': '(GMT+13:00) Enderbury',
  'sh.fi.tz.Pacific/Tongatapu': '(GMT+13:00) Tongatapu',
  'sh.fi.tz.Pacific/Fakaofo': '(GMT+13:00) Fakaofo',
  'sh.fi.tz.Pacific/Apia': '(GMT+14:00) Apia',
  'sh.fi.tz.Pacific/Kiritimati': '(GMT+14:00) Kiritimati',
  // #endregion SHARED STRINGS: TIMEZONES
  // #endregion SHARED STRINGS

  // DASHBOARD
  // HEADER
  'am.header_tab_sites': 'Sites',
  'am.header_tab_analytics': 'Analytics',
  'am.header_tab_files': 'Files',
  'am.header_tab_templates': 'Templates',

  'am.header_notigications_placeholder': 'All caught up!',
  'am.header_notigications_news&updates': 'NEWS & UPDATES',

  'am.header_right-menu_my-account': 'My Account',
  'am.header_right-menu_upgrade': 'Upgrade',
  'am.header_right-menu_log-out': 'Log Out',

  // #region Cookie Notice
  // #region Popup
  'am.cn.popup_heading': 'Your Cookie Settings',
  'am.cn.popup_description':
    '{{resellerName}} uses cookies to enable essential functionality on our websites, offer you a better browsing experience, analyze website traffic, and personalize content.',
  'am.cn.popup_checkbox-on-status-text': 'On',
  'am.cn.popup_checkbox-off-status-text': 'Off',
  'am.cn.popup_read-about-cookies-link': 'Read about how we use cookies',
  'am.cn.popup_privacy-policy-link': 'Privacy and Cookies Policy',
  'am.cn.popup_categories_heading': 'Cookie Categories',
  'am.cn.popup_categories_essential-heading': 'Essential',
  'am.cn.popup_categories_essential-description':
    'These cookies are strictly necessary to provide you with services available through our websites. You cannot refuse these cookies without impacting how our websites function. You can block or delete them by changing your browser settings, as described under the heading "Managing cookies" in the',
  'am.cn.popup_categories_functionality-heading':
    'Performance and Functionality',
  'am.cn.popup_categories_functionality-description':
    'These cookies are used to enhance the performance and functionality of our websites, or to help us customize our websites and application for you in order to enhance your experience, but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.',
  'am.cn.popup_categories_analytic-heading': 'Analytics',
  'am.cn.popup_categories_analytic-description':
    'These cookies collect information that is used in aggregate form to help us understand how our websites are being used or how effective our marketing campaigns are.',
  'am.cn.popup_allow-all-btn': 'Allow all',
  'am.cn.popup_save-btn': 'Save settings',
  // #endregion Popup
  // #region Toolbar
  'am.cn.toolbar_text':
    'We use cookies to enable essential functionality on our websites, offer you a better browsing experience, analyze website traffic, and personalize content. By clicking Accept you consent to our use of cookies.',
  'am.cn.toolbar_read-about-cookies-link': 'Read about how we use cookies.',
  'am.cn.toolbar_cookie-settings-btn': 'Cookie settings',
  'am.cn.toolbar_accept-btn': 'Accept',
  // #endregion Toolbar
  // #endregion Cookie Notice

  // #region Cookie Notice Preview
  // #region Popup
  'am.cn.pr.popup_heading': 'Your Cookie Settings',
  'am.cn.pr.popup_description':
    'We use cookies to enable essential functionality on our website, and analyze website traffic.',
  'am.cn.pr.popup_checkbox-on-status-text': 'On',
  'am.cn.pr.popup_checkbox-off-status-text': 'Off',
  'am.cn.pr.popup_read-about-cookies-link': 'Read about how we use cookies',
  'am.cn.pr.popup_privacy-policy-link': 'Privacy and Cookies Policy',
  'am.cn.pr.popup_categories_heading': 'Cookie Categories',
  'am.cn.pr.popup_categories_essential-heading': 'Essential',
  'am.cn.pr.popup_categories_essential-description':
    'These cookies are strictly necessary to provide you with services available through our websites. You cannot refuse these cookies without impacting how our websites function. You can block or delete them by changing your browser settings, as described under the heading "Managing cookies" in the',
  'am.cn.pr.popup_categories_functionality-heading':
    'Performance and Functionality',
  'am.cn.pr.popup_categories_functionality-description':
    'These cookies are used to enhance the performance and functionality of our websites, or to help us customize our websites and application for you in order to enhance your experience, but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.',
  'am.cn.pr.popup_categories_analytic-heading': 'Analytics',
  'am.cn.pr.popup_categories_analytic-description':
    'These cookies collect information that is used in aggregate form to help us understand how our websites are being used or how effective our marketing campaigns are.',
  'am.cn.pr.popup_allow-all-btn': 'Allow all',
  'am.cn.pr.popup_save-btn': 'Save settings',
  // #endregion Popup
  // #region Toolbar
  'am.cn.pr.toolbar_text':
    'We use cookies to enable essential functionality on our website, and analyze website traffic. By clicking Accept you consent to our use of cookies.',
  'am.cn.pr.toolbar_read-about-cookies-link':
    'Read about how we use cookies.',
  'am.cn.pr.toolbar_cookie-settings-btn': 'Cookie settings',
  'am.cn.pr.toolbar_accept-btn': 'Accept',
  // #endregion Toolbar
  // #endregion Cookie Notice

  // SHOPPING CART
  'am.sc.title': 'Your Shopping Cart',
  'am.sc.description': 'DESCRIPTION',
  'am.sc.term': 'TERM',
  'am.sc.price': 'PRICE',
  'am.sc.checkout-btn': 'checkout',
  'am.sc.cart-empty_text': 'Your shopping cart is empty.',
  'am.sc.total_text': 'Total',
  'am.sc.discount_text': 'sale',
  // eslint-disable-next-line no-template-curly-in-string
  'am.sc.total_price': '${{total}}',
  // eslint-disable-next-line no-template-curly-in-string
  'am.sc.item_price': '${{price}}',
  'am.sc.currency_usd': 'usd',
  'am.sc.term_year': '{{term}} year',
  'am.sc.item_year_plural': '{{term}} years',
  'am.sc.term_month': '{{term}} month',
  'am.sc.item_month_plural': '{{term}} months',

  'am.sc.payment_title': 'Payment Method',
  'am.sc.payment_paypal-title': 'Paypal',
  'am.sc.payment_paypal-description':
    'Add PayPal credit to your account. This is a one-time payment and may take up to five minutes to process.',
  // eslint-disable-next-line no-template-curly-in-string
  'am.sc.payment_pay-btn': 'Pay ${{total}}',
  'am.sc.payment_terms&conditions_text':
    'By clicking the Pay & Activate button, you are agree the ',
  'am.sc.payment_terms&conditions_link': 'Terms & Conditions',
  // SITES
  'am.si.create_site_btn_text': 'Create site',
  'am.si.create-site_menu_title': 'Choose a template for your site',
  'am.si.create-site_menu_subtitle': 'All templates can be fully customised with your brand.',

  'am.si.verify-email_text-verify':
    'Take a moment to verify your email address by clicking the button inside the email we sent.',
  'am.si.verify-email_send-btn': 'Send email',

  'am.si.website-publishing_title': 'Website is Publishing',
  'am.si.website-publishing_description':
    'Your site files are being uploaded. This can take several minutes. We will notify you when complete.',
  'am.si.website-publishing_continue-editing-btn': 'continue editing',

  'am.si.publish-notice_header_publishing': 'Website publishing',
  'am.si.publish-notice_header_publishing_text':
    'We`re publishing your website.',
  'am.si.publish-notice_header_publishing_multiple':
    '{{publishingIndex}}X Website publishing',
  'am.si.publish-notice_header_publishing_text_multiple':
    'We publish your websites.',
  'am.si.publish-notice_header_finished': 'Finished',
  'am.si.publish-notice_header_finished_text': 'Site successfully published.',

  'am.si.sites-limit_text':
    'You have reached your sites limit. Upgrade your plan or purchase additional sites amount. ',
  'am.si.sites-limit_text_upgrade-now': 'Upgrade Now',
  // ////////////////////
  'am.si.publish-limit-reached': 'Limit Reached',
  'am.si.publish-limit-reached_description':
    'You have reached your sites limit of {{maxAllowedProjects}}.<4/> Upgrade to get additional sites amount.',
  'am.si.publish-limit-reached_description_no-upgrade':
    'You have reached your sites limit of {{maxAllowedProjects}}.',
  'am.si.publish-limit-reached_upgrade-btn': 'Upgrade now',
  // //////////////////////
  'am.si.publish-success_title': 'Website Published!',
  'am.si.publish-success_description':
    'Your website is successfully uploaded and available at',
  'am.si.publish-success_open-site-btn': 'open website',

  'am.si.my_sites_dropdown_text': 'My Sites',
  'am.si.my_sites_dropdown_recent': 'Recent',
  'am.si.my_sites_dropdown_published': 'Published',
  'am.si.my_sites_dropdown_name_a-z': 'Name A-Z',
  'am.si.my_sites_dropdown_deleted': 'Deleted',

  'am.si.no-projects_title': 'Create your first website',
  'am.si.no-projects_text1': 'Choose from a selection of website templates.',
  'am.si.no-projects_text2':
    'Add photos, text and more, then click publish to get online.',

  'am.si.item_dropdown_publish': 'Publish',
  'am.si.item_dropdown_unpublish': 'Unpublish',
  'am.si.item_dropdown_rename': 'Rename',
  'am.si.item_dropdown_duplicate': 'Duplicate',
  'am.si.item_dropdown_delete': 'Delete',

  'am.si.item_upgrade': 'Upgrade',
  'am.si.item_edit': 'Edit',
  'am.si.item_restore': 'Restore',
  'am.si.item_view-site': 'View site',
  'am.si.item_deleted': 'Deleted',
  'am.si.item_expired': 'Expired',

  'am.si.rename-form_title': 'Site Name',
  'am.si.rename-form_input-placeholder': 'Please enter your site name',
  'am.si.rename-form_input-err': 'Site name seems to be invalid.',
  'am.si.rename-form_save': 'Save',
  'am.si.rename-form_cancel': 'Cancel',

  'am.si.unpublish-form_title': 'Unpublish site',
  'am.si.unpublish-form_description':
    'Make your website offline. People will be unable to visit it.',
  'am.si.unpublish-form_confirm': 'Yes',
  'am.si.unpublish-form_cancel': 'No',

  'am.si.change-active-form_title': 'Publish new website',
  'am.si.change-active-form_description':
    'This will make your current website offline. Do you want to publish?',
  'am.si.change-active-form_confirm': 'Yes',
  'am.si.change-active-form_cancel': 'No',

  'am.si.delete-form_title': 'Delete site',
  'am.si.delete-form_description':
    'Delete all files, photos and related content for this website.',
  'am.si.delete-form_confirm': 'Yes',
  'am.si.delete-form_cancel': 'No',

  'am.si.restore-form_title': 'Restore site',
  'am.si.restore-form_description':
    'This site will be fully restored. Additional storage maybe required.',
  'am.si.restore-form_confirm': 'Yes',
  'am.si.restore-form_cancel': 'No',

  'am.si.update-form_title': 'Update site',
  'am.si.update-form_description':
    'There are not published changes. Do you want to publish them?',
  'am.si.update-form_confirm': 'Yes',
  'am.si.update-form_cancel': 'No',

  'am.si.update-pre-check-form_title': 'ALREADY EXISTS',
  'am.si.update-pre-check-form_description':
    'Website or files already exists on your hosting server. Updating will overwrite it. Do you want to continue?',
  'am.si.update-pre-check-form_confirm': 'Yes',
  'am.si.update-pre-check-form_cancel': 'No',

  // PUBLISHING MODALS
  'am.si.hosting-unavailable_title': 'HOSTING UNAVAILABLE',
  'am.si.hosting-unavailable_description':
    'Publish is temporary unavailable. Please contact us or try again later.',
  'am.si.hosting-unavailable_confirm': 'OK',

  'am.si.subscription-incomplete_title': 'Publish Not Available',
  'am.si.subscription-incomplete_description':
    'There was an error publishing your site. There are not any Domain Names connected to your account yet.',
  'am.si.subscription-incomplete_confirm': 'OK',

  'am.si.modal_network-error-title': 'NETWORK ERROR',
  'am.si.modal_network-error-description':
    'Network connection is temporary unavailable. Please try again.',
  'am.si.modal_network-error-button': 'OK',

  'am.si.modal_unpublishing-error-title': 'UNPUBLISH FAILED',
  'am.si.modal_unpublishing-error-description':
    'Network connection is temporary unavailable. Please try again.',
  'am.si.modal_unpublishing-error-button': 'OK',

  'am.si.modal_duplicate-error-title': 'DUPLICATE FAILED',
  'am.si.modal_duplicate-error-description':
    'Network connection is temporary unavailable. Please try again.',
  'am.si.modal_duplicate-error-button': 'OK',

  'am.si.modal_publish-trial-title': 'Publish Not Available',
  'am.si.modal_publish-trial-description':
    'Website publishing is not available on your plan.',
  'am.si.modal_publish-trial-button': 'OK',

  'am.si.limit-reached_title': 'Publish Not Available',
  'am.si.limit-reached_description':
    'You have reached your publish sites limit of {{maxAllowedSites}}',
  'am.si.limit-reached_upgrade_btn': 'Upgrade now',

  'am.si.modal_publish-no-hosting-title': 'Publish Not Available',
  'am.si.modal_publish-no-hosting-description':
    'There was an error publishing your site. There are not any hosting connected to your account yet.',

  'am.si.publish-no-project-title': 'NETWORK ERROR',
  'am.si.publish-no-project-description':
    'Network connection is temporary unavailable. Please try again.',
  'am.si.publish-no-project-button': 'OK',

  'am.si.modal_publish-no-domain-title': 'Publish Not Available',
  'am.si.modal_publish-no-domain-description':
    'There was an error publishing your site. There are not any Domain Names connected to your account yet.',

  'am.si.modal_publish-no-subscription-title': 'Publish Not Available',
  'am.si.modal_publish-no-subscription-description':
    'There was an error publishing your site. There are not any subscriptions connected to your account yet.',

  'am.si.publish-dialog_title': 'Choose website domain',
  'am.si.publish-dialog_description':
    'Address where people will find your website online.',
  'am.si.publish-dialog_publish': 'Publish',
  'am.si.publish-dialog_try-again': 'Try Again',

  'am.si.publish-dialog_subdomain_title': 'Use a Subdomain of {{domain}}',
  'am.si.publish-dialog_subdomain_description':
    'Subdomain with Secure Connection for free.',
  'am.si.publish-dialog_subdomain_description_no_ssl':
    'Subdomain without Secure Connection for free.',
  'am.si.publish-dialog_ssl-text': ' (SSL) ',

  'am.si.publish-dialog_partner-domain_title': '{{resellerName}} Domain',
  'am.si.publish-dialog_partner-domain_description':
    'For a more professional online presence.',

  'am.si.publish-dialog_connected-domain_title': 'Connected Domain',
  'am.si.publish-dialog_connected-domain_description':
    'Connected domains from other providers',

  // TEMPLATES
  'am.tl.category-name_all': 'All',
  'am.tl.category-name_business': 'Business',
  'am.tl.category-name_services': 'Services',
  'am.tl.category-name_personal': 'Personal',
  'am.tl.category-name_health': 'Health',
  'am.tl.category-name_beauty': 'Beauty',
  'am.tl.category-name_restaurant': 'Restaurant',
  'am.tl.category-name_portfolio': 'Portfolio',

  'am.tl.template_view-btn': 'View template',
  'am.tl.preview_all-templates-btn': 'All templates',
  'am.tl.preview_all-templates-btn-on-mobile': 'Back',
  'am.tl.preview_create-site-btn': 'Create this site',
  'am.tl.preview_create-site-btn-on-mobile': 'Create site',
  'am.tl.preview_multi-page-btn': 'Multi-page',
  'am.tl.preview_single-page-btn': 'Single-page',
  'am.tl.preview_online-store-btn': 'Online Store',

  // ANALYTICS

  'am.an.title': 'My Analytics',
  'am.an.date-picker_info-text': 'Select the period to view Analytics',

  'am.an.empty_title': 'Website Analytics',
  'am.an.empty_text1': 'Analytics gives you the insights you need to improve and grow your business.',
  'am.an.empty_text2': 'Publish your first website to automatically activate your analytics.',
  // ALL ANALYTICS
  'am.an.all-analytics_live-visitors': 'Live Visitors',
  'am.an.all-analytics_live-visitors_tooltip':
    'Total visitors actively browsing your site right now.',
  'am.an.all-analytics_visits': 'Visits',
  'am.an.all-analytics_visits_tooltip':
    'Visits display the total amount views of your site. A single visitor can have multiple views.',
  'am.an.all-analytics_page-views': 'Page views',
  'am.an.all-analytics_page-views_tooltip':
    'Page views is the total number of pages viewed on your site by all visitors.',
  'am.an.all-analytics_unique-visitors': 'Unique Visitors',
  'am.an.all-analytics_unique-visitors_tooltip':
    'Total number of unique visitors. Regardless how many pages they viewed, these are counted as one visitor.',

  // OVERVIEW TAB
  'am.an.overview_tab-name': 'Overview',

  'am.an.overview_chart_live-visitors-title': 'Live Visitors',
  'am.an.overview_chart_live-visitors-tooltip':
    'Total visitors actively browsing your site right now.',

  'am.an.overview_chart_visits-title': 'Visits',
  'am.an.overview_chart_visits-tooltip':
    'Visits display the total amount views of your site. A single visitor can have multiple views.',

  'am.an.overview_chart_page-views-title': 'Page views',
  'am.an.overview_chart_page-views-tooltip':
    'Page views is the total number of pages viewed on your site by all visitors.',

  'am.an.overview_chart_unique-visitors-title': 'Unique Visitors',
  'am.an.overview_chart_unique-visitors-tooltip':
    'Total number of unique visitors. Regardless how many pages they viewed, these are counted as one visitor.',

  'am.an.overview_advanced-chart_visits-title': '{{number}} visit {{ending}}',
  'am.an.overview_advanced-chart_visits-title_plural':
    '{{number}} visits {{ending}}',
  'am.an.overview_advanced-chart_views-title': '{{number}} view {{ending}}',
  'am.an.overview_advanced-chart_views-title_plural':
    '{{number}} views {{ending}}',
  'am.an.overview_advanced-chart_new-users-title':
    '{{number}} New user {{ending}}',
  'am.an.overview_advanced-chart_new-users-title_plural':
    '{{number}} New users {{ending}}',
  'am.an.overview_advanced-chart_returning-users-title':
    '{{number}} Returning user {{ending}}',
  'am.an.overview_advanced-chart_returning-users-title_plural':
    '{{number}} Returning users {{ending}}',
  'am.an.overview_advanced-chart_desktop-devices-title':
    '{{number}} Desktop device {{ending}}',
  'am.an.overview_advanced-chart_desktop-devices-title_plural':
    '{{number}} Desktop devices {{ending}}',
  'am.an.overview_advanced-chart_mobile-devices-title':
    '{{number}} Mobile device {{ending}}',
  'am.an.overview_advanced-chart_mobile-devices-title_plural':
    '{{number}} Mobile devices {{ending}}',
  'am.an.overview_advanced-chart_dropdown_day': 'day',
  'am.an.overview_advanced-chart_dropdown_week': 'week',
  'am.an.overview_advanced-chart_dropdown_month': 'month',
  // ACTIVE USERS TAB
  'am.an.active-users_tab-name': 'Active Users',
  'am.an.active-users_table-type': 'Type',
  'am.an.active-users_table-visits': 'Visits',
  'am.an.active-users_table-users': 'Users',
  'am.an.active-users_table-page-views': 'Page views',
  'am.an.active-users_table-pages': 'Pages',
  'am.an.active-users_table-avg-visit-duration': 'Avg. visit duration',
  'am.an.active-users_table-user-type_new': 'New',
  'am.an.active-users_table-user-type_returning': 'Returning',
  // POPULAR PAGES TAB
  'am.an.popular-pages_tab-name': 'Popular Pages',
  'am.an.popular-pages_chart-label':
    'Top {{pages}} pages on this {{interval}}',
  'am.an.popular-pages_table-pages': 'Pages',
  'am.an.popular-pages_table-views': 'Views',
  'am.an.popular-pages_table-referer-from': 'Referer from',
  'am.an.popular-pages_table-referer-to': 'Referer to',
  'am.an.popular-pages_table-time-on-page': 'Time on page',
  // DEVICES TAB
  'am.an.devices_tab-name': 'Devices',
  'am.an.devices_type_desktop': 'Desktop',
  'am.an.devices_type_mobile&tablet': 'Mobile & Tablet',
  'am.an.devices_stacked-chart_percentage': '{{total}}%',
  // LOCATION TAB
  'am.an.location_tab-name': 'Location',
  'am.an.location_table-region': 'Region',
  'am.an.location_table-sessions': 'Sessions',
  'am.an.location_table-users': 'Users',
  'am.an.location_table-avr-time': 'Avr. time on site',
  'am.an.location_map_tooltip_sessions': 'Sessions: {{sessions}}',
  // ACCOUNT SETTINGS
  'am.ma.title': 'Account Settings',
  'am.ma.menu_my-account': 'My Account',
  'am.ma.menu_subscriptions': 'Subscription',
  'am.ma.menu_billing-history': 'Billing History',
  'am.ma.menu_email-notifications': 'Email Notifications',
  'am.ma.menu_preferences': 'Preferences',
  'am.ma.menu_security': 'Security',
  // MY ACCOUNT TAB
  'am.ma.my-account-tab_personal-info_title': 'Account',
  'am.ma.my-account-tab_personal-info_edit-btn': 'Edit',
  'am.ma.my-account-tab_personal-info_first-name_title': 'First name',
  'am.ma.my-account-tab_personal-info_first-name_placeholder':
    'Your first name',
  'am.ma.my-account-tab_personal-info_address_title': 'Address',
  'am.ma.my-account-tab_personal-info_address_placeholder':
    'Street address, building, etc.',
  'am.ma.my-account-tab_personal-info_city_title': 'City',
  'am.ma.my-account-tab_personal-info_city_placeholder': 'City',
  'am.ma.my-account-tab_personal-info_country_title': 'Country',
  'am.ma.my-account-tab_personal-info_country_placeholder':
    'Select your country',
  'am.ma.my-account-tab_personal-info_country_no-results_placeholder':
    'No results',
  'am.ma.my-account-tab_personal-info_email_title': 'Email',
  'am.ma.my-account-tab_personal-info_email_placeholder':
    'example@domain.com',
  'am.ma.my-account-tab_personal-info_full-name_title': 'Full name',
  'am.ma.my-account-tab_personal-info_full-name_placeholder':
    'Your full name',
  'am.ma.my-account-tab_personal-info_last-name_title': 'Last name',
  'am.ma.my-account-tab_personal-info_last-name_placeholder':
    'Your last name',
  'am.ma.my-account-tab_personal-info_phone_title': 'Phone',
  'am.ma.my-account-tab_personal-info_phone_placeholder': '1 234-567-8901',
  'am.ma.my-account-tab_personal-info_state_title': 'State',
  'am.ma.my-account-tab_personal-info_state_placeholder': 'State',
  'am.ma.my-account-tab_personal-info_postcode_title': 'POSTCODE/ZIP',
  'am.ma.my-account-tab_personal-info_postcode_placeholder': '12345',
  'am.ma.my-account-tab_submit-section_save-btn': 'Save',
  'am.ma.my-account-tab_submit-section_cancel-btn': 'Cancel',

  'am.ma.my-account-tab_security_title': 'Security',
  'am.ma.my-account-tab_security_edit-btn': 'Edit',
  'am.ma.my-account-tab_security_password_title': 'Password',
  'am.ma.my-account-tab_security_password_placeholder': 'Current password',
  'am.ma.my-account-tab_security_new-password_title': 'New password',
  'am.ma.my-account-tab_security_new-password_placeholder': 'New password',
  'am.ma.my-account-tab_security_save-btn': 'Save',
  'am.ma.my-account-tab_security_cancel-btn': 'Cancel',
  // SUBSCRIPTION
  'am.ma.subscription-tab_overview_title': 'Overview',
  'am.ma.subscription-tab_overview_live-sites': 'Live Sites',
  'am.ma.subscription-tab_overview_total-sites': 'Total Sites',
  'am.ma.subscription-tab_overview_site-pages': 'Site Pages',
  'am.ma.subscription-tab_overview_storage-used': 'Storage Used',

  'am.ma.subscription-tab_subscription_title': 'Subscription',
  'am.ma.subscription-tab_subscription_plan': 'Plan',
  'am.ma.subscription-tab_subscription_billing-cycle': 'Billing cycle',
  'am.ma.subscription-tab_subscription_next-payment': 'Next Payment',
  'am.ma.subscription-tab_subscription_update-btn': 'Update',
  'am.ma.subscription-tab_subscription_upgrade-btn': 'Upgrade',
  'am.ma.subscription-tab_overviewCounter_of': ' of ',

  'am.ma.subscription-tab_additional-sites_title': 'Additional sites',
  'am.ma.subscription-tab_additional-sites_description': 'For Publish',
  'am.ma.subscription-tab_additional-sites_sites': 'site',
  'am.ma.subscription-tab_additional-sites_sites_plural': 'sites',
  // eslint-disable-next-line no-template-curly-in-string
  'am.ma.subscription-tab_additional-sites_cost': '${{cost}}',
  'am.ma.subscription-tab_additional-sites_downgrade-hint':
    'By confirming, your paid subscription will be canceled. It will remain active until {{expiredDate}}. At that time, your additional sites will be unpublished. No further charges will be made to your credit card.',
  'am.ma.subscription-tab_additional-sites_downgrade-btn': 'Downgrade',
  // BILLING HISTORY
  'am.ma.billing-history-tab_invoice#': 'Invoice #',
  'am.ma.billing-history-tab_date': 'Date',
  'am.ma.billing-history-tab_total': 'Total',
  'am.ma.billing-history-tab_view-more-btn': 'View more',
  'am.ma.billing-history-tab_invoice_title': 'Invoice',
  'am.ma.billing-history-tab_invoice_invoice#': 'Invoice Number',
  'am.ma.billing-history-tab_invoice_payment-date': 'Payment Date',
  'am.ma.billing-history-tab_invoice_bill-to': 'Bill To',
  'am.ma.billing-history-tab_invoice_payment-info': 'Payment Info',
  'am.ma.billing-history-tab_invoice_payment-type': 'Payment Type:',
  'am.ma.billing-history-tab_invoice_card-ending': 'Card ending in:',
  'am.ma.billing-history-tab_invoice_amount-paid': 'Amount Paid',
  'am.ma.billing-history-tab_invoice_sign&amount': '{{sign}}{{amount}}',
  'am.ma.billing-history-tab_invoice_discount%': '%{{discount}}',
  'am.ma.billing-history-tab_invoice_description': 'Description',
  'am.ma.billing-history-tab_invoice_print-btn': 'Print',
  'am.ma.billing-history-tab_invoice_subtotal': 'Subtotal:',
  'am.ma.billing-history-tab_invoice_discount': 'Discount:',
  'am.ma.billing-history-tab_invoice_tax': 'Tax:',
  'am.ma.billing-history-tab_invoice_total': 'Total:',
  'am.ma.billing-history-tab_invoice_hint':
    'All pricing is displayed and charged in {{name}}.',
  // EMAIL NOTIFICATIONS
  'am.ma.email-notifications-tab_subject': 'Subject',
  'am.ma.email-notifications-tab_date': 'Date',
  'am.ma.email-notifications-tab_status': 'Status',
  // PREFERENCES
  'am.ma.preferences-tab_title': 'General',
  'am.ma.preferences-tab_languge': 'Language',
  'am.ma.preferences-tab_time-zone': 'Time zone',
  'am.ma.preferences-tab_account-usage': 'Account usage',
  'am.ma.preferences-tab_email-type': 'Email type',
  'am.ma.preferences-tab_email-updates': 'Email updates',
  'am.ma.preferences-tab_email-type_plain-text': 'Plain Text',
  'am.ma.preferences-tab_account-usage_myself': 'Myself - personal site',
  'am.ma.preferences-tab_account-usage_my-business':
    'My business/organization',
  'am.ma.preferences-tab_account-usage_someone-else':
    'Someone else (client/friend/family)',
  'am.ma.preferences-tab_account-usage_other': 'Other',
  'am.ma.preferences-tab_checkbox_service-notifications':
    'Service Notification & Warnings',
  'am.ma.preferences-tab_checkbox_updates&limits':
    'Updates & Limited Promotions',
  'am.ma.preferences-tab_checkbox_news': 'Industry Related News',
  'am.ma.preferences-tab_connected-accounts_title': 'Connected accounts',
  'am.ma.preferences-tab_connected-accounts_google': 'Google',
  'am.ma.preferences-tab_connected-accounts_google_text':
    'Connect your Google account for quick account log in.',
  'am.ma.preferences-tab_connected-accounts_facebook': 'Facebook',
  'am.ma.preferences-tab_connected-accounts_facebook_text':
    'Connect your Facebook account for quick account log in.',
  'am.ma.preferences-tab_connected-accounts_connect-btn': 'Connect',
  'am.ma.preferences-tab_connected-accounts_disconnect-btn': 'Disconnect',
  // FILES TAB
  'am.fs.title': 'My Files',
  'am.fs.storage-used': '{{storage_used}} of {{storage_total}} used',
  'am.fs.text_directory': 'Directory',
  'am.fs.folder_view-btn': 'View',
  'am.fs.folder_files-count': '{{fileCount}} file',
  'am.fs.folder_files-count_plural': '{{fileCount}} files',
  'am.fs.empty-folder_title': 'File Storage',
  'am.fs.empty-folder_text1':
    'This is your storage, all your website files will be stored here.',
  'am.fs.empty-folder_text2':
    'You can access this anytime through your Site Editor.',
  'am.fs.delete-confirm_title': 'Delete file',
  'am.fs.delete-confirm_description':
    'Are you sure you want to permanently delete this file from your site?',
  'am.fs.delete-confirm-err_title': 'Delete file',
  'am.fs.delete-confirm-err_description':
    'Error occured during file removal.',
  'am.fs.delete-confirm-err_text': 'Ok',
  'am.fs.delete-confirm_confirm': 'Yes',
  'am.fs.delete-confirm_cancel': 'No',

  // SECURITY
  'am.ma.security-tab_title': 'Login History',
  'am.ma.security-tab_close-all-btn': 'CLose all',
  'am.ma.security-tab_close-btn': 'CLose',
  'am.ma.security-tab_online': 'Online',
  'am.ma.security-tab_unknown': 'Unknown',
  'am.ma.security-tab_unknown-browser': 'unknown',
  // HELPER FUNCS TEXTS
  'hlpr.toDate.text_today': 'Today',
  'hlpr.toDate.text_yesterday': 'Yesterday',

  /**
   * SITEPLUS EDITOR
   */

  // ROOT
  'se.add-block-here': 'Add block here',
  'se.add-footer-here': 'Add footer here',
  'se.sb.plus-btn': 'Build',
  'se.sb.menu_pages': 'Pages',
  'se.sb.menu_pages_upgrade-btn': 'Upgrade',
  'se.sb.menu_pages_upgrade-subtitle': 'Need more pages?',
  'se.sb.menu_pages_upgrade-description':
    'Upgrade your website to add more pages, buttons and menus.',
  'se.sb.menu_store': 'Store',
  'se.sb.upgrade-btn': 'Upgrade',
  'se.sb.upgrade-deafult-plan': 'FREE',
  'se.sb.upgrade-popover_title': 'Build it your way!',
  'se.sb.upgrade-popover_description':
    'You are on the {{planName}} plan. Get all premium features and publish to your own domain name.',
  'se.sb.menu_blog': 'Blog',
  'se.sb.menu_theme': 'Theme',
  'se.sb.menu_theme_template_title': 'Template',
  'se.sb.menu_theme_template_description':
    'Here you can reset your website to template defaults.',
  'se.sb.menu_theme_template_reset-btn': 'Reset',
  'se.sb.menu_theme_template_reset-confirm_title': 'Reset Template',
  'se.sb.menu_theme_template_reset-confirm_description':
    'Are you sure you want to reset your website to template defaults?',
  'se.sb.menu_settings': 'Settings',
  'se.sb.back-home-btn': 'Home',
  'se.sb.toggle-layout-btn_show-tooltip': 'Show Layout',
  'se.sb.toggle-layout-btn_hide-tooltip': 'Hide Layout',
  'se.sb.toggle-sidebar-lock-btn_lock-tooltip': 'Lock Sidebar',
  'se.sb.toggle-sidebar-lock-btn_unlock-tooltip': 'Unlock Sidebar',
  'se.sb.toggle-sidebar-log-out-tooltip': 'Log Out',

  // #region ONBOARDING
  // #region General
  'se.ob.next-btn': 'Next',
  'se.ob.finish-btn': 'Finish',
  'se.ob.progress': '{{position}} of {{stepsCount}}',
  'se.ob.skip-btn': 'Skip',
  'se.ob.skip-confirm_title': 'Skip tutorial',
  'se.ob.skip-confirm_description':
    'Are you sure you want to skip Get Started tutorial? You can start it again later.',
  // #endregion General
  // #region Welcome
  'se.ob.welcome_title': 'Welcome',
  'se.ob.welcome_desc-1': 'Your new website is ready to edit.',
  'se.ob.welcome_desc-2': 'Here are some tips to help you get started.',
  'se.ob.welcome_get-started-btn': 'Get started',
  // #endregion Welcome
  // #region Blocks
  'se.ob.blocks_title': 'Add blocks',
  'se.ob.blocks_description':
    'Drag and drop additional pre-designed blocks anywhere on your page.',
  // #endregion Blocks
  // #region Connect store
  'se.ob.connect-store_connect-title': 'Connect store',
  'se.ob.connect-store_connect-description':
    'Would You like to connect store?',
  'se.ob.connect-store_populate-title': 'Add demo products',
  'se.ob.connect-store_populate-description':
    'Would You like to add demo products?',
  'se.ob.connect-store_add-btn': 'Add',
  'se.ob.connect-store_connect-btn': 'Connect',
  'se.ob.connect-store_cancel-btn': 'Cancel',
  // #endregion Connect store
  // #region Device
  'se.ob.device_title': 'Device toolbar',
  'se.ob.device_description':
    'Your site will always look perfect on any device, it automatically adapts to your customer’s screen sizes.',
  // #endregion Device
  // #region Elements
  'se.ob.elements_title': 'Add features',
  'se.ob.elements_description':
    'Drag and drop additional features anywhere on your page.',
  // #endregion Elements
  // #region Plus menu
  'se.ob.plus-menu_title': 'Build your site',
  'se.ob.plus-menu_description':
    'The Build menu gives you all the features you need to create and update your website.',
  // #endregion Plus menu
  // #region Publish
  'se.ob.publish_title': 'Go live',
  'se.ob.publish_description':
    '{{action}} your website anytime to your domain name with one click. Revise and edit anytime.',
  'se.ob.publish_update-btn': 'Update',
  'se.ob.publish_publish-btn': 'Publish',
  // #endregion Publish
  // #region Text editing
  'se.ob.text-editing_title': 'Edit text & features',
  'se.ob.text-editing_description':
    'Select text to edit just like a word document or click any photo or feature for options.',
  // #endregion Text editing
  // #endregion ONBOARDING

  // #region HEADER
  'se.hr.logo-tooltip': 'Account Manager',
  'se.hr.back-to-editor-btn': 'Back to editor',
  'se.hr.history_undo-tooltip': 'Undo',
  'se.hr.history_redo-tooltip': 'Redo',
  'se.hr.save_init': 'Saved',
  'se.hr.save_pending': 'Saving',
  'se.hr.save_done': 'Saved',
  'se.hr.save_tooltip': 'Any changes is automatically saved',
  'se.hr.device-name_phone': 'Phone',
  'se.hr.device-name_note': 'Note',
  'se.hr.device-name_tablet': 'Tablet',
  'se.hr.device-name_laptop': 'Laptop',
  'se.hr.device-name_quad': 'Quad',
  'se.hr.device-name_desktop': 'Desktop',
  'se.hr.preview-btn': 'Preview',
  'se.hr.preview-btn-tooltip': 'See how it looks',
  'se.hr.publish-btn': 'Publish',
  'se.hr.publish-btn-go-live-tooltip': 'Go live',
  'se.hr.publish-btn-all-up-to-date-tooltip': 'All up to date',
  // #endregion HEADER

  // #region SIDEBAR PLUS MENU
  'se.pm.back-btn': 'Back',
  'se.pm.limit-modal_title': 'Limit reached',
  'se.pm.limit-modal_default-title': 'No more blocks available',
  'se.pm.limit-modal_description':
    'You can add only {{limit}} blocks to page.',
  'se.pm.store-page_not-possible-to-add_title': 'Not Possible',
  'se.pm.store-page_not-possible-to-add-block_description':
    'This is a special {{pageName}} page.<3 />No blocks can be added.',
  'se.pm.store-page_not-possible-to-add-feature_description':
    'This is a special {{pageName}} page.<3 />No features can be added.',
  'se.pm.only-one-catalog-per-page_description':
    'You can add only one catalog per page.',
  'se.pm.store-page_not-possible-to-add_default-title': 'Error',
  'se.pm.store-page_not-possible-to-add_default-err-text': 'Ok',

  // #region Features - Basic
  'se.pm.features_title': 'Features',
  'se.pm.features_basic_heading': 'Heading',
  'se.pm.features_basic_text': 'Text',
  'se.pm.features_basic_button': 'Button',
  'se.pm.features_basic_icon': 'Icon',
  'se.pm.features_basic_photo': 'Photo',
  'se.pm.features_basic_video': 'Video',
  'se.pm.features_basic_gallery': 'Gallery',
  'se.pm.features_basic_slider': 'Slider',
  'se.pm.features_basic_form': 'Form',
  'se.pm.features_basic_map': 'Map',
  // #endregion Features - Basic

  // #region Features - Layout
  'se.pm.features_section-title_layout': 'Layout',
  'se.pm.features_layout_space': 'Space',
  'se.pm.features_layout_panel': 'Panel',
  'se.pm.features_layout_divider': 'Divider',
  // #endregion Features - Layout

  // #region Features - Blog
  'se.pm.features_section-title_blog': 'Blog',
  'se.pm.features_blog_post': 'Post',
  'se.pm.features_blog_posts': 'Posts',
  // #endregion Features - Blog

  // #region Features - Social
  'se.pm.features_section-title_social': 'Social',
  'se.pm.features_social_social-btns': 'Social Buttons',
  'se.pm.features_social_sharing-btns': 'Sharing Buttons',
  'se.pm.features_social_instagram': 'Instagram',
  'se.pm.features_social_facebook-like': 'Facebook Like',
  'se.pm.features_social_facebook-page': 'Facebook Page',
  'se.pm.features_social_facebook-comments': 'Facebook Comments',
  'se.pm.features_social_twitter-post': 'Twitter Post',
  'se.pm.features_social_twitter-list': 'Twitter List',
  // #endregion Features - Social

  // #region Features - Advanced
  'se.pm.features_section-title_advanced': 'Advanced',
  'se.pm.features_advanced_embed-code': 'Embedded Code',
  // #endregion Features - Advanced

  // Features - Premium
  'se.pm.features_premium_group':
    'Upgrade required to use {{featureGroup}} features.',
  'se.pm.features_premium_single':
    'Upgrade required to use {{featureName}} feature.',
  'se.pm.features_premium_link_group':
    'Get access to {{featureGroup}} features now.',
  'se.pm.features_premium_link_single':
    'Get access to {{featureName}} feature now.',

  // #region Online Store
  'se.pm.features_section-title_online-store': 'Online Store',
  'se.pm.features_online-store_product': 'Product',
  'se.pm.features_online-store_catalog': 'Catalog',
  // #endregion Online Store

  'se.es.getstarted_description':
    'Start selling online with your own Shopping Cart. It\'s that simple! Just add your products, set your price and select your payment options.',
  'se.es.getstarted_btn': 'Get Started',
  'se.es.title': 'Online store',
  'se.es.products-tab_title': 'Products',
  'se.es.settings-tab_title': 'Settings',

  'se.es.settings-tab_payments_title': 'Payments',
  'se.es.settings-tab_store-information_title': 'Store Information',
  'se.es.settings-tab_regional-settings_title': 'Regional Settings',
  'se.es.settings-tab_sales-channels_title': 'Sales Channels',
  'se.es.settings-tab_marketplaces_title': 'Marketplaces',
  'se.es.settings-tab_facebook-store_title': 'Facebook Store',
  'se.es.settings-tab_shipping_title': 'Shipping',
  'se.es.settings-tab_taxes_title': 'Taxes',
  'se.es.settings-tab_notifications_title': 'Notifications',

  'se.es.discounts-tab_title': 'Discounts',
  'se.es.orders-tab_title': 'Orders',

  'se.es.payments_title': 'Payments',
  'se.es.payments_button_manage': 'Manage',
  'se.es.payments_info_enable':
    'Enable payment options to accept Credit Cards, PayPal, and other payment types during checkout.',
  'se.es.payments_popup_title': 'Payment Options',
  'se.es.payments-ecwid_loading_title': 'Loading Payment Options',

  // #region Blocks
  'se.pm.blocks_title': 'Blocks',
  'se.pm.blocks_manage-saved-block_title': 'Manage saved blocks',
  'se.pm.blocks_manage-block_btn-text': 'Manage Blocks',
  'se.pm.blocks_done_btn-text': 'DONE',
  'se.pm.blocks_saved_blocks': 'Saved Blocks',
  'se.pm.blocks_saved_blocks_item': 'Block #{{num}}',
  // #endregion Blocks

  // #endregion SIDEBAR PLUS MENU

  // #region SIDEBAR PAGES
  // #region General
  'se.sp.menu-title': 'Menu',
  'se.sp.add-btn': 'Add',
  'se.sp.new-link': 'New Link',
  'se.sp.new-folder': 'New Folder',
  'se.sp.new-page': 'New Page',
  'se.sp.cancel-btn': 'Cancel',
  'se.sp.delete-btn': 'Delete',
  'se.sp.hidden-section-title': 'Hidden',
  'se.sp.hidden-section-description':
    'The hidden pages are published, but do not appear in menu.',
  'se.sp.out-of-plan-section-title': 'Out of plan',
  'se.sp.out-of-plan-section-description':
    'The out of plan pages are NOT published and do not appear in menu.',
  'se.sp.pages-limit-reached-title': 'No More Pages Available',
  'se.sp.pages-limit-reached-description':
    '<0>You have reached your page limit of {{maxAllowedPages}}.</0><1>Upgrade or purchase additional pages.</1>',
  // #endregion General

  // #region Toolbar
  'se.sp.toolbar_page-btn': 'Page',
  'se.sp.toolbar_folder-btn': 'Folder',
  'se.sp.toolbar_link-btn': 'Link',
  // #endregion Toolbar

  // #region Add new page
  'se.sp.add-page_title': 'Add New Page',
  'se.sp.add-page_description': 'Choose type of page to create.',
  'se.sp.add-page_blank-option': 'Blank',
  'se.sp.add-page_duplicate-option': 'Duplicate',
  'se.sp.add-page_template-option': 'Template',
  'se.sp.add-page_all-templates': 'All Templates',
  'se.sp.add-page_this-site': 'This site',
  // #endregion Add new page

  // #region Edit page
  'se.sp.edit-page_title': '{{itemType}} Settings',
  'se.sp.edit-page_type_post': 'Post',
  'se.sp.edit-page_confirm-page-delete_title': 'Delete page',
  'se.sp.edit-page_confirm-page-delete_description':
    'Are you sure you want to permanently delete {{name}} page?',
  'se.sp.edit-page_confirm-homepage-hiding_title': 'Hide homepage',
  'se.sp.edit-page_confirm-homepage-hiding_description':
    'After hiding <1>{{newHome}}</1> page will become homepage for your site.',
  'se.sp.edit-page_confirm-last-page-delete_title': 'Cannot delete last page',
  'se.sp.edit-page_confirm-last-page-delete_description':
    'Last page cannot be deleted. Please add a new page before deleting this page.',
  'se.sp.edit-page_confirm-last-page-hide_title': 'Cannot hide last page',
  'se.sp.edit-page_confirm-last-page-hide_description':
    'Last page cannot be hidden. Please add a new page before hiding this page',
  'se.sp.edit-page_post-align_label': 'Alignment',
  'se.sp.edit-page_post-align_value-center': 'Center',
  'se.sp.edit-page_post-align_value-left': 'Left',
  'se.sp.edit-page_post-align_value-right': 'Right',
  'se.sp.edit-page_post-aspect_label': 'Aspect Ratio',
  'se.sp.edit-page_post-aspect_value-horizontal': 'Horizontal',
  'se.sp.edit-page_post-aspect_value-square': 'Square',
  'se.sp.edit-page_post-aspect_value-vertical': 'Vertical',
  'se.sp.edit-page_post-card-spacing_label': 'Card Spacing',
  'se.sp.edit-page_post-card-title-position_label': 'Title Position',
  'se.sp.edit-page_post-card-title-position_value-bottom': 'Bottom',
  'se.sp.edit-page_post-card-title-position_value-middle': 'Middle',
  'se.sp.edit-page_post-card-title-position_value-top': 'Top',
  'se.sp.edit-page_post-divider_label': 'Divider',
  'se.sp.edit-page_post-grid-style_label': 'Grid Style',
  'se.sp.edit-page_post-grid-style_value-card': 'Card',
  'se.sp.edit-page_post-grid-style_value-standard': 'Standard',
  'se.sp.edit-page_post-layout_label': 'Format',
  'se.sp.edit-page_post-layout_value-feed': 'Feed',
  'se.sp.edit-page_post-layout_value-grid': 'Grid',
  'se.sp.edit-page_post-layout_value-list': 'List',
  'se.sp.edit-page_post-list-style_label': 'List Style',
  'se.sp.edit-page_post-list-style_value-standard': 'Standard',
  'se.sp.edit-page_post-list-style_value-compact': 'Compact',
  'se.sp.edit-page_post-meta_label': 'Meta',
  'se.sp.edit-page_post-meta_text': '',
  'se.sp.edit-page_post-navigation-style_label': 'Pagination Style',
  'se.sp.edit-page_post-navigation-style_value-previous': 'Previous',
  'se.sp.edit-page_post-navigation-style_value-standard': 'Standard',
  'se.sp.edit-page_post-pagination-type_label': 'Navigation Type',
  'se.sp.edit-page_post-pagination-type_value-pagination': 'Pagination',
  'se.sp.edit-page_post-pagination-type_value-endless': 'Endless',
  'se.sp.edit-page_post-row-limit_label': 'Posts Per Row',
  'se.sp.edit-page_post-width_label': 'Page Width',
  'se.sp.edit-page_post-width_value-narrow': 'Narrow',
  'se.sp.edit-page_post-width_value-standard': 'Standard',
  'se.sp.edit-page_post-width_value-wide': 'Wide',
  'se.sp.edit-page_posts-per-page_label': 'Posts per page',
  'se.sp.edit-page_post-excerpt_label': 'Excerpt',
  'se.sp.edit-page_post-thumbnails_label': 'Thumbnails',
  'se.sp.edit-page_name': 'Name',
  'se.sp.edit-page_url': 'Url',
  'se.sp.edit-page_url-validation_required': 'Page URL cannot be empty.',
  'se.sp.edit-page_url-validation_latin': 'Please enter a real page URL.',
  'se.sp.edit-page_url-validation_no-space': 'Please enter a real page URL.',
  'se.sp.edit-page_url-validation_duplicate-url':
    'This URL is already in use.',
  'se.sp.edit-page_title-text': 'Title',
  'se.sp.edit-page_description': 'Description',
  'se.sp.edit-page_advanced-btn': 'Advanced',
  'se.sp.edit-page_og-title': 'Og Title',
  'se.sp.edit-page_og-description': 'Og description',
  'se.sp.edit-page_link': 'Link',
  'se.sp.edit-page_link_open-in-new-tab': 'Open in new tab',
  'se.sp.edit-page_set-homepage-btn': 'Set as Homepage',
  'se.sp.edit-page_unhide-page-btn': 'Unhide page',
  'se.sp.edit-page_hide-page-btn': 'Hide page',
  'se.sp.edit-page_unhide-btn': 'Unhide',
  'se.sp.edit-page_make-hidden-btn': 'Make hidden',
  'se.sp.edit-page_post-page_format_label': 'Format',
  'se.sp.edit-page_post-page_format_value-standard': 'Standard',
  'se.sp.edit-page_post-page_format_value-hero': 'Hero',
  'se.sp.edit-page_post-page_width_label': 'Post Width',
  'se.sp.edit-page_post-page_width_value-narrow': 'Narrow',
  'se.sp.edit-page_post-page_width_value-standard': 'Standard',
  'se.sp.edit-page_post-page_width_value-wide': 'Wide',
  'se.sp.edit-page_post-page_meta_label': 'Show Meta',
  'se.sp.edit-page_post-page_meta-style_label': 'Meta Style',
  'se.sp.edit-page_post-page_meta-style_value-standard': 'Standard',
  'se.sp.edit-page_post-page_meta-style_value-compact': 'Compact',
  'se.sp.edit-page_post-page_meta-style_value-bottom': 'Bottom',
  'se.sp.edit-page_post-page_align_label': 'Alignment',
  'se.sp.edit-page_post-page_align_value-left': 'Left',
  'se.sp.edit-page_post-page_align_value-center': 'Center',
  'se.sp.edit-page_post-page_align_value-right': 'Right',
  'se.sp.edit-page_post-page_banner-height_label': 'Banner Height',
  'se.sp.edit-page_post-page_banner-height_value-640': 'Tall',
  'se.sp.edit-page_post-page_banner-height_value-410': 'Medium',
  'se.sp.edit-page_post-page_banner-height_value-310': 'Short',
  'se.sp.edit-page_post-page_navigation_label': 'Post Navigation',
  'se.sp.edit-page_post-page_social-sharing_label': 'Social Sharing',
  'se.sp.edit-page_post-page_preview_label': 'Post Preview',
  'se.sp.edit-page_post-page_preview_value-0': 'Example Post',
  // #endregion Edit page
  // #endregion SIDEBAR PAGES

  // #region SIDEBAR THEMES
  // #region General
  'se.st.template': 'Template',
  'se.st.reset-btn': 'Reset',
  // #endregion General

  // #region Colours
  'se.st.colours': 'Colors',
  'se.st.colours_description':
    'These primary colors set the style for your template.',
  'se.st.colours_theme-select-title': 'Theme',
  'se.st.colours_reset-default-colors-confirmation_title': 'Reset colors ',
  'se.st.colours_reset-default-colors-confirmation_description':
    'Are you sure you want to reset color styles to template default?',
  'se.st.pre-colors_brand_title': 'Primary',
  'se.st.pre-colors_brand_description':
    'Main theme color, used throughout the site.',
  'se.st.pre-colors_alt-brand_title': 'Secondary',
  'se.st.pre-colors_alt-brand_description':
    'This color used for contrast as second theme color.',
  'se.st.pre-colors_text_title': 'Text',
  'se.st.pre-colors_text_description':
    'Default text color used throughout the site.',
  'se.st.pre-colors_text-alt_title': 'Light text',
  'se.st.pre-colors_text-alt_description':
    'Alternative text color used on dark backgrounds.',
  'se.st.pre-colors_complementary_title': 'Complementary',
  'se.st.pre-colors_complementary_description':
    'Used as an accent for special elements.',
  'se.st.colours_palette-item-wizzard_title': 'color wizard',
  'se.st.colours_palette-item-wizzard_description':
    'Add your logo or photo to make the perfect theme.',
  'se.st.colours_palette-item-wizzard_defined-colors_title': 'Defined colors',
  'se.st.colours_palette-item-wizzard_defined-colors_description':
    'Drag and drop to replace colors.',
  'se.st.colours_palette-item-wizzard_defined-colors_save-btn': 'Save',
  'se.st.colours_palette-item-wizzard_defined-colors_cancel-btn': 'Cancel',
  // #endregion Colours

  // #region Fonts
  'se.st.fonts': 'Fonts',
  'se.st.fonts_description':
    'Fonts give your site personality. Fonts are chosen based on style and subject.',
  'se.st.fonts_reset-default-fonts-confirmation_title': 'Reset fonts',
  'se.st.fonts_reset-default-fonts-confirmation_description':
    'Are you sure you want to reset font styles to template default?',
  'se.st.fonts_use-one-text': 'Use one font only',
  'se.st.typography-form_style-btn': 'Style',
  'se.st.typography-form_close-btn': 'Close',
  'se.st.typography-form_heading': 'heading',
  'se.st.typography-form_heading-and-text': 'heading & text',
  'se.st.typography-form_text': 'text',
  'se.st.typography_font-size': 'Font size',
  'se.st.typography_line-height': 'Line height',
  'se.st.typography_font-letter-spacing': 'Font letter spacing',
  // #endregion Fonts

  // #region Headings
  'se.st.headings': 'Headings',
  'se.st.headings_description':
    'These typography styles are used for each heading. Adjust them to fit your brand.',
  'se.st.headings_reset-default-headings-confirmation_title':
    'Reset headings',
  'se.st.headings_reset-default-headings-confirmation_description':
    'Are you sure you want to reset heading styles to template default?',
  'se.st.heading_style-title_banner-text': 'Banner text',
  'se.st.heading_style-title_main-heading': 'Main heading',
  'se.st.heading_style-title_heading': 'Heading',
  'se.st.heading_style-title_small-heading': 'Small heading',
  'se.st.heading_style-title_subheading': 'Subheading',
  'se.st.heading_style-title_text': 'Text',
  // #endregion Headings

  // #region Links
  'se.st.links': 'Links',
  'se.st.links_description':
    'Selected styles used for displaying text links on your site. Adjust for your brand.',
  'se.st.links_reset-default-headings-confirmation_title': 'Reset links',
  'se.st.links_reset-default-headings-confirmation_description':
    'Are you sure you want to reset link styles to template default?',
  // #endregion Links

  // #region Buttons
  'se.st.buttons': 'Buttons',
  'se.st.buttons_description':
    'Button styles used for this template. Adjust them to fit your brand.',
  'se.st.buttons_reset-default-buttons-confirmation_title': 'Reset buttons',
  'se.st.headings_reset-default-buttons-confirmation_description':
    'Are you sure you want to reset button styles to template default?',
  'se.st.buttons_type_primary-solid': 'Primary solid',
  'se.st.buttons_type_secondary-solid': 'Secondary solid',
  'se.st.buttons_type_primary-outline': 'Primary outline',
  'se.st.buttons_type_secondary-outline': 'Secondary outline',
  'se.st.buttons_button-form_button-text': 'Button',
  'se.st.buttons_button-form_link': 'Link',
  // #endregion Buttons

  // #region Quotes
  'se.st.quotes': 'Quotes',
  'se.st.quotes_description':
    'Quote styles and designs selected for this template. Adjust for your brand.',
  'se.st.quotes_reset-default-quotes-confirmation_title': 'Reset quotes',
  'se.st.quotes_reset-default-quotes-confirmation_description':
    'Are you sure you want to reset quote styles to template default?',
  'se.st.quotes_blockquote-primary': 'Primary',
  'se.st.quotes_blockquote-secondary': 'Secondary',
  // #endregion Quotes

  // #region Divider
  'se.st.divider': 'Divider',
  'se.st.divider_reset-default-confirmation_title': 'Reset divider',
  'se.st.divider_reset-default-confirmation_description':
    'Are you sure you want to reset divider styles to template default?',
  'se.st.divider_description':
    'Selected styles used for displaying divider on your site. Adjust for your brand.',
  'se.st.divider_subtitle': 'Theme',
  'se.st.divider_theme-solid': 'solid',
  'se.st.divider_theme-dashed': 'dashed',
  'se.st.divider_theme-dotted': 'dotted',
  'se.st.divider_theme-dots': 'dots',
  'se.st.divider_theme-waves': 'waves',
  'se.st.divider_theme-slant': 'slant',
  'se.st.divider_theme-dash': 'dash',
  'se.st.divider_theme-zigzag': 'zigzag',
  // #endregion Divider
  // #endregion SIDEBAR THEMES

  // ====================
  // === ONLINE STORE SETTINGS
  // ====================
  // #region Shipping
  'se.es.sh.title': 'Shipping',
  'se.es.sh.set_up_how_to_deliver_description':
    'Setup how to deliver products to your customers or set a pick up address.',
  'se.es.sh.shipping_origin': 'Shipping origin',
  'se.es.sh.warehouse_or_shop_address_description':
    'Your warehouse or shop address where you ship from.',
  'se.es.sh.shipping_options': 'Shipping options',
  'se.es.sh.create_shipping_description':
    'Create shipping options for your customers to choose from.',
  'se.es.sh.add_address': 'Add address',
  'se.es.sh.add': 'Add',
  'se.es.sh.create_shipping_options_description':
    'Create shipping options for your customers to choose from.',
  'se.es.sh.shipping_origin_this_is_the_address_description':
    'This is the address from where you ship your orders and is used to calculate real-time shipping rates.',
  'se.es.sh.shipping-ecwid_loading_title': 'Loading Shipping Options',

  // ====================
  // === SIDEBAR SETTINGS
  // ====================
  // #region General
  'se.ss.menu-item_general': 'General',
  // #endregion General

  // #region Domains
  'se.ss.menu-item_domains': 'Domains',
  'se.ss.domains_description':
    'Connect and manage domain names for your site.',
  'se.ss.domains_connect-domain-btn': 'Connect domain',
  'se.ss.domains_manage-domain-names': 'Manage domain names for your site.',
  'se.ss.domains_status-name_free': 'Free',
  'se.ss.domains_status-name_active': 'Active',
  'se.ss.domains_status-name_connected': 'Connected',
  'se.ss.domains_status-name_primary': 'Primary',
  'se.ss.domains_section-free-title': 'Free',
  'se.ss.domains_section-connected-title': 'Connected',
  'se.ss.domains_section-resellers-domains':
    '{{resellerDisplayName}} domains',
  'se.ss.domains_primary-domain-description':
    'This is the domain your visitors will see when accessing your site. All other domains associated with this site will redirect here.',
  'se.ss.domains_free-domain-description':
    'This is your free {{resellerDisplayName}} domain. Your site can always be accessed from this domain and it never expires.',
  'se.ss.domains_free_site-address': 'Site address',
  'se.ss.domains_connect-domain-title': 'Connect domain',
  'se.ss.domains_connect-domain-description':
    'Connect a domain you own from a third-party domain provider to your {{resellerDisplayName}} site.',
  'se.ss.domains_rename-btn': 'Rename',
  'se.ss.domains_continue-btn': 'Continue',
  'se.ss.domains_refresh-btn': 'Refresh',
  'se.ss.domains_dns-settings-title': 'DNS settings',
  'se.ss.domains_dns-settings-description':
    'This table shows you the DNS entries needed to connect your domain. Incorrect or missing entries are highlighted in red. Log in to your domain provider to manage these settings.',
  'se.ss.domains_dns-settings_table_host': 'host',
  'se.ss.domains_dns-settings_table_record': 'record',
  'se.ss.domains_dns-settings_table_alias': 'alias',
  'se.ss.domains_dns-settings_table_status': 'status',
  'se.ss.domains_dns-settings_status_connected': 'Connected',
  'se.ss.domains_dns-settings_status_not-connected': 'Not connected',
  'se.ss.domains_dns-settings_domain-title-dns-settings': 'dns settings',
  'se.ss.domains_connect-domain': 'Connect domain',
  'se.ss.domains_disconnect-domain': 'Disconnect domain',
  'se.ss.domains_make-primary-domain': 'Make primary domain',
  'se.ss.domains_disconnect-domain-modal-confirmation-description':
    'Are you sure you want to disconnect this domain? Free domain will be primary.',
  // #endregion Domains

  // #region Accounts
  'se.ss.menu-item_accounts': 'Accounts',
  // #endregion Accounts

  // #region Marketing
  'se.ss.menu-item_marketing': 'Marketing',
  'se.ss.marketing_connect-btn': 'Connect',
  'se.ss.marketing_save-btn': 'Save',
  'se.ss.marketing_cancel-btn': 'Cancel',
  'se.ss.marketing_validation_required-field': 'Filed is required.',
  'se.ss.marketing_validation_enter-track-id':
    'Please enter your Tracking ID.',
  'se.ss.marketing_validation_enter-container-id':
    'Please enter your Container ID.',
  'se.ss.marketing_validation_enter-verification-key':
    'Please enter your Verification Key.',
  'se.ss.marketing_validation_site-verification-input-hint':
    'Does not work with {{domain}}.',
  'se.ss.marketing_google-analytics_title': 'Google analytics',
  'se.ss.marketing_google-analytics_description':
    'Enter your Google Analytics account number to enable Google Analytics on your site.',
  'se.ss.marketing_google-tag-manager_title': 'Google Tag Manager',
  'se.ss.marketing_google-tag-manager_description':
    'Enter your Google Tag Manager code to quickly deploy Google and third-party tags on your site.',
  'se.ss.marketing_site-verification_title': 'Site Verification',
  'se.ss.marketing_site-verification_input-placeholder': 'Verification Key',
  'se.ss.marketing_site-verification-code_title': 'Verification code',
  'se.ss.marketing_site-verification_description':
    'Enter your Google Site Verification code, then click “Publish” to apply changes.',
  'se.ss.marketing_pinterest_button_title': 'Pinterest Button',
  'se.ss.marketing_pinterest_title': 'Pin it button',
  'se.ss.marketing_pinterest_description':
    'The Pin It button is the best way for your business to get discovered online. Let people pin your site to Pinterest.',
  'se.ss.marketing_pinterest_example': 'Example',
  'se.ss.marketing_pinterest_button-size': 'Button Size',
  'se.ss.marketing_pinterest_button-size_small': 'Small',
  'se.ss.marketing_pinterest_button-size_big': 'Big',
  'se.ss.marketing_pinterest_button-shape': 'Button Shape',
  'se.ss.marketing_pinterest_button-shape_rectangle': 'Rectangle',
  'se.ss.marketing_pinterest_button-shape_rounded': 'Rounded',
  'se.ss.marketing_pinterest_button-colour': 'Button Color',
  'se.ss.marketing_pinterest_button-colour_red': 'Red',
  'se.ss.marketing_pinterest_button-colour_gray': 'Gray',
  'se.ss.marketing_pinterest_button-colour_white': 'White',
  // #endregion Marketing

  // #region Blog
  'se.ss.menu-item_blog': 'Blog',
  // #endregion Blog

  // #region Legal
  'se.ss.menu-item_legal': 'Legal',
  'se.ss.legal_description':
    'Manage your website\'s legal pages and cookie policy.',
  'se.ss.legal_cookie-banner_label': 'Cookie banner',
  'se.ss.legal_cookie-banner_description':
    'Display a banner notice on your website to inform your visitors of cookies used by your website',
  'se.ss.legal_cookie-banner_note':
    'Note: Notice cannot be disabled according to GDPR policy.',
  'se.ss.legal_sp-analytics_label': 'Website Analytics',
  'se.ss.legal_sp-analytics_description':
    'Collect information about your website visitors to provide accurate analytics about website traffic data.',
  'se.ss.legal_banner-text_label': 'Banner text',
  'se.ss.legal_banner-text_reset-btn': 'Reset',
  'se.ss.legal_banner-text_default-value':
    'We use cookies to enable essential functionality on our website, and analyze website traffic. By clicking Accept or continuing to use our website, you consent to our use of cookies.',
  'se.ss.legal_banner-text_left-symbols': 'Left {{count}} symbol',
  'se.ss.legal_banner-text_left-symbols_plural': 'Left {{count}} symbols',
  'se.ss.legal_banner-text_validation_small-value':
    'Banner text must be more than {{ minValue }} characters.',
  'se.ss.legal_button-style_label': 'Button style',
  'se.ss.legal_button-style_bar': 'Bar',
  'se.ss.legal_banner-position_label': 'Banner position',
  'se.ss.legal_button-position_bottom': 'Bottom',
  'se.ss.legal_banner-theme_label': 'Banner theme',
  'se.ss.legal_button-theme_dark': 'Dark',
  'se.ss.legal_button-theme_light': 'Light',
  // #endregion Legal

  // #region Advanced
  'se.ss.menu-item_advanced': 'Advanced',
  'se.ss.advanced_site-editor': 'Site Editor',
  'se.ss.advanced_description':
    'Unlock additional features in the Site Editor.',
  'se.ss.advanced_block-creator-switcher_label': 'Block Creator',
  'se.ss.advanced_block-creator-switcher_description':
    'Save your block layouts and use them across multiple pages or website.',

  'se.ss.advanced_404-page': '404 Error Page',
  'se.ss.advanced_404-page_description':
    '404 Error Page will be shown when a visitor goes to an invalid URL on your site.',
  'se.ss.advanced_404-page_layout': 'Layout',
  'se.ss.advanced_404-page_change': 'Change',
  'se.ss.advanced_404-page_cancel': 'Cancel',
  'se.ss.advanced_404-page_apply': 'Apply',
  'se.ss.advanced_404-page_confirm-reset_title': 'Change 404',
  'se.ss.advanced_404-page_confirm-reset_description':
    'All your 404 page data will be lost. Are you sure you want to change it?',

  'se.ss.advanced_delete-store': 'Delete Store',
  'se.ss.advanced_delete-store_description':
    'Deleting your Online Store will delete all products, categories and orders. This will not be recoverable once deleted.',
  'se.ss.advanced_delete-store_confirm-reset_title': 'Delete Store',
  'se.ss.advanced_delete-store_confirm-reset_unpublished-description':
    'This will delete the Store including all products and orders from your website. Continue?',
  'se.ss.advanced_delete-store_confirm-reset_published-description':
    'This will make your website offline and delete the Store including products and orders. Continue?',

  'se.ss.advanced_delete-store-btn': 'Delete Store',

  'se.ss.advanced_delete-site': 'Delete Site',
  'se.ss.advanced_delete-site_description':
    'Deleting your site will permanently delete your site and all of content.',
  'se.ss.advanced_delete-site_confirm-reset_title': 'Delete site',
  'se.ss.advanced_delete-site_confirm-reset_description':
    'Delete all files, photos and related content for this website.',
  'se.ss.advanced_delete-site-btn': 'Delete Site',
  // #endregion Advanced

  // #region Store
  // #region Settings
  'se.es.settings-nav-item': 'Settings',
  // #region Information
  'se.es.settings_nav-item_store-information': 'Store information',
  'se.es.si.confirm-save': 'Save',
  'se.es.si.confirm-cancel': 'Cancel',
  'se.es.si.title': 'Store Information',
  'se.es.si.business-info_description':
    'This information will appear on your invoices and will be used by customers to contact you.',

  'se.es.si.business-info_store-name-label': 'Store name',
  'se.es.si.business-info_store-name-placeholder': 'Business name',
  'se.es.si.business-info_store-name-validation_required':
    'Store name is required.',
  'se.es.si.business-info_store-name-validation_not-valid':
    'Please enter a real Store name.',

  'se.es.si.business-info_store-email-label': 'Store email',
  'se.es.si.business-info_store-email-placeholder': 'Email',
  'se.es.si.business-info_store-email-validation_required':
    'Please enter your email address.',
  'se.es.si.business-info_store-email-validation_not-valid':
    'Please enter valid email address.',

  'se.es.si.business-info_contact-phone-label': 'Contact phone',
  'se.es.si.business-info_contact-phone-placeholder': '1 234-567-8901',
  'se.es.si.business-info_contact-phone-validation_required':
    'Please enter a real phone number.',
  'se.es.si.business-info_contact-phone-validation_not-valid':
    'Please enter a real phone number.',

  'se.es.si.business-info_product-type-label': 'Product type',
  'se.es.si.business-info_product-type_physical-and-e-goods':
    'Physical and E-goods',
  'se.es.si.business-info_product-type_physical': 'Physical',
  'se.es.si.business-info_product-type_e-goods': 'E-goods',
  'se.es.si.business-info_business-address-title': 'Business address',
  'se.es.si.business-info_business-address-description':
    'Your legal trading address.',
  'se.es.si.business-info_business-address-add-address': 'Add address',
  'se.es.si.business_address_title': 'Business address',
  'se.es.si.business_address_description':
    'This address will be displayed on your invoices and be used to calculate shipping rates.',
  'se.es.si.business_address_address-label': 'Address',
  'se.es.si.business_address_address-placeholder': 'Address',
  'se.es.si.business_address_country-label': 'Country',
  'se.es.si.business_address_country-placeholder': 'Select...',
  'se.es.si.business_address_city-label': 'City',
  'se.es.si.business_address_city-placeholder': 'City',
  'se.es.si.business_address_state-label': 'State',
  'se.es.si.business_address_state-placeholder': 'State',
  'se.es.si.business_address_postcode-label': 'Postcode / zip',
  // #endregion Information
  // #region Regional
  'se.es.rg.title': 'Regional Settings',
  'se.es.rg.description':
    'These regional settings will apply to your Online Store.',
  'se.es.rg.show_text_box_during_checkout':
    'Show text box where customers can enter special instruction during checkout.',
  'se.es.rg.timezone-label': 'Time zone',
  'se.es.rg.unit-system-label': 'Unit system',
  'se.es.rg.unit-system_metric': 'Metric',
  'se.es.rg.unit-system_imperial': 'Imperial',
  'se.es.rg.default-currency-label': 'Default currency',
  'se.es.rg.display-format-label': 'Display format',
  // #endregion Regional

  // #region Sales Channels
  'se.es.sc.title': 'Sales Channels',
  'se.es.sc.error-modal-title': 'Error',
  'se.es.sc.error-modal-error-title': 'Modal error',
  'se.es.sc.error-modal-description': 'Something went wrong!',
  // #region Marketplaces
  'se.es.sc.marketplaces_title': 'Marketplaces',
  'se.es.sc.marketplaces_description':
    'List your products across Ebay, Yahoo and more to increase sales.',
  'se.es.sc.marketplaces_ecwid-modal-title': 'Marketplaces',
  'se.es.sc.marketplaces_btn-setup': 'Setup Marketplaces',
  'se.es.sc.marketplaces_btn-enable': 'Enable Marketplaces',
  'se.es.sc.marketplaces_error-title': 'Error',
  'se.es.sc.marketplaces_error-description':
    'Sorry. Something went wrong. Please try later.',
  'se.es.sc.marketplaces_limit-modal-title': 'Not Available',
  'se.es.sc.marketplaces_limit-modal-description':
    'Upgrade to get access to Sales Channels features.',
  'se.es.sc.marketplaces_confirm-modal-title': 'Enable Channels',
  'se.es.sc.marketplaces_confirm-modal-description':
    'Your products will appear when someone search for your products on the Internet.',
  // #endregion Marketplaces
  // #region Facebook Store
  'se.es.sc.facebook-store_title': 'Facebook Store',
  'se.es.sc.facebook-store_description':
    'Start selling on Facebook. Join over 40,000 successful online stores with built-in checkout.',
  'se.es.sc.facebook-store_ecwid-modal-title': 'Facebook Store',
  'se.es.sc.facebook-store_btn-setup': 'Setup Facebook',
  'se.es.sc.facebook-store_btn-enable': 'Enable Facebook',
  'se.es.sc.facebook-store_error-title': 'Error',
  'se.es.sc.facebook-store_error-description':
    'Sorry. Something went wrong. Please try later.',
  'se.es.sc.facebook-store_limit-modal-title': 'Not Available',
  'se.es.sc.facebook-store_limit-modal-description':
    'Upgrade to get access to Sales Channels features.',
  'se.es.sc.facebook-store_confirm-modal-title': 'Enable Marketplace',
  'se.es.sc.facebook-store_confirm-modal-description':
    'List your products on the popular shopping sites on the Internet.',
  // #endregion Facebook Store
  // #endregion Sales Channels
  // #region Modals
  'se.es.modals_limit-modal_upgrade-btn': 'Upgrade now',

  'se.es.modals_confirm-popup_loading-btn': 'Loading',
  'se.es.modals_confirm-popup_accept-btn': 'Yes',
  'se.es.modals_confirm-popup_decline-btn': 'No',

  'se.es.modals_default-popup_accept-btn': 'Ok',
  // #endregion Modals
  // #region Notifications
  'se.es.nf.title': 'Notifications',
  'se.es.nf.description':
    'Store emails will be automatically sent to your customers.',
  'se.es.nf.email-settings-title': 'Email settings',
  'se.es.nf.email-forward-btn-title': 'Email addresses',
  'se.es.nf.email-logo-title': 'Email Logo',
  'se.es.nf.email-logo-description':
    'Logo will be displayed in all store emails.',
  'se.es.nf.email-addresses_title': 'Email Addresses',
  'se.es.nf.email-addresses_description':
    'Set up the reply-to and from email addresses. All customer notifications will be sent from these email addresses.',
  'se.es.nf.email-addresses_notifications':
    'These notifications are automatically sent out to the customer.',
  'se.es.nf.email-catalog_description':
    'These email notifications will be automatically sent to you and your customers.',
  'se.es.nf.email-addresses_reply-to-email-label': 'Reply-to email',
  'se.es.nf.email-addresses_reply-to-email-validation_required':
    'Store name is required.',
  'se.es.nf.email-addresses_reply-to-email-validation_not-valid':
    'Please enter a real email address.',

  'se.es.nf.email-addresses_reply-to-email-placeholder': 'Email',
  'se.es.nf.email-addresses_from-email-label': 'From email',
  'se.es.nf.email-addresses_from-email-placeholder': 'Email',
  'se.es.nf.email-addresses_from-email-validation_required':
    'Store name is required.',
  'se.es.nf.email-addresses_from-email-validation_not-valid':
    'Please enter a real email address.',
  // #endregion Notifications
  // #endregion Settings
  // #endregion Store

  // ====================
  // === MODALS
  // ====================
  'se.ml.confirm_title': 'Are your sure?',
  'se.ml.confirm_confirmText': 'Yes',
  'se.ml.confirm_cancelText': 'No',

  // ====================
  // === WIZARD
  // ====================
  'se.wd.title': 'Define colors',
  'se.wd.take-colors-btn': 'Take colors',
  'se.wd.text_unsupported-format-error': 'Sorry, unsupported file format.',
  'se.wd.text_supported-formats': 'We likes png, jpg and gif',
  'se.wd.text_upload-another-file': 'Click to upload another file',
  'se.wd.description':
    'Grab colors from your photo and apply this colors on your site.',

  // ====================
  // === DRAGZONE
  // ====================
  'se.dz.drag-drop-title': 'DRAG & DROP',
  'se.dz.drag-drop-subtitle': 'your images or <1>browse</1>',
  'se.dz.drag-drop-subtitle2': 'browse',
  'se.dz.drag-drop_drop-files': 'Drop your files here',
  'se.dz.drag-drop_drop_drop-multiple-text':
    'You can drop multiple PNGs, JPEGs or GIFs',

  // ====================
  // === SIDEBAR GENERAL
  // ====================
  'se.sg.subtitle': 'Favicon for browsers',
  'se.sg.description':
    'Display your brand icon on the browser tab and in your visitors site bookmarks.',
  'se.sg.image_optimization': 'Image optimisation',
  'se.sg.image_optimization_desc':
    'automatically optimises photos for best viewing across all devices.',
  'se.sg.back-to-top': 'Back to top',
  'se.sg.back-to-top_desc':
    'Let visitors easily scroll to the top of your website with a smart Back to Top button.',
  'se.sg.back-to-top_position': 'position',
  'se.sg.back-to-top_position_right': 'Right (Default)',
  'se.sg.back-to-top_position_left': 'Left',
  'se.sg.favicon_upload-image': 'Upload image',
  'se.sg.favicon_upload': 'Upload',
  'se.sg.favicon_error_image-too-big-1': 'Sorry, your image is to big!',
  'se.sg.favicon_error_image-too-big-2': 'Max available 1024 x 1024 pixels.',
  'se.sg.favicon_error_file-extenstion-not-allowed':
    'File extension not allowed.',

  // #region FONTS
  'se.ft.font': 'Font',
  'se.ft.format': 'Format',
  'se.ft.default': 'Default',

  // #region Style
  'se.ft.style': 'Style',
  'se.ft.style_thin': 'Thin',
  'se.ft.style_thin-italic': 'Thin Italic',
  'se.ft.style_extra-light': 'Extra-Light',
  'se.ft.style_extra-light-italic': 'Extra-Light Italic',
  'se.ft.style_light': 'Light',
  'se.ft.style_light-italic': 'Light Italic',
  'se.ft.style_regular': 'Regular',
  'se.ft.style_regular-italic': 'Regular Italic',
  'se.ft.style_medium': 'Medium',
  'se.ft.style_medium-italic': 'Medium Italic',
  'se.ft.style_semi-bold': 'Semi-Bold',
  'se.ft.style_semi-bold-italic': 'Semi-Bold Italic',
  'se.ft.style_bold': 'Bold',
  'se.ft.style_bold-italic': 'Bold Italic',
  'se.ft.style_extra-bold': 'Extra-Bold',
  'se.ft.style_extra-bold-italic': 'Extra-Bold Italic',
  'se.ft.style_ultra-bold': 'Ultra-Bold',
  'se.ft.style_ultra-bold-italic': 'Ultra-Bold Italic',
  // #endregion Style

  // Transform
  // ---

  // #region Transform
  // Should be careful if changing keys below
  // is needed as in some cases there is string
  // interpolation with CssRoot and Components variable values
  'se.ft.transform_default': 'Default',
  'se.ft.transform_paragraph': 'Paragraph',
  'se.ft.transform_uppercase': 'Uppercase',
  'se.ft.transform_lowercase': 'Lowercase',
  'se.ft.transform_capitalize': 'Capitalize',
  'se.ft.transform_small-caps': 'Small caps',
  // #endregion Transform
  // #endregion FONTS

  // #region COLOR PICKER & POPOVER
  'se.cp.color-list_title_theme': 'Theme',
  'se.cp.color-list_title_colours': 'Colors',
  'se.cp.color-list_title_bullet-color': 'Bullet color',
  'se.cp.color-popover_title': 'Color',
  'se.cp.color-popover_apply': 'Apply',
  'se.cp.color-popover_cancel': 'Cancel',
  // #endregion COLOR PICKER & POPOVER

  // #region COUNTER
  'se.cr.recommended-chars': 'Recommended: <1>{{recommended}}</1> chars',
  'se.cr.used-chars': 'Used: <1>{{used}}</1>',
  'se.cr.symbols-left': 'Left {{count}} symbol',
  'se.cr.symbols-left_plural': 'Left {{count}} symbols',
  // #endregion

  // #region HEADER SECTION OPTION
  'se.ho.layout-tab-title': 'Layout',
  'se.ho.layout_description': 'Add menu items and sort order by dragging.',
  'se.ho.layout_add-feature-btn': 'Add feature',
  'se.ho.layout_add-feature-popover-title': 'Add feature',
  'se.ho.layout_menu-select_edit-btn': 'edit',
  'se.ho.layout_menu-title': 'Menu',
  'se.ho.layout_menu-select_shopping-cart': 'Shopping Cart',
  // #region Layount - Logo
  'se.ho.layout_menu-select_logo': 'Logo',
  'se.ho.layout_logo_type': 'Type',
  'se.ho.layout_logo_image': 'Image',
  'se.ho.layout_logo_text': 'Text',
  'se.ho.layout_logo_upload-your-logo': 'Upload your logo for your site.',
  'se.ho.layout_logo_crop': 'Crop',
  'se.ho.layout_logo_upload': 'Upload',
  'se.ho.layout_logo_add-logo': 'Add a logo',
  'se.ho.layout_logo_name': 'Name',
  'se.ho.layout_logo_link-homepage-onclick-label':
    'On click link to homepage',
  'se.ho.layout_logo_my-site-name': 'My site name',
  'se.ho.layout_logo_crop_original': 'Original',
  'se.ho.layout_logo_crop_resize': 'Resize',
  'se.ho.layout_logo_crop_cancel': 'Cancel',
  'se.ho.layout_logo_crop_apply': 'Apply',
  // #endregion Layount - Logo

  // #region Layout - Navigation
  'se.ho.layout_menu-select_navigation': 'Menu',
  'se.so.design': 'Design',
  'se.so.swap-submenu-colours': 'Swap submenu colors',
  'se.so.change-style': 'Change style',
  // #endregion Layout - Navigation

  // #region Layout - Button
  'se.ho.layout_default-value': 'Your button',
  'se.ho.layout_header-button': 'Button',
  'se.ho.layout_menu-select_button': 'Button',
  'se.ho.layout_text': 'Text',
  'se.ho.layout_text-placeholder': 'My site name',
  'se.ho.layout_font-size': 'Font size',
  'se.ho.layout_open-link-in-new-tab': 'Open link in new tab',
  'se.ho.layout_type': 'Type',
  'se.ho.layout_letter-spacing': 'Letter spacing',
  // #endregion Layout - Button

  // #region Layout - Sociasl Buttons
  'se.ho.layout_menu-select_social-buttons': 'Social Buttons',
  // #endregion Layout - Social Buttons

  // #region Layout - Design
  'se.ho.design_background': 'Background',
  'se.ho.design_top-border': 'Top border',
  'se.ho.design_bottom-border': 'Bottom border',
  'se.ho.design_hamburger-menu-label': 'Hamburger menu',
  'se.ho.design_sticky-label': 'Fixed menu',
  'se.ho.design_sticky-tooltip_text':
    'Keep quick access to the navigation by freezing menu position at the top of the browser while you scroll down the page.',
  // #endregion Layout - Design

  // #endregion HEADER SECTION OPTION

  // #region SECTION OPTION
  'se.so.layout': 'Layout',
  'se.so.background': 'Background',
  'se.so.move': 'Move',
  'se.so.duplicate': 'Duplicate',
  'se.so.delete': 'Delete',
  'se.so.save-block': 'Save Block',
  'se.so.move-blocks': 'Move blocks',
  'se.so.block-saved_title': 'Block saved',
  'se.so.block-saved_description': 'Your block is in Build menu now.',
  'se.so.block-save-limit_description':
    'You can save only {{limit}} blocks.<4/>Delete unused to save new blocks.',
  'se.so.empty-block_placeholder':
    'This block is empty. Drag any Feature or Block from Build menu onto the block.',
  'se.so.empty-page_placeholder':
    'This page is empty. Drag any Feature or Block from Build menu onto the page.',
  'se.so.block-save-validation_description_block':
    'This block contains Online Store features.\nRemove this features to save block.',
  'se.so.block-save-validation_description_limit':
    'Custom Blocks limit exceeded.',
  'se.so.block-save-validation_description_section': 'Section not found.',
  'se.so.block-save-validation_description_section_block':
    '\'Custom Block\' or \'Section\' not found.',
  'se.so.block-save-validation_title': 'Not Possible',
  // #endregion SECTION OPTION

  // #region TOOLBAR
  // #region Layout
  'se.tb.layout_title': 'Layout',
  'se.tb.layout_columns': 'Columns',
  'se.tb.layout_align-vertically': 'Align vertically',
  // #endregion Layout
  // #region Background
  'se.tb.background_title': 'Background',
  'se.tb.background_type': 'Background type',
  'se.tb.background_select-type': 'Select background type',
  'se.tb.background_photo': 'Photo',
  'se.tb.background_upload': 'Upload',
  'se.tb.background_add-photo': 'Add photo',
  'se.tb.background_colour': 'Color',
  'se.tb.background_overlay': 'Overlay',
  'se.tb.background_video': 'Video',
  'se.tb.background_video-validation-error': 'This video doesn\'t exist.',
  'se.tb.background_map': 'Map',
  'se.tb.background_edit-map-btn': 'Edit Map',
  'se.tb.background_slider': 'Slider',
  'se.tb.background_edit-slider-btn': 'Edit Slider',
  // #endregion Background
  // #region Video
  'se.tb.link-to-video': 'Link to video',
  'se.tb.paste-video-url': 'Paste a video url to play',
  'se.tb.video_upload': 'Upload',
  'se.tb.video_add-custom-preview': 'Add preview',
  'se.tb.video_link-tooltip':
    'You can paste video from YouTube, Vimeo and Dailymotion.',
  // #endregion Video
  // #region Font
  'se.tb.font-size': 'Font size',
  'se.tb.padding': 'Padding',
  'se.tb.font': 'Font',
  'se.tb.style': 'Style',
  'se.tb.format': 'Format',
  'se.tb.color_title': 'Color',
  'se.tb.color_text': 'Text',
  'se.tb.color_active': 'Active',
  'se.tb.search-icons_placeholder': 'Search icons',
  // #endregion Font
  // #region Link Form
  'se.tb.link-form_list-select-label': 'Link to',
  'se.tb.link-form_remove': 'Remove',
  'se.tb.link-form_open-in-new-tab': 'Open link in new tab',
  'se.tb.link-form_page': 'Page',
  'se.tb.link-form_page-placeholder': 'Select page',
  'se.tb.link-form_anchor': 'Anchor',
  'se.tb.link-form_anchor-placeholder': 'Select page and anchor',
  'se.tb.link-form_url': 'URL',
  'se.tb.link-form_url-placeholder': 'Enter your URL',
  'se.tb.link-form_file': 'File',
  'se.tb.link-form_file-placeholder': 'Select your file',
  'se.tb.link-form_email': 'Email address',
  'se.tb.link-form_email-placeholder': 'Enter email address',
  'se.tb.link-form_phone': 'Phone number',
  'se.tb.link-form_phone-placeholder': 'Enter phone number',
  // #endregion Link Form

  // #region Settings
  'se.tb.divider_settings_title': 'Settings',
  'se.tb.divider_settings-spacing': 'Spacing',
  'se.tb.settings_type_primary-solid': 'Primary solid',
  'se.tb.settings_type_secondary-solid': 'Secondary solid',
  'se.tb.settings_type_primary-outline': 'Primary outline',
  'se.tb.settings_type_secondary-outline': 'Secondary outline',
  'se.tb.settings_type_link': 'Link',
  // #endregion Settings
  // #endregion TOOLBAR

  // ====================
  // === FILE MANAGER
  // ====================

  'se.fm.upload-tab_title': 'Upload',
  'se.fm.upload-tab_title-on-mobile': 'Photos',
  'se.fm.upload-tab_drop-zone_title': 'DRAG & DROP',
  'se.fm.upload-tab_drop-zone_hovered-title': 'Drop your files here',
  'se.fm.upload-tab_drop-zone_hovered-text':
    'You can drop multiple PNGs, JPEGs or GIFs',
  'se.fm.upload-tab_drop-zone_text': 'your photos or <1>browse</1>',

  'se.fm.my-photos-tab_progress-size': '{{used}} of {{available}}',
  'se.fm.my-photos-tab_progress-%': '{{percents}}%',
  'se.fm.my-photos-tab_footer_select-btn': 'Select',
  'se.fm.my-photos-tab_footer_cancel-btn': 'Cancel',
  'se.fm.my-photos-tab_breadcrumb_text': 'My Sites',
  'se.fm.my-photos-tab_empty_title': 'No Photos',
  'se.fm.my-documents-tab_empty_title': 'No documents yet',
  'se.fm.my-documents-tab_empty_text': 'Drop your documents here or upload',
  'se.fm.my-documents-tab_title': 'My Documents',
  'se.fm.my-photos-tab_title': 'My Photos',
  'se.fm.my-photos-tab_title-template': 'Template',
  'se.fm.my-photos-tab_title-on-mobile': 'Gallery',

  'se.fm.clouds-tab_title': 'Clouds',
  'se.fm.clouds-tab_manage-clouds': 'Manage Clouds',
  'se.fm.clouds-tab_content_title': 'Access your photos',
  'se.fm.clouds-tab_content_subtitle':
    'Connect to your photos and files from any online service.',
  'se.fm.clouds-tab_content_connect-btn': 'Connect',
  'se.fm.clouds-tab_content_disconnect-btn': 'Disconnect',
  'se.fm.clouds-tab_content_remove-btn': 'Remove',

  'se.fm.clouds-tab_content_auth-page_google_content':
    'Connect and access all your photos and files. We never modify or delete files.',
  'se.fm.clouds-tab_content_auth-page_instagram_content':
    'Connect and access all your photos and files. We never modify or delete files.',
  'se.fm.clouds-tab_content_auth-page_facebook_content':
    'Connect and access all your photos and files. We never modify or delete files.',
  'se.fm.clouds-tab_content_auth-page_dropbox_content':
    'Connect and access all your photos and files. We never modify or delete files.',
  'se.fm.clouds-tab_content_auth-page_box_content':
    'Connect and access all your photos and files. We never modify or delete files.',
  'se.fm.clouds-tab_content_auth-page_flickr_content':
    'Connect and access all your photos and files. We never modify or delete files.',

  'se.fm.free-photos-tab_title': 'Free photos',
  'se.fm.free-photos-tab_breabcrumb_categories': 'Categories',
  'se.fm.free-photos-tab_breabcrumb_search-result': 'Search result',
  'se.fm.free-photos-tab_breabcrumb_collections': 'Collections',
  'se.fm.free-photos-tab_search_placeholder': 'Search Unsplash photos',
  'se.fm.free-photos-tab_search_placeholder-on-mobile': 'Search Free photos',
  'se.fm.free-photos-tab_title-on-mobile': 'Free',
  'se.fm.free-photos-tab_cancel-btn': 'Cancel',
  'se.fm.free-photos-tab_select-btn': 'Select',
  'se.fm.free-photos-tab_back-btn': 'Back to photos',
  'se.fm.free-photos-tab_err_title': 'Error',
  'se.fm.free-photos-tab_err_text': 'Something happened try again',
  'se.fm.free-photos-tab_empty-search_title_default': 'No Photos',
  'se.fm.free-photos-tab_empty-search_description_default':
    'Drop your photos here or upload.',
  'se.fm.free-photos-tab_empty-search_title': 'No Results Found',
  'se.fm.free-photos-tab_empty-search_description':
    'Sorry, we couldn\'t find any matches.',

  'se.fm.free-photos_categories_business': 'Business',
  'se.fm.free-photos_categories_nature': 'Nature',
  'se.fm.free-photos_categories_health': 'Health',
  'se.fm.free-photos_categories_work': 'Work',
  'se.fm.free-photos_categories_food': 'Food',
  'se.fm.free-photos_categories_people': 'People',
  'se.fm.free-photos_categories_office': 'Office',
  'se.fm.free-photos_categories_music': 'Music',
  'se.fm.free-photos_categories_beauty': 'Beauty',
  'se.fm.free-photos_categories_architecture': 'Architecture',
  'se.fm.free-photos_categories_abstract': 'Abstract',
  'se.fm.free-photos_categories_forest': 'Forest',
  'se.fm.free-photos_categories_family': 'Family',
  'se.fm.free-photos_categories_portfolio': 'Portfolio',
  'se.fm.free-photos_categories_style': 'Style',
  'se.fm.free-photos_categories_journey': 'Journey',
  'se.fm.free-photos_categories_services': 'Services',
  'se.fm.free-photos_categories_restaurant': 'Restaurant',
  'se.fm.free-photos_categories_landscape': 'Landscape',
  'se.fm.free-photos_categories_travel': 'Travel',
  'se.fm.free-photos_categories_marketing': 'Marketing',
  'se.fm.free-photos_categories_technology': 'Technology',
  'se.fm.free-photos_categories_caffe': 'Caffe',
  'se.fm.free-photos_categories_personal': 'Personal',
  'se.fm.free-photos_categories_animal': 'Animal',
  'se.fm.free-photos_categories_city': 'City',
  'se.fm.free-photos_categories_fashion': 'Fashion',
  'se.fm.free-photos_categories_adventure': 'Adventure',

  'se.fm.network-err_title': 'Network Error',
  'se.fm.network-err_subtitle':
    'Network connection is temporary unavailable.',
  'se.fm.network-store-err_subtitle':
    'Online Store connection is temporary unavailable. Please try again.',
  'se.fm.network-err_try-again-btn': 'Try Again',

  // ERROR STRINGS
  'se.fm.err-string_auth_title': 'Authentification error',
  'se.fm.err-string_auth_mesage': 'You are not authenticated',
  'se.fm.err-string_cloud_mesage': 'Cloud service error.',
  'se.fm.err-string_cloud_description':
    'This cloud service is temporary unavailable.',
  'se.fm.err-string_filesize_mesage': 'File\'s size is too large',
  'se.fm.err-string_filesize_description':
    'Max available file size is {{size}} MB.',
  'se.fm.err-string_maxfiles_mesage': 'Max files upload limit reached',
  'se.fm.err-string_maxfiles_description':
    'Cannot upload more than 30 files.',
  'se.fm.err-string_mietype_mesage': 'File has wrong filetype',
  'se.fm.err-string_mietype_description':
    'Please upload .png, .jpg, .bmp or .gif.',
  'se.fm.err-string_mietype_description-alltypes':
    'Please upload office documents, graphic assets or archive with your files.',
  'se.fm.err-string_network_mesage': 'Network error.',
  'se.fm.err-string_network_description':
    'Network connection is temporary unavailable.',
  'se.fm.err-string_notfound_mesage': 'File not found',
  'se.fm.err-string_notfound_description':
    'This file is temporary unavailable.',
  'se.fm.err-string_storage_mesage': 'Max storage limit reached',
  'se.fm.err-string_storage_description':
    'There is no free space in your account.',
  'se.fm.err-string_system_mesage': 'A server error has occurred.',
  'se.fm.err-string_system_description': 'Server is temporary unavailable.',
  'se.fm.err-string_unknown_mesage': 'System Error.',
  'se.fm.err-string_unknown_description': 'Server is temporary unavailable.',
  'se.fm.err-string_loading_cursor_overlay':
    'Cannot find loading cursor of {{cursorElements}}, loading overlay of {{overlayElements}}',

  // #region WIDGETS FEATURES

  'se.wf.edit-btn': 'Edit',
  'se.wf.resize-max': 'Max',
  'se.wf.resize-min': 'Min',

  // #region MAP
  'se.wf.map_settings_title': 'Settings',
  'se.wf.map_design_title': 'Design',
  'se.wf.map_setting_address_title': 'Address',
  'se.wf.map_setting_address_placeholder': 'Address or map URL',
  'se.wf.map_setting_type_title': 'Type',
  'se.wf.map_setting_type_roadmap': 'Road Map',
  'se.wf.map_setting_type_sattellite': 'Sattellite Map',
  'se.wf.map_setting_type_hybridmap': 'Hybrid Map',
  'se.wf.map_setting_type_terrainmap': 'Terrain Map',
  'se.wf.map_setting_zoom_title': 'Zoom',
  'se.wf.map_switcher_address-bubble': 'Address bubble',
  'se.wf.map_switcher_drag-control': 'Drag control',
  'se.wf.map_switcher_zoom-control': 'Zoom control',
  // #endregion MAP

  // #region PANEL
  'se.wf.panel_background_title': 'Background',
  'se.wf.panel_design_title': 'Design',
  'se.wf.panel_background_colour_title': 'Color',
  'se.wf.panel_background_overlay_title': 'Overlay',
  'se.wf.panel_background_photo_title': 'Photo',
  'se.wf.panel_background_photo_add-photo': 'Add photo',
  'se.wf.panel_design_border_title': 'Border',
  'se.wf.panel_design_radius_title': 'Radius',
  'se.wf.panel_design_shadow_title': 'Shadow',
  'se.wf.panel_color-popover_apply': 'Apply',
  'se.wf.panel_color-popover_cancel': 'Cancel',
  // #endregion PANEL

  // #region ECOMMERCE PRODUCT
  'se.wf.ecom_product.placeholder_product_not_found':
    'You have no products yet',
  'se.wf.ecom_product.placeholder_no_products': 'You have no products yet',
  'se.wf.ecom_product.placeholder_add_new': 'Add new product',
  'se.wf.ecom_product.placeholder_add_product_text':
    'Please, click to add product',
  'se.wf.ecom_product.placeholder_no_photo': 'No photo',
  'se.wf.ecom_product.layout_title': 'Layout',
  'se.wf.ecom_product.layout_show_product': 'Show Product',
  'se.wf.ecom_product.layout_not_found': 'NOT FOUND',
  'se.wf.ecom_product.layout_loading_products': 'Loading...',
  'se.wf.ecom_product.layout_sku': 'sku',
  'se.wf.ecom_product.layout_sku_placeholder': 'Select',
  'se.wf.ecom_product.layout_sku_show': 'Show',
  'se.wf.ecom_product.layout_sku_hide': 'Hide',
  'se.wf.ecom_product.settings_title': 'Settings',
  'se.wf.ecom_product.settings_button_label': 'Button Label',
  'se.wf.ecom_product.settings_button_label_edit': 'Edit',
  'se.wf.ecom_product.settings_image_aspect_ratio': 'Image Aspect Ratio',
  'se.wf.ecom_product.settings_on_click': 'On Click',
  'se.wf.ecom_product.settings_edit_button_label': 'Edit button label',
  'se.wf.ecom_product.settings_edit_button_placeholder': 'Your placeholder',
  'se.wf.ecom_product.button_action_add_to_cart': 'Add to Cart',
  'se.wf.ecom_product.button_action_go_to_checkout': 'Go to Checkout',
  'se.wf.ecom_product.button_action_open_product_page': 'Open Product page',
  'se.wf.ecom_product.settings_product_details': 'Product Details',
  'se.wf.ecom_product.settings_product_details_on_click_name_or_photo':
    'On Click Name or Photo',
  'se.wf.ecom_product.settings_product_details_on_click_name':
    'On Click Name',
  'se.wf.ecom_product.settings_product_details_on_click_photo':
    'On Click Photo',
  'se.wf.ecom_product.design_title': 'Design',
  'se.wf.ecom_product.design_hover_effects': 'Hover Effects',
  'se.wf.ecom_product.design_hover_effects_highlight_border':
    'Highlight Border',
  'se.wf.ecom_product.design_hover_effects_highlight_background':
    'Highlight Background',
  'se.wf.ecom_product.design_hover_effects_highlight_shadow':
    'Highlight Shadow',
  'se.wf.ecom_product.design_hover_effects_zoom_in_photo': 'Zoom in photo',
  'se.wf.ecom_product.design_hover_effects_show_additional_image':
    'Show additional image',
  'se.wf.ecom_product.design_hover_effects_target_name_or_photo':
    'Name or Photo',
  'se.wf.ecom_product.design_hover_effects_target_product': 'Product',
  'se.wf.ecom_product.design_hover_effects_target_name': 'Name',
  'se.wf.ecom_product.design_hover_effects_target_photo': 'Photo',
  'se.wf.ecom_product.style': 'Style',
  'se.wf.ecom_product.design_product_colour': 'Product color',
  'se.wf.ecom_product.design_color_title_for_background': 'Background',
  'se.wf.ecom_product.design_color_title_for_boxshadow': 'Border',
  'se.wf.ecom_product_availability_visible': 'Visible',
  'se.wf.ecom_product_availability_hidden': 'Hidden',
  'se.wf.ecom_product_in_the_cart': 'in the cart',
  'se.wf.ecom_product_in_the_bag': 'in the bag',
  'se.wf.ecom_product_list_cell_spacing': 'Product list cell spacing',
  'se.wf.ecom_product_in_the_cart_item': 'item',
  'se.wf.ecom_product_in_the_cart_items': 'items',
  'se.wf.ecom_product_in_the_cart_no_more':
    'in the cart, no more left in stock',
  'se.wf.ecom_digital_product_in_the_cart':
    'This item is already in your shopping cart',
  'se.wf.ecom_product_show_count': 'Show {{ count }} Products',
  'se.wf.ecom_product_unlimited': 'Unlimited',
  // #endregion ECOMMERCE PRODUCT

  // #region ECOMMERCE CATALOG
  'se.wf.ecom_catalog.layout_title': 'Layout',
  'se.wf.ecom_catalog.category': 'Category',
  'se.wf.ecom_catalog.category_all_products': 'All Products',
  'se.wf.ecom_catalog.products_per_row': 'Products Per Row',
  'se.wf.ecom_catalog.total_rows': 'Total Rows',
  'se.wf.ecom_catalog.view_title': 'View',
  'se.wf.ecom_catalog.view_sku': 'SKU',
  'se.wf.ecom_catalog.view_price': 'Price',
  'se.wf.ecom_catalog.view_buy_now_button': 'Buy Now Button',
  'se.wf.ecom_catalog.view_add_more_button': 'Add more',
  'se.wf.ecom_catalog.view_add_to_bag_button': 'Add to bag',
  'se.wf.ecom_catalog.view_image_aspect_ratio': 'Image Aspect Ratio',
  'se.wf.ecom_catalog.view_additional_image_on_hover':
    'Additional Image On Hover',
  'se.wf.ecom_catalog.image_aspect_ratio_2x3': '2:3 Standard (vertical)',
  'se.wf.ecom_catalog.image_aspect_ratio_3x4': '3:4 Three-four (vertical)',
  'se.wf.ecom_catalog.image_aspect_ratio_1x1': '1:1 Square',
  'se.wf.ecom_catalog.image_aspect_ratio_4x3': '4:3 Four-three',
  'se.wf.ecom_catalog.image_aspect_ratio_3x2': '3:2 Standard',
  'se.wf.ecom_catalog.show_recommended_label': 'Show (recommended)',
  'se.wf.ecom_catalog.show_label': 'Show',
  'se.wf.ecom_catalog.hide_label': 'Hide',
  'se.wf.ecom_catalog.design_title': 'Design',
  'se.wf.ecom_catalog.design_background': 'Background',
  'se.wf.ecom_catalog.design_border': 'Border',
  'se.wf.ecom_catalog.design_color_title_for_product': 'Product',
  'se.wf.ecom_catalog.design_color_title_for_background': 'Background',
  'se.wf.ecom_catalog.design_products_spacing': 'Products Spacing',
  'se.wf.ecom_catalog.you_have_no_products_yet': 'You have no products yet',
  // #endregion ECOMMERCE CATALOG

  // ECOMMERCE CART
  'wf.ecom_cart.toolbar_title': 'Shopping Cart',
  'wf.ecom_cart.toolbar_label_icon': 'Icon',
  'wf.ecom_cart.toolbar_label_badge': 'Badge',
  'wf.ecom_cart.toolbar_label_style': 'Style',
  'wf.ecom_cart.toolbar_label_style_change': 'Change style',

  // #region SOCIAL
  'se.wf.social_layout_title': 'Layout',
  'se.wf.social_design_title': 'Design',
  'se.wf.social_design_alignment': 'Alignment',
  'se.wf.social_design_size': 'Size',
  'se.wf.social_design_style': 'Style',
  'se.wf.social_design_change-style': 'Change style',
  'se.wf.social_design_fill-color_background': 'Background',
  'se.wf.social_design_fill-color_icon': 'Icon',
  'se.wf.social_design_color_icon': 'Icon',
  'se.wf.social_design_color_background': 'Background',
  'se.wf.social_design_color_hover': 'Hover',
  'se.wf.social_subtitle': 'Add buttons and sort order by dragging.',
  'se.wf.social_add-social-network-btn': 'Add social network',
  'se.wf.social_networks_title': 'Social networks',
  'se.wf.social_edit-btn': 'edit',
  'se.wf.social_edit_title': 'Link',
  'se.wf.social_edit_text': 'Type your profile name or link',
  'se.wf.social_edit_link-placeholder': 'n\u200Came or l\u200Cink',
  'se.wf.social_edit_open-in-new-tab': 'On click open in new tab',
  // #endregion SOCIAL

  // #region SHARES
  'se.wf.shares_layout_title': 'Layout',
  'se.wf.shares_design_title': 'Design',
  'se.wf.shares_design_style': 'Style',
  'se.wf.shares_design_style_circle': 'Circle',
  'se.wf.shares_design_style_rounded': 'Rounded',
  'se.wf.shares_design_style_square': 'Square',
  'se.wf.shares_design_onclick': 'On click',
  'se.wf.shares_design_onclick_share-site': 'Share this site',
  'se.wf.shares_design_onclick_share-page': 'Share this page',
  'se.wf.shares_design_adaptive': 'Adaptive',
  'se.wf.shares_design_adaptive_collaps-last': 'Collapse last buttons',
  'se.wf.shares_design_adaptive_collaps-first': 'Collapse first buttons',
  'se.wf.shares_design_adaptive_collaps-equal': 'Collapse equal',
  // #endregion SHARES

  // #region INSTAGRAM
  'se.wf.instagram_settings_title': 'Settings',
  'se.wf.instagram_settings_url_placeholder': 'Username or URL',
  'se.wf.instagram_settings_total-photos': 'Total photos',
  'se.wf.instagram_settings_photo-size': 'Photo size',
  'se.wf.instagram_settings_open-in-new-tab': 'Open in new tab',
  'se.wf.instagram_design_title': 'Design',
  'se.wf.instagram_design_crop': 'Crop photos',
  'se.wf.instagram_design_ratio': 'Aspect Ratio',
  'se.wf.instagram_design_show-title': 'Show title',
  'se.wf.instagram_design_ratio_square': '1:1 Square',
  'se.wf.instagram_design_ratio_standard': '3:2 Standard',
  'se.wf.instagram_design_ratio_standard-vertical': '2:3 Standard (vertical)',
  'se.wf.instagram_design_ratio_four-three': '4:3 Four-three',
  'se.wf.instagram_design_ratio_three-four': '3:4 Three-four (vertical)',
  'se.wf.instagram_design_ratio_widescreen': '16:9 Widescreen',
  'se.wf.instagram_design_ratio_anamorphic': '2.40 Anamorphic Widescreen',
  'se.wf.instagram_default_err':
    'Sorry, something went wrong. We are working to resolve this problem. Please try again later.',
  // #endregion INSTAGRAM

  // #region FB_LIKE
  'se.wf.fb-like_settings_title': 'Settings',
  'se.wf.fb-like_settings_type': 'Type',
  'se.wf.fb-like_settings_type_like': 'Like Button',
  'se.wf.fb-like_settings_type_share&like': 'Share & Like Buttons',
  'se.wf.fb-like_settings_link_to-like': 'Link to like',
  'se.wf.fb-like_settings_link-input_placeholder': 'Facebook page or URL',
  'se.wf.fb-like_design_title': 'Design',
  'se.wf.fb-like_design_style': 'Style',
  'se.wf.fb-like_design_style_standard': 'Standard',
  'se.wf.fb-like_design_style_box-count': 'Box count',
  'se.wf.fb-like_design_style_button-count': 'Button count',
  'se.wf.fb-like_design_style_button': 'Button',
  'se.wf.fb-like_design_size': 'Size',
  'se.wf.fb-like_design_size_small': 'Small',
  'se.wf.fb-like_design_size_large': 'Large',
  // #endregion FB_LIKE

  // #region FB_PAGE
  'se.wf.fb-page_settings_title': 'Settings',
  'se.wf.fb-page_settings_type': 'Type',
  'se.wf.fb-page_settings_page-id': 'Facebook page id',
  'se.wf.fb-page_settings_page-id_placeholder': 'Facebook page or url',

  'se.wf.fb-page_settings_tabs-format': 'Tabs Format',
  'se.wf.fb-page_settings_tabs-format_timeline': 'Timeline',
  'se.wf.fb-page_settings_tabs-format_messages': 'Messages',
  'se.wf.fb-page_settings_tabs-format_events': 'Events',
  'se.wf.fb-page_settings_tabs-format_timeline&messages':
    'Timeline and messages',
  'se.wf.fb-page_settings_tabs-format_timeline&events': 'Timeline and events',

  'se.wf.fb-page_design_title': 'Design',
  'se.wf.fb-page_design_header-style': 'Header Style',
  'se.wf.fb-page_design_header-style_standard': 'Standard',
  'se.wf.fb-page_design_header-style_small': 'Small',

  'se.wf.fb-page_design_cover-photo': 'Cover Photo',
  'se.wf.fb-page_design_cover-photo_show': 'Show',
  'se.wf.fb-page_design_cover-photo_hide': 'Hide',

  'se.wf.fb-page_design_friends-faces': 'Friends Faces',
  'se.wf.fb-page_design_friends-faces_show': 'Show',
  'se.wf.fb-page_design_friends-faces_hide': 'Hide',
  // #endregion FB_PAGE

  // #region FB_COMMENTS
  'se.wf.fb-comments_settings_title': 'Settings',
  'se.wf.fb-comments_settings_link-to-comment': 'Link to comment',
  'se.wf.fb-comments_settings_link-to-comment_placeholder':
    'Facebook page or url',

  'se.wf.fb-comments_settings_number-of-comments': 'Number of comments',

  'se.wf.fb-comments_design_title': 'Design',

  'se.wf.fb-comments_design_colour-theme': 'Color theme',
  'se.wf.fb-comments_design_colour-theme_light': 'Light',
  'se.wf.fb-comments_design_colour-theme_dark': 'Dark',

  'se.wf.fb-comments_design_display-by': 'Display by',
  'se.wf.fb-comments_design_display-by_most-popular': 'Most Popular',
  'se.wf.fb-comments_design_display-by_newest': 'Newest',
  'se.wf.fb-comments_design_display-by_oldest': 'Oldest',

  // #endregion FB_COMMENTS

  // #region TWITTER POST
  'se.wf.twitter-post_settings_title': 'Settings',
  'se.wf.twitter-post_settings_tweet-post-link': 'TWEET POST LINK',
  'se.wf.twitter-post_settings_tweet-url_placeholder': 'Tweet URL or ID',

  'se.wf.twitter-post_settings_media': 'Media',
  'se.wf.twitter-post_settings_media_show': 'Show Media',
  'se.wf.twitter-post_settings_media_hide': 'Hide Media',

  'se.wf.twitter-post_design_title': 'Design',

  'se.wf.twitter-post_design_style': 'Style',
  'se.wf.twitter-post_design_style_show-one': 'Show One Tweet',
  'se.wf.twitter-post_design_style_show-conversation': 'Show Conversation',

  'se.wf.twitter-post_design_colour-theme': 'Color Theme',
  'se.wf.twitter-post_design_colour-theme_light': 'Light',
  'se.wf.twitter-post_design_colour-theme_dark': 'Dark',

  'se.wf.twitter-post_err-message': 'Please enter a valid tweet URL or ID.',

  // #endregion TWITTER POST

  // #region TWITTER LIST
  'se.wf.twitter-list_settings_title': 'Settings',
  'se.wf.twitter-list_settings_twitter-link': 'Twitter link',
  'se.wf.twitter-list_settings_twitter-url_placeholder': 'Username or URL',

  'se.wf.twitter-list_settings_amount': 'Amount',

  'se.wf.twitter-list_design_title': 'Design',

  'se.wf.twitter-list_design_style': 'Style',
  'se.wf.twitter-list_design_style_standard': 'Standard',
  'se.wf.twitter-list_design_style_hidden': 'Hidden Header',

  'se.wf.twitter-list_design_colour-theme': 'Color Theme',
  'se.wf.twitter-list_design_colour-theme_white': 'White',
  'se.wf.twitter-list_design_colour-theme_transparent': 'Transparent',

  // #endregion TWITTER LIST

  // #region EMBED BLOCK
  'se.wf.embed_title': 'Embedded content',
  'se.wf.embed_code_title': 'code',
  'se.wf.embed_code_publish-info': 'Your content will be visible on publish.',
  'se.wf.embed_code_switcher-lable': 'Adapt to full height',
  'se.wf.embed_link_title': 'link',
  'se.wf.embed_code_placeholder': 'Paste code here',
  'se.wf.embed_code_description':
    'Paste code to the content that you want to show on the site.',
  'se.wf.embed_url_placeholder': 'Paste URL here',
  'se.wf.embed_url_description':
    'Enter link to the website that you want to show on the site.',

  'se.wf.embed_errors_invalid-link': 'Please enter a real website address.',
  'se.wf.embed_errors_invalid-url': 'Please enter a valid url.',
  'se.wf.embed_errors_redirect-error':
    'Website with redirect cannot be embedded.',
  'se.wf.embed_errors_x-frame-deny': 'This website cannot be embedded.',
  'se.wf.embed_errors_http-deny':
    'Website without SSL (https) cannot be embedded.',
  'se.wf.embed_errors_default': 'Sorry, something went wrong.',

  // #endregion EMBED BLOCK

  // #region TOOLBAR BUTTONS
  'se.wf.buttons_default-value': 'Button',
  'se.wf.toolbar-buttons_bold': 'bold',
  'se.wf.toolbar-buttons_italic': 'italic',
  'se.wf.toolbar-buttons_unerline': 'underline',
  'se.wf.toolbar-buttons_heading': 'heading',
  'se.wf.toolbar-buttons_align': 'align',
  'se.wf.toolbar-buttons_alt-text': 'Alt text',
  'se.wf.toolbar-buttons_link': 'link',
  'se.wf.toolbar-buttons_blockquote': 'blockquote',
  'se.wf.toolbar-buttons_list': 'list',
  'se.wf.toolbar-buttons_font': 'font',
  'se.wf.toolbar-buttons_size': 'size',
  'se.wf.toolbar-buttons_color': 'color',
  'se.wf.toolbar-buttons_image-color': 'overlay',
  'se.wf.toolbar-buttons_crop': 'Crop',
  'se.wf.toolbar-buttons_replace': 'Replace',
  'se.wf.toolbar-buttons_advanced': 'Advanced',
  'se.wf.toolbar-buttons_settings': 'settings',
  'se.wf.toolbar-buttons_icon': 'icon',
  'se.wf.toolbar-buttons_width-default': 'default',
  'se.wf.toolbar-buttons_width-wide': 'wide',
  'se.wf.toolbar-buttons_width-full': 'full',
  'se.wf.toolbar-buttons_settings_type': 'Type',
  'se.wf.toolbar-buttons_settings_background': 'Background',
  'se.wf.toolbar-buttons_settings_border-size': 'Border size',
  // #endregion TOOLBAR BUTTONS

  // #region COLOR POPOVER
  'se.wf.color-popover_default-title': 'Color',
  // #endregion COLOR POPOVER

  // #region HEADING
  'se.wf.heading_default-value': 'Heading',
  // #endregion HEADING

  // #region TEXT LIST
  'se.wf.text_default-value':
    'I’m a paragraph. Drag me to add paragraph to your block, write your own text and edit me.',
  'se.wf.text-list_offset': 'Offset',
  // #endregion TEXT LIST

  // #region ICON
  'se.wf.icon_color-default-title': 'Color',
  'se.wf.icon_icon': 'icon',
  'se.wf.icon_background': 'Background',
  'se.wf.icon_align': 'align',
  'se.wf.icon_rotate-left': 'Rotate left',
  'se.wf.icon_rotate-right': 'Rotate right',
  'se.wf.icon_flip-vertically': 'flip vertically',
  'se.wf.icon_flip-horizontally': 'flip horizontally',
  'se.wf.icon_size': 'Size',
  'se.wf.icon_search-input_placeholder': 'Se\u200Carch icons',
  'se.wf.icon_empty-list_placeholder': 'No icons found...',
  // #endregion ICON

  // #region PHOTO
  'se.wf.photo_add-photo': 'Add photo',
  'se.wf.photo_replace': 'replace',
  'se.wf.photo_crop': 'crop',
  'se.wf.photo_link': 'link',
  'se.wf.photo_overlay': 'overlay',
  'se.wf.photo_alt-text': 'Alt text',
  'se.wf.photo_settings': 'settings',
  'se.wf.photo_advanced': 'advanced',
  'se.wf.photo_image_original': 'Original',
  'se.wf.photo_image_resize': 'Resize',
  'se.wf.photo_image_cancel': 'Cancel',
  'se.wf.photo_image_apply': 'Apply',
  'se.wf.photo_settings_image-caption': 'Image caption',
  'se.wf.photo_settings_show-caption': 'Show caption',
  'se.wf.photo_settings_show-caption_on-bottom': 'On bottom',
  'se.wf.photo_settings_show-caption_dont-show': 'Don\'t show',
  'se.wf.photo_settings_on-click-enlarge': 'On click enlarge',
  'se.wf.photo_settings_on-click-enlarge_dark-theme': 'With dark theme',
  'se.wf.photo_settings_on-click-enlarge_light-theme': 'With light theme',
  // #endregion PHOTO

  // #region FORMS
  'se.wf.forms_edit_title': 'Edit',
  'se.wf.forms_alt-text_image_title_title': 'Title',
  'se.wf.forms_alt-text_image_title': 'Description',
  'se.wf.forms_alt-text_image_title_input-placeholder':
    'Please enter alt title',
  'se.wf.forms_alt-text_image_input-placeholder':
    'Please enter alt description',
  'se.wf.forms_alt-text_image_description':
    'Text to tell website viewers the nature or contents of your photo.',
  'se.wf.forms_alt-text_gallery_title_title': 'Title',
  'se.wf.forms_alt-text_gallery_title': 'Description',
  'se.wf.forms_alt-text_gallery_title_input-placeholder':
    'Please enter alt title',
  'se.wf.forms_alt-text_gallery_input-placeholder':
    'Please enter alt description',
  'se.wf.forms_alt-text_gallery_description':
    'Text to tell website viewers the nature or contents of your photo.',
  'se.wf.forms_alt-text_gallery_title_des': 'Description',
  // #endregion FORMS

  // #region VIDEO
  'se.wf.video_settings': 'Settings',
  'se.wf.video_design': 'Design',
  'se.wf.video_link-label': 'Video link',
  'se.wf.video_link-placeholder': 'Video URL or ID',
  'se.wf.video_autoplay': 'Autoplay',
  'se.wf.video_allow-full-window': 'Allow full window',
  'se.wf.video_theme': 'Theme',
  'se.wf.video_theme_red': 'Red',
  'se.wf.video_theme_white': 'White',
  'se.wf.video_preview': 'Preview',
  // #endregion VIDEO

  // #region GALLERY
  'se.wf.gallery_layout': 'Layout',
  'se.wf.gallery_design': 'Design',
  'se.wf.gallery_photo_alt-text': 'Alt text',
  'se.wf.gallery_photo_replace': 'Replace',
  'se.wf.gallery_photo-spacing': 'Photo Spacing',
  'se.wf.gallery_photo-size': 'Photo size',
  'se.wf.gallery_crop-photos': 'Crop photos',
  'se.wf.gallery_aspect-ratio': 'Aspect Ratio',
  'se.wf.gallery_add-photo-btn': 'Add Photo',
  'se.wf.gallery_add-photo-text': 'add photo',
  'se.wf.gallery_aspect-ratio_square': '1:1 Square',
  'se.wf.gallery_aspect-ratio_standard': '3:2 Standard',
  'se.wf.gallery_aspect-ratio_standard-vertical': '2:3 Standard (vertical)',
  'se.wf.gallery_aspect-ratio_four-three': '4:3 Four-three',
  'se.wf.gallery_aspect-ratio_three-four-vertical':
    '3:4 Three-four (vertical)',
  'se.wf.gallery_aspect-ratio_widescreen': '16:9 Widescreen',
  'se.wf.gallery_aspect-ratio_anamorphic-widescreen':
    '2.40 Anamorphic Widescreen',
  // #endregion GALLERY

  // #region SLIDER
  'se.wf.slider_settings': 'Settings',
  'se.wf.slider_loop-slides': 'Loop slides',
  'se.wf.slider_autoplay': 'Autoplay',
  'se.wf.slider_autoplay-unit': 's',
  'se.wf.slider_slide-1-default-value': 'New brand slider',
  'se.wf.slider_slide-2-default-value': 'Philosophies design',
  'se.wf.slider_slide-3-default-value': 'Easy navigation',
  'se.wf.slider_design': 'Navigation',
  'se.wf.slider_navigation': 'Theme',
  'se.wf.slider_advanced': 'Advanced',
  'se.wf.slider_dots_show-dots': 'Show dots',
  'se.wf.slider_dots_bottom-position': 'Bottom Position',
  'se.wf.slider_dots_top-position': 'Top Position',
  'se.wf.slider_dots_show-arrows': 'Show arrows',
  'se.wf.slider_arrows_default-position': 'Default Position',
  'se.wf.slider_arrows_left-position': 'Left Position',
  'se.wf.slider_arrows_right-position': 'Right Position',
  'se.wf.slider_themes_dark': 'Dark',
  'se.wf.slider_themes_light': 'Light',
  'se.wf.slider_align-vertically': 'Align Vertically',
  'se.wf.slider_manage-slides': 'Manage Slides',
  'se.wf.slider_slide': 'Slide',
  'se.wf.layout_add-slide-btn': 'Add Slide',
  'se.wf.slider_slides': 'slides',
  // #endregion SLIDER

  // #region FORM
  'se.wf.form_default-form-submit-btn': 'Send message',

  // #region Layout
  'se.wf.form_layout': 'Layout',
  'se.wf.form_layout_edit': 'edit',
  'se.wf.form_layout_add-field': 'Add Field',
  'se.wf.form_layout_add-form-field': 'Add form field',
  'se.wf.form_layout_title': 'Title',
  'se.wf.form_layout_placeholder': 'Placeholder',
  'se.wf.form_layout_default-currency': 'Default Currency',
  'se.wf.form_layout_split': 'Split',
  'se.wf.form_layout_validation': 'Validation',
  'se.wf.form_layout_options': 'Options',
  'se.wf.form_layout_add-selection': 'Add selection',
  'se.wf.form_layout_description': 'Description',
  'se.wf.form_layout_required-field': 'Required field',
  'se.wf.form_layout_edit-option_title-placeholder': 'label',
  'se.wf.form_layout_field-text_label': 'Text',
  'se.wf.form_layout_field-text_placeholder': 'Enter your text',
  'se.wf.form_layout_field-textarea_label': 'Text area',
  'se.wf.form_layout_field-textarea_placeholder': 'Enter your message',
  'se.wf.form_layout_field-select_label': 'Select',
  'se.wf.form_layout_field-select_placeholder': 'Select your option',
  'se.wf.form_layout_field-select_option-1': 'Option 1',
  'se.wf.form_layout_field-select_option-2': 'Option 2',
  'se.wf.form_layout_field-radio_label': 'Radio',
  'se.wf.form_layout_field-radio_option-1': 'Option 1',
  'se.wf.form_layout_field-radio_option-2': 'Option 2',
  'se.wf.form_layout_field-select_option': 'Option {{position}}',
  'se.wf.form_layout_field-name_label': 'Full Name',
  'se.wf.form_layout_field-name_placeholder-name': 'Enter your name',
  'se.wf.form_layout_field-name_placeholder-surname': 'Enter your surname',
  'se.wf.form_layout_field-address_label': 'Address',
  'se.wf.form_layout_field-address_placeholder': 'Enter your address',
  'se.wf.form_layout_field-email_label': 'Email',
  'se.wf.form_layout_field-email_placeholder': 'Enter your email address',
  'se.wf.form_layout_field-phone_label': 'Phone',
  'se.wf.form_layout_field-phone_placeholder': 'Enter your phone number',
  'se.wf.form_layout_field-facebook_label': 'Facebook',
  'se.wf.form_layout_field-facebook_placeholder': 'Link to Facebook page',
  'se.wf.form_layout_field-twitter_label': 'Twitter',
  'se.wf.form_layout_field-twitter_placeholder': 'Link to Twitter page',
  'se.wf.form_layout_field-link_label': 'Link',
  'se.wf.form_layout_field-link_placeholder': 'Enter URL',
  'se.wf.form_layout_field-date_label': 'Date',
  'se.wf.form_layout_field-date_placeholder': 'Select a date',
  'se.wf.form_layout_field-number_label': 'Number',
  'se.wf.form_layout_field-number_placeholder': 'Enter number',
  'se.wf.form_layout_field-currency_label': 'Currency',
  'se.wf.form_layout_field-currency_placeholder': 'Enter total sum',
  'se.wf.form_layout_default-submit-text': 'Thank you!',
  'se.wf.form_layout_default-submit-description':
    'We have received your submission.',
  // #endregion Layout

  // #region Settings
  'se.wf.form_settings': 'Settings',
  'se.wf.form_settings_form-name': 'Form Name',
  'se.wf.form_settings_send-form-to': 'Send form to',
  'se.wf.form_settings_send-request-to': 'Send request to',
  'se.wf.form_settings_email_title': 'Email',
  'se.wf.form_settings_sending-to-email': 'Sending to {{emailText}}',
  'se.wf.form_settings_email_save-btn': 'Save',
  'se.wf.form_settings_email_cancel-btn': 'Cancel',
  'se.wf.form_settings_email_description':
    'You’ll receive submits at this email address.',
  // #endregion Settings

  // #region Validation
  'se.wf.form_validation_not-empty-title': 'Form name cannot be empty.',
  'se.wf.form_validation_real-title': 'Please enter a real title.',
  'se.wf.form_validation_option-requried': 'This field is required.',
  'se.wf.form_validation_option-max-length':
    'Maximum length of this field is 256 characters.',
  // #endregion Validation

  'se.wf.form_advanced': 'Advanced',
  'se.wf.form_advanced_on-submit-title': 'On Submit',
  'se.wf.form_advanced_on-submit-description':
    'Display message after user submits form.',
  'se.wf.form_advanced_title-label': 'Title',
  'se.wf.form_advanced_title-validation_not-empty': 'Title cannot be empty.',
  'se.wf.form_advanced_title-validation_must-be-real':
    'Please enter a real title.',
  'se.wf.form_advanced_description-label': 'Description',
  'se.wf.form_advanced_is-horizontal': 'Is horizontal',
  'se.wf.form_advanced_placeholders': 'Placeholders',
  'se.wf.form_advanced_split-form': 'Split form by two rows',
  'se.wf.form_advanced_custom-validation': 'Custom validation',
  // #endregion FORM

  // #region Upload Image
  'se.wf.upload-image_upload-placeholder': 'Upload',
  'se.wf.upload-image_upload_add-logo-btn': 'Add logo',
  // #endregion Upload Image
  // #endregion WIDGET FEATURES

  // #region Resizable
  'sp.component.resizable.pixels': 'px',
  'sp.component.resizable.max': 'MAX',
  'sp.component.resizable.min': 'MIN',
  // #endregion Resizable

  // #region BLOG
  // #region Settings
  'bg.settings_title': 'Blog',
  'bg.settings_description': 'Setup general settings for your blog.',
  'bg.settings_get-started-text-block':
    'Add blog to your website and start posting. Flexibly adjust blog and post layouts to fit your style.',
  'bg.settings_posts-url-format': 'Posts url format',
  'bg.settings_post-url-options_post-name': 'Post Name',
  'bg.settings_post-url-options_day-and-post-name': 'Day and Post Name',
  'bg.settings_post-url-options_month-and-post-name': 'Month and Post Name',
  'bg.settings_post-url-options_year-and-post-name': 'Year and Post Name',
  'bg.settings_comments-switcher_label': 'Comments',
  'bg.settings_comments-switcher_description':
    'Control how people comment on your posts.',
  'bg.settings_comments-sort-by_label': 'Sort by',
  'bg.settings_comments-sort-by_option_most-popular': 'Most Popular',
  'bg.settings_comments-sort-by_option_newest': 'Newest',
  'bg.settings_comments-sort-by_option_oldest': 'Oldest',
  'bg.settings_comments-limit_label': 'Number of comments',
  'bg.settings_enable-blog-btn-text': 'Enable blog',
  'bg.settings_disable-blog-btn-text': 'Disable blog',
  'bg.settings_disable-blog-confirm-modal_title': 'Disable blog',
  'bg.settings_override-setting-confirm-modal_title': 'Override Setting',
  'bg.settings_override-setting-confirm-modal_description':
    'Custom setting is set for one or multiple blog posts. Do you want to override it?',
  'bg.settings_disable-blog-confirm-modal_description':
    'This will disable the Blog including Blog page and all posts from your website. Continue?',
  'bg.post.title': 'Title',

  // #endregion Settings

  // TODO remove when toolbar will be placed into iframe
  // #region Add Feature Toolbar
  'se.af.heading': 'Heading',
  'se.af.paragraph': 'Text',
  'se.af.image': 'Photo',
  'se.af.unordered-list': 'Unordered List',
  'se.af.ordered-list': 'Ordered List',
  'se.af.blockquote': 'Blockquote',
  'se.af.divider': 'Divider',
  'se.af.spacer': 'Spacer',
  // #endrefion Add Feature Toolbar

  // #endregion BLOG

  // #region CSSROOT
  '--bucket-text': 'taken',
  '--add-photo-text': 'add photo',
  '--add-embed-text': 'add embed',
  '--view-auth-text': 'Close',
  '--btn-empty-text': 'Empty',
  '--edit-fake-text': 'Edit',
  '--enter-mail-text': 'Enter your e-mail',
  '--enter-address-text': 'Enter shipping address',
  '--form-error-text': 'Form cannot be empty.',
  '--unavailable-pic-text': 'Not available',
  '--unavailable-photo-text': 'Photo temporarily unavailable',
  '--remove-btn-text': 'Remove',
  '--remove-dom-price-text': 'remove',
  '--tb-replace-text': 'Replace',
  '--select-country-text': 'Select your country',
  '--space-text': 'space',
  '--dom-taken-text': 'taken',
  '--post-editor-placeholder': 'Start a new story here',
  // #endregion CSSROOT

  // #sidebar products
  'se.es.product_main_label': 'Products',
  'se.es.product_main_info':
    'View, add and manage all your store products and categories.',

  'se.es.products_label_total': '{{total}} Product',
  'se.es.products_label_total_plural': '{{total}} Products',
  'se.es.products_button_add': 'Add',
  'se.es.products_button_all': 'All products',
  'se.es.products_empty_text': 'You have no products',
  'se.es.products_empty_category_text': 'This category is empty',
  'se.es.products_empty_store_text': 'Your store is empty',
  'se.es.product_item_menu_edit': 'Edit',
  'se.es.product_item_menu_hide': 'Hide',
  'se.es.product_item_menu_show': 'Show',
  'se.es.product_item_menu_delete': 'Delete',
  'se.es.product_delete_confirm_title': 'Delete Product',
  'se.es.product_delete_confirm_message':
    'Deleted products cannot be recovered. Do you still want to continue?',
  'se.es.product_error_modal_def_title': 'Error',
  'se.es.products_categories_select_placeholder': 'Select category',
  'se.es.products_categories_title_manage': 'Manage Category',
  'se.es.products_categories_label': 'Categories',
  'se.es.products_categories_button': 'Manage',
  'se.es.products_category_default': 'All Products',
  'se.es.product_error_price-required': 'Price is required.',
  'se.es.product_error_price-number': 'Please enter a real price.',
  'se.es.product_error_price-length':
    'Price must be less than 1 000 000 000.',
  'se.es.product_error_weight-number': 'Please enter a real weight.',
  'se.es.product_error_weight-length': 'Max weight is 1 000 000 000.',
  'se.es.dropzone_label_drag-drop': 'DRAG & DROP',
  'se.es.dropzone_label_image': 'image',
  'se.es.dropzone_label_file': 'file',
  'se.es.dropzone_label_or': 'or',
  'se.es.dropzone_label_browse': 'browse',
  'se.es.dropzone_call_action_drop_typed': 'Drop your {{type}} here',
  'se.es.dropzone_error_incorrect': 'Incorrect {{type}}',
  'se.es.dropzone_upgrade_title': 'Enable Channels',
  'se.es.dropzone_upgrade_description':
    'Your products will appear when someone search for your products on the Internet.',
  'se.es.dropzone_upgrade_err_title': 'Error',
  'se.es.dropzone_upgrade_err_message': 'Sorry. Something went wrong. Please try later.',

  'se.es.fileuploader_error_def': 'Incorrect File',

  'se.pb.title': 'All products',
  'se.pb.button_add': 'Add product',
  'se.pb.button_show': 'Show',
  'se.pb.button_hide': 'Hide',
  'se.pb.button_delete': 'Delete',
  'se.pb.button_enable': 'Show',
  'se.pb.button_disable': 'Hide',
  'se.pb.label_show_products_cnt': 'Show {{count}} Product',
  'se.pb.label_show_products_cnt_plural': 'Show {{count}} Products',
  'se.pb.header_product': 'Product',
  'se.pb.header_catalog': 'Catalog',
  'se.pb.header_inventory': 'Inventory',
  'se.pb.header_category': 'Category',
  'se.pb.header_sku': 'Sku',
  'se.pb.header_price': 'Price',
  'se.pb.category_all_products': 'All Products',

  'se.pc.paginate.page': 'Page',
  'se.pc.paginate.show_of':
    'Showing {{first}} to {{last}} of {{total}} entries',
  'se.pc.forward_button.active': 'active',
  'se.pc.forward_button.disabled': 'disabled',

  'se.mp.button_save': 'Save',
  'se.mp.save-to-apply': 'Save product to apply changes',
  'se.mp.button_cancel': 'Cancel',
  'se.mp.tab_label_general': 'Product',
  'se.mp.tab_label_options': 'Variants',
  'se.mp.tab_label_gallery': 'Photos',
  'se.mp.tab_label_files': 'Files',
  'se.mp.general_label_add_photo': 'Add photo',
  'se.mp.general_label_name': 'Name',
  'se.mp.general_label_sku': 'SKU',
  'se.mp.general_label_price': 'Price',
  'se.mp.general_label_category': 'Category',
  'se.mp.general_default_category_name': 'All Products',
  'se.mp.general_label_on-sale': 'On sale',
  'se.mp.general_label_description': 'Description',
  'se.mp.general_label_availability': 'Product availability',
  'se.mp.general_label_in-stock': 'in stock',
  'se.mp.general_label_out-of-stock': 'out of stock',
  'se.mp.general_label_require-shipping': 'require shipping',
  'se.mp.general_label_kg': 'kg',
  'se.mp.general_label_centimeter': 'cm',
  'se.mp.general_label_inch': 'in',
  'se.mp.general_label_items': 'items',
  'se.mp.general_label_pound': 'lb',
  'se.mp.general_label_stock-control': 'stock control',
  'se.mp.general_label_quantity': 'Items',
  'se.mp.general_label_upload': 'Upload',

  'se.mp.general_label_width': 'Width',
  'se.mp.general_label_height': 'Height',
  'se.mp.general_label_length': 'Length',
  'se.mp.general_label_stock_control': 'Stock control',
  'se.mp.general_err_img-size':
    'Your image is too big! Max available photo size is 15MB',
  'se.mp.general_err_img-type':
    'Please upload .jpg, .png, .jpeg, .gif, .bmp.',
  'se.mp.general_err_img-incorrect': 'Please upload .jpg, .png, .jpeg, .gif, .bmp.',
  'se.mp.general_err_file-size':
    'Sorry, your file is too big! Max available file size is 25MB',
  'se.mp.general_err_file-type':
    'Please upload a real document. Use .zip, .rar, .jpg, .png, .doc, .csv, etc.',
  'se.mp.general_err_failed-process': 'Failed process product image.',
  'se.mp.general_category-selected': 'Selected in {{categories}} categories',
  'se.mp.options_label_add-variant': 'Add variant',
  'se.mp.options_info_text':
    'If this product comes in multiple size variations or colors you can set these options here.',
  'se.mp.cant_close': 'Modal cannot be closed. Save before closing',

  'se.mp.options_col_label-variant': 'Variant',
  'se.mp.options_col_label-options': 'Options',
  'se.mp.options_col_label-type': 'Type',
  'se.mp.options_title': 'Product Variants',
  'se.mp.options_const_options-title': 'Add variant',
  'se.mp.options_const_types-select': 'Select',
  'se.mp.options_const_types-radio': 'Radio',
  'se.mp.options_const_types-checkbox': 'Checkbox',
  'se.mp.options_const_option-name-required': 'Variant name is required.',
  'se.mp.options_const_choice-name-required': 'Option name is required.',
  'se.mp.options_const_choice-first-param-required':
    'First param is required.',
  'se.mp.options_const_choice-price-negative':
    'This option makes product price negative.',
  'se.mp.options_const_option-duplicate': 'This variant is already in use.',
  'se.mp.options_const_choice-duplicate': 'This options is already in use.',

  'se.mp.options_option': 'Option',

  'se.mp.options_const_size_extra-small': 'Extra Small',
  'se.mp.options_const_size_extra-large': 'Extra Large',
  'se.mp.options_const_size_double-extra': 'Double Extra',

  'se.mp.options_const_color_brown': 'Brown',
  'se.mp.options_const_color_red': 'Red',
  'se.mp.options_const_color_orange': 'Orange',
  'se.mp.options_const_color_yellow': 'Yellow',
  'se.mp.options_const_color_green': 'Green',
  'se.mp.options_const_color_blue': 'Blue',
  'se.mp.options_const_color_indigo': 'Indigo',
  'se.mp.options_const_color_violet': 'Violet',

  'se.mp.options_const_material_down': 'Down',
  'se.mp.options_const_material_fur': 'Fur',
  'se.mp.options_const_material_nylon': 'Nylon',
  'se.mp.options_const_material_spandex': 'Spandex',

  'se.mp.options_form_label-title': 'Title',
  'se.mp.options_form_label-type': 'Type',
  'se.mp.options_form_label-option': 'Option',
  'se.mp.options_form_label-price-modifier': 'Price modifier',
  'se.mp.options_form_label-amount': 'Amount',
  'se.mp.options_form_label-default': 'Default',
  'se.mp.options_form_label-add-options': 'Add options',
  'se.mp.options_form_new-choice-name': 'Option',
  'se.mp.options_button_save': 'Save',
  'se.mp.options_button_cancel': 'Cancel',
  'se.mp.options_button_edit': 'Edit',
  'se.mp.options_predef-name-size': 'Size',
  'se.mp.options_predef-name-colour': 'Color',
  'se.mp.options_predef-name-material': 'Material',
  'se.mp.options_predef-name-condition': 'Condition',
  'se.mp.options_predef-name-width': 'Width',
  'se.mp.options_predef-name-custom': 'Custom',
  'se.mp.options_predef-name-size-small': 'Small',
  'se.mp.options_predef-name-size-medium': 'Medium',
  'se.mp.options_predef-name-size-large': 'Large',
  'se.mp.options_predef-name-colour-white': 'White',
  'se.mp.options_predef-name-colour-grey': 'Grey',
  'se.mp.options_predef-name-colour-dark-grey': 'Dark Grey',
  'se.mp.options_predef-name-colour-black': 'Black',
  'se.mp.options_predef-name-material-fabric': 'Fabric',
  'se.mp.options_predef-name-material-denim': 'Denim',
  'se.mp.options_predef-name-material-leather': 'Leather',
  'se.mp.options_predef-name-material-polyester': 'Polyester',
  'se.mp.options_predef-name-condition-new': 'New',
  'se.mp.options_predef-name-condition-used': 'Used',
  'se.mp.options_predef-name-width-narrow': 'Narrow',
  'se.mp.options_predef-name-width-regular': 'Regular',
  'se.mp.options_predef-name-width-wide': 'Wide',
  'se.mp.options_predef-name-width-extra-wide': 'Extra Wide',
  'se.mp.options_predef-name-custom-option1': 'Option 1',
  'se.mp.options_predef-name-custom-option2': 'Option 2',
  'se.mp.error_title_def': 'Error',
  'se.mp.error_message_def':
    'Sorry, something went wrong. We are working to resolve this problem. Please try again later.',
  'se.mp.error_title_wrong_data': 'Incorrect data',
  'se.mp.error_title_prod-del': 'Product Already Deleted',
  'se.mp.error_message_prod-del':
    'This product already deleted from your Online Store.',
  'se.mp.error_message_name-emapty': 'Product name required.',
  'se.mp.error_message_price-compare-larger':
    'CompareToPrice should be greater than product price.',
  'se.mp.error_message_price-negative':
    'Some variants make product price negative.',
  'se.mp.error_message_description-too-long':
    'The description is too long (maximum is 10 000 characters).',
  'se.mp.error_message_upload-image':
    'Sorry, the image cannot be uploaded.\nPlease contact our customer support team for assistance.',

  'se.mp.files_error_title_def': 'Error',
  'se.mp.files_error_message_def':
    'Sorry, something went wrong. We are working to resolve this problem. Please try again later.',
  'se.mp.files_error_deleted_title': 'Error',
  'se.mp.files_error_failed-process': 'Failed process files.',
  'se.mp.files_error_failed-delete': 'Failed delete file.',
  'se.mp.files_error_failed-upload': 'Failed upload file.',
  'se.mp.files_error_deleted_message':
    'This product already deleted from your Online Store.',
  'se.mp.files_info-text':
  // eslint-disable-next-line no-useless-concat
    'You can drop multiple PNGs, JPEGs or\n' + '\t\t\t\t\t\t\t\t\t\tGIFs',
  'se.mp.files_title': 'Files',
  'se.mp.files_description': 'Upload files.',
  'se.mp.files_error_type': 'Incorrect file type.',
  'se.mp.files_error_size': 'Max size 20MB.',
  'se.mp.files_ready-to-upload': 'Ready to upload',
  'se.mp.files_marked-for-delete': 'Marked for delete',

  'se.mp.gallery_title': 'Gallery',
  'se.mp.gallery_ready-to-upload': 'Ready to upload',
  'se.mp.gallery_marked-for-delete': 'Marked for delete',
  'se.mp.gallery_primary': 'Primary photo',
  'se.mp.gallery_set-primary': 'Make primary',
  'se.mp.gallery_info-text':
  // eslint-disable-next-line no-useless-concat
    'You can drop multiple PNGs, JPEGs or\n' + '\t\t\t\t\t\t\t\t\t\tGIFs',
  'se.mp.gallery_error_incorrect_title': 'We didn\'t recognize this file',
  'se.mp.gallery_error_incorrect_message':
    'Please upload a real photo. Use .jpg, .png, .jpeg, .gif, .bmp.',
  'se.mp.gallery_error_type_title': 'Not Supported',
  'se.mp.gallery_error_type_message':
    'Please upload .jpg, .png, .jpeg, .gif, .bmp.',
  'se.mp.gallery_error_size_title': 'Sorry, your image is too big!',
  'se.mp.gallery_error_size_message': 'Max available photo size is 20MB',
  'se.mp.files_error_type_title': 'Not Supported',
  'se.mp.files_error_type_message':
    'Please, upload a real document. Use .zip, .rar, .jpg, .png, .doc, .csv, etc.',
  'se.mp.files_error_size_title': 'Sorry, your file is too big!',
  'se.mp.files_error_size_message': 'Max available file size is 25MB',
  'se.mp.gallery_error_failed-save-general': 'Failed save',
  'se.mp.gallery_error_failed-save-image': 'Failed save gallery image.',
  'se.mp.gallery_error_failed-delete-image': 'Failed delete gallery image.',
  'se.mp.gallery_error_failed-upload-image': 'Failed upload gallery image.',

  'se.mp.err-types_required_name': 'Please enter your product name.',
  'se.mp.err-types_name_length':
    'The name is too long (maximum is 255 characters).',
  'se.mp.err-types_required_price': 'Please enter a real price.',
  'se.mp.err-types_required_prise-number': 'Please enter a real price.',
  'se.mp.err-types_required_prise-length':
    'Price must be less than 1 000 000 000.',
  'se.mp.err-types_required_weight-number': 'Please enter a real weight.',
  'se.mp.err-types_required_weight-length': 'Max weight is 1 000 000 000.',
  'se.mp.err-types_required_width-number': 'Please enter a real width.',
  'se.mp.err-types_required_width-length': 'Max width is 1000.',
  'se.mp.err-types_required_height-number': 'Please enter a real height.',
  'se.mp.err-types_required_height-length': 'Max height is 1000.',
  'se.mp.err-types_required_length-number': 'Please enter a real length.',
  'se.mp.err-types_required_length-length': 'Max length is 1000.',
  'se.mp.err-types_required_quantity-number': 'Please enter a real quantity.',
  'se.mp.err-types_required_quantity-length':
    'Max quantity is 1 000 000 000.',
  'se.mp.err-types_required_quantity-integer':
    'Only  integer quantity allowed.',

  'se.mp.err-types_required_sku': 'Please enter a product SKU.',
  'se.mp.err-types_wrong-value_price':
    'On Sale price should be less than price.',
  'se.mp.err-types_wrong-value_format': 'Wrong format.',
  'se.mp.err-types_wrong-value_format_max': 'Maximum 32 characters.',
  'se.mp.err-types_wrong-value_format_min': 'Minimum 2 characters.',
  'se.mp.err-types_wrong-value_format_char':
    'Special characters not allowed.',
  'se.mp.err-types_wrong-sku_duplicate': 'This SKU is already in use.',
  'se.mp.err-types_wrong-price': 'Some variants make product price negative.',

  'se.mp.err_wrong-image-size': 'File size exceeds allowed limit 20MB',
  'se.mp.err_bad-image':
    'File extension not allowed. Please use .jpeg, .png, .bmp, .jpg, .gif.',
  'se.mp.err_failed-save-product': 'Failed save product.',

  'se.mp.limit_products-title': 'Products Limit Reached',
  'se.mp.limit_products-message':
    'You\'ve reached your Online Store Products limit of {{productsLimit}}.',
  'se.mp.limit_products-message-light':
    'You\'ve reached your Online Store Products limit.',

  // #region HELP CENTER
  'hc.help-btn': 'Help',
  'hc.head-title': 'How can we help you?',
  'hc.head-search_placeholder': 'Search guides and tutorials',
  'hc.thank-item_title': 'Message sent',
  'hc.thank-item_text':
    'Thanks for reaching out! Our support engineers will contact you shortly',
  'hc.thank-item_back-btn': 'Back to search',
  'hc.search-res_title_top-suggestions': 'Top suggestions',
  'hc.search-res_title': 'Search results',
  'hc.search-res_no-res': 'No results found for',
  'hc.email-support_btn': 'Email support',
  'hc.email-form_title': 'Please select a topic and send us a message for further assistance.',
  'hc.email-form_send-btn': 'Send',
  'hc.email-form_attach-file-btn': 'Attach a file',
  'hc.email-form_back-btn': 'Back to help',
  'hc.email-form_subj-placeholder': 'Subject',
  'hc.email-form_topic-select-placeholder': 'Select a topic',
  'hc.email-form_text-placeholder': 'How can we help you?',
  'hc.email-form_select-option_member-account': 'Member Account',
  'hc.email-form_select-option_editor': 'Site Editor',
  'hc.email-form_select-option_domain-management': 'Domain Management',
  'hc.email-form_select-option_published-site': 'Published Site',
  'hc.email-form_select-option_other': 'Other',
  'hc.email-form_error-subject': 'Please enter your subject.',
  'hc.email-form_error-text': 'Please enter your message.',
  // #endregion HELP CENTER

  // #region MINISITE
  'se.sb.head-title': 'How can we help you?',
  // #endregion MINISITE

  // #region COUPONS_DISCOUNTS
  'se.es.discounts-menu_title': 'Discounts',
  'se.es.discounts-menu_desc':
    'Offer your customers a monetary, percentage, or shipping discount on products in your online store.',
  'se.es.discounts-list_title': 'Discount coupons',
  'es.es.button-title_add_coupon': 'Add',
  'se.es.ecwid-modal_manage_discounts_title': 'Manage discounts',
  'se.es.discounts-list_desc':
    'Boost sales and reward your loyal customers with discount coupons.',
  'se.es.coupon_single': 'Coupon',
  'se.es.add-coupon_single': 'Add Coupon',
  'se.es.coupon_multiple': 'Coupons',
  'se.es.manage_discounts_title': 'Volume discounts',
  'se.es.manage_discounts_desc': 'Manage discounts based on subtotal.',
  'se.es.manage-btn_label': 'Manage',
  'se.es.all_coupons_label': 'All coupons',
  'se.es.discount-types_discount': 'Discount (value)',
  'se.es.discount-types_shipping': 'Free shipping',
  'se.es.discount_types_additional_desc': 'off entire order',
  'se.es.discount-types_shipping_and_discount': 'Free shipping + Discount',
  'se.es.discounts_confirm-popup_error': 'Error',
  'se.es.discounts_menu-list-item_dropdown_edit': 'edit',
  'se.es.discounts_menu-list-item_dropdown_pause': 'pause',
  'se.es.discounts_menu-list-item_dropdown_unpause': 'unpause',
  'se.es.discounts_menu-list-item_dropdown_delete': 'delete',
  // #endregion COUPONS_DISCOUNTS

  // #region COUPON_MODAL
  'se.es.coupon_limited-by_categories': 'Categories',
  'se.es.coupon_limited-by_products': 'Products',
  'se.es.coupon_select-option_all_products': 'All Products',
  'se.es.coupon-modal_multy-select_title': 'Limited by',
  'se.es.coupon-modal_multy-select_all_products': 'All products',
  'se.es.coupon-modal-label': 'Coupon',
  'se.es.coupon-modal-label_new': 'New Coupon',
  'se.es.coupon-modal_cancel_btn': 'Cancel',
  'se.es.coupon-modal_save_btn': 'Save',
  'se.es.coupon-modal_discount_code_title': 'Discount code',
  'se.es.coupon-modal_discount_title': 'Discount',
  'se.es.coupon-modal_modifier_title': 'Modifier',
  'se.es.coupon-modal_amount_title': 'Amount',
  'se.es.coupon-modal_start_date_title': 'Start date',
  'se.es.coupon-modal_start_date_info': 'Select Start date',
  'se.es.coupon-modal_end_date_title': 'End date',
  'se.es.coupon-modal_end_date_info': 'Select End date',
  'se.es.coupon-modal_applies_to_title': 'Applies to',
  'se.es.coupon-modal_all_orders_title': 'All orders',
  'se.es.coupon-modal_subtotal_placeholder': 'Subtotal over',
  'se.es.coupon-modal_date_picker_placeholder': 'Set date',
  'se.es.coupon-modal_code_required': 'Coupon code is required.',
  'se.es.coupon-modal_code_too_short': 'Your coupon code is too short.',
  'se.es.coupon-modal_code_too_long': 'Your coupon code is too long.',
  'se.es.coupon-modal_already_exists': 'This code is already in use.',
  'se.es.coupon-modal_limit_name_products': 'products',
  'se.es.coupon-modal_limit_name_categories': 'categories',
  // #endregion COUPON_MODAL

  // #region ORDERS
  'se.es.orders_ecwid-modal-title': 'Orders',
  'se.es.orders_list-title': 'Orders',
  'se.es.orders_list-desc':
    'Receive and manage all your customer orders here.',
  'se.es.orders_store_summary': 'Store summary',
  'se.es.orders_total_sales': 'Total sales',
  'se.es.orders_order_fulfillment': 'Order fulfillment',
  'se.es.orders_order_fulfillment_desc': 'You need to process these orders.',
  'se.es.orders_store_summary-title': 'Order fulfillment',
  'se.es.order-types_not_paid_title': 'Not Paid. Not Shipped.',
  'se.es.order-types_not_paid_desc':
    'You have not shipped or fulfilled these orders yet.',
  'se.es.order-types_paid_not_shipped_title': 'Paid. Not Shipped.',
  'se.es.order-types_paid_not_shipped_desc':
    'You have partially fulfilled these orders, but not shipped yet.',
  'se.es.order-types_not_paid_shipped_title': 'Not Paid. Shipped',
  'se.es.order-types_not_paid_shipped_desc':
    'The shipment is in transit, but these orders not paid yet.',
  'se.es.order-ecwid_loading_title': 'Checking & Updating',
  'se.es.order-alert_new_comes_in':
    'Get notified by email whenever a new order comes in.',
  // #endregion ORDERS

  // #region TAXES
  'se.es.taxes_menu-title': 'Taxes',
  'se.es.taxes_menu-desc':
    'Change the way taxes are calculated on your online store.',
  'se.es.taxes_list-title': 'Tax rates',
  'se.es.taxes_list-desc':
    'A list of all the tax rates for the countries you ship to.',
  'se.es.taxes-item_multiple': 'taxes',
  'se.es.taxes-manage_zones_title': 'Zones',
  'se.es.taxes-manage_zones_desc':
    'Set the shipping zones to include the regions that you ship to.',
  'se.es.taxes-manage_zones_btn': 'Manage zones',
  'se.es.taxes-manage_taxes_btn': 'Manage taxes',
  'se.es.taxes_default_label': 'All zones',
  'se.es.taxes_manage_zones_iframe_title': 'Manage Zones',
  'se.es.taxes_manage_taxes_iframe_title': 'Manage Taxes',
  'se.es.empty_list_part': 'You have no',
  // #endregion TAXES

  // #region BULK COUPONS
  'se.es.bulk_coupons_title': 'All Coupons',
  'se.es.bulk_coupons_pause_btn': 'PAUSE',
  'se.es.bulk_coupons_unpause_btn': 'UNPAUSE',
  'se.es.bulk_coupons_delete_btn': 'DELETE',
  'se.es.coupons_table_header_coupon': 'coupon',
  'se.es.coupons_table_header_status': 'status',
  'se.es.coupons_table_header_used': 'used',
  'se.es.coupons_table_header_start': 'start',
  'se.es.coupons_table_header_end': 'end',
  'se.es.coupons_table_item_status_active': 'ACTIVE',
  'se.es.coupons_table_item_status_paused': 'PAUSED',
  'se.es.coupons_table_item_status_expired': 'EXPIRED',
  'se.es.coupons_table_item_status_usedup': 'USEDUP',
  // #endregion BULK COUPONS

  // #region CATEGORIES
  'se.es.categories_manage_btn': 'Manage',
  'se.es.categories_sidebar_section-label': 'Categories',
  'se.es.categories_category_display-type_manual': 'Manual',
  'se.es.categories_category_display-type_auto': 'Automatic',
  'se.es.categories_manage_modal-title': 'Manage categories',
  'se.es.categories_manage_modal_add_category_btn': 'Add category',
  'se.es.categories_manage_modal_delete_single_btn': 'Delete',
  'se.es.categories_manage_modal_delete_multi_btn': 'Delete',
  'se.es.categories_manage_modal_filter-all': 'All',
  'se.es.categories_manage_modal_filter-empty': 'Empty',
  'se.es.categories_manage_modal_filter-n/a': 'Disabled',
  'se.es.categories_manage_modal_header_category': 'Category',
  'se.es.categories_manage_modal_header_descriptions': 'Description',
  'se.es.categories_manage_modal_header_products': 'Products',
  'se.es.categories_manage_modal_header_used_on_site': 'Used on site',
  'se.es.categories_manage_modal_header_availability': 'Availability',
  'se.es.categories_manage_modal_empty_list': 'There are no categories yet',
  'se.es.categories_single_modal_edit_title': 'Edit category',
  'se.es.categories_single_modal_create_title': 'Add category',
  'se.es.categories_single_modal_tab-details': 'Details',
  'se.es.categories_single_modal_tab-products': 'Products',
  'se.es.categories_single_modal_name-label': 'Title',
  // 'se.es.categories_single_modal_description-hint':
  // 'Type a two- or three-word description of your item to get category suggestions that will help
  // more shoppers find it.',
  'se.es.categories_single_modal_description-hint':
    'Products in your online store are grouped by categories, which makes them easier to find.',
  'se.es.categories_single_modal_description-label': 'Description',
  'se.es.categories_single_modal_cancel-btn': 'Cancel',
  'se.es.categories_single_modal_save-btn': 'Save',
  'se.es.categories_single_modal_add-photo': 'Add photo',
  'se.es.categories_single_modal_upload': 'Upload',
  'se.es.categories_single_modal_category-type_label': 'Category type',
  'se.es.categories_single_modal_category-type_placeholder': 'Select...',
  'se.es.categories_single_modal_sort-by_label': 'Sort by',
  'se.es.categories_single_modal_sort-by_placeholder': 'Select...',
  'se.es.categories_single_modal_sort-by_default': 'Store Default',
  'se.es.categories_single_modal_sort-by_name-asc': 'Name A-Z',
  'se.es.categories_single_modal_sort-by_name-desc': 'Name Z-A',
  'se.es.categories_single_modal_sort-by_highest-price': 'Highest Price',
  'se.es.categories_single_modal_sort-by_lowest-price': 'Lowest Price',
  'se.es.categories_single_modal_sort-by_newest': 'Newest',
  'se.es.categories_single_modal_sort-by_oldest': 'Oldest',
  'se.es.categories_single_modal_empty_products-list': 'No Products Yet',
  'se.es.categories_single_modal_title_is_required':
    'Please enter your category name.',
  'se.es.categories_single_modal_title_max_len':
    'Maximum title length is 255 characters.',
  'se.es.categories_single_modal_description_max_len':
    'The description is too long (maximum is 10 000 characters).',
  'se.es.categories_single_modal_wrong_image_ext': 'Wrong image extension.',
  'se.es.categories_single_modal_wrong_image_size':
    'File size exceeds allowed limit 20MB',
  'se.es.categories_single_modal_select_products-btn': 'Select products',
  'se.es.categories_single_modal_products-add_hint':
    'To save, please add a category',
  'se.es.categories_products_select_modal_title': 'Select products',
  'se.es.categories_products_select_modal_filter-placeholder':
    'Search for products',
  'se.es.categories_products_select_modal_cancel-btn': 'Cancel',
  'se.es.categories_products_select_modal_done-btn': 'Done',
  'se.es.categories_incorrect_image': 'Please upload .jpg, .png, .jpeg, .gif, .bmp.',
  'se.es.categories-ecwid_loading_title': 'Loading Categories',
  // #endregion CATEGORIES

  // #region LABELS
  'se.es.in_stock_text': 'in stock',
  'se.es.in_stock_text_infinity': 'N/A',
  // #endregion LABELS

  // #region BLOG SIDEBAR
  'se.bs.title': 'Blog',
  'se.bs.description': 'View, add and manage all your blog posts and tags.',
  'se.bs.list_title': 'Your posts',
  'se.bs.list_add': 'Add',
  'se.bs.list_published': 'Published',
  'se.bs.list_drafts': 'Drafts',
  'se.bs.list_scheduled': 'Scheduled',
  'se.bs.list_last-edited': 'Last edited',
  'se.bs.list_no-posts': 'There are no posts yet',
  'se.bs.list-item_menu_edit': 'Edit',
  'se.bs.list-item_menu_publish': 'Publish',
  'se.bs.list-item_menu_unpublish': 'Unpublish',
  'se.bs.list-item_menu_delete': 'Delete',
  'se.bs.modal_title': 'Add/Edit Post',
  'se.bs.modal_button-preview': 'Preview',
  'se.bs.modal_button-publish': 'Publish now',
  'se.bs.modal_button-tooltip_go-live': 'Go live',
  'se.bs.modal_button-tooltip_up-to-date': 'All up to date',
  'se.bs.modal_button-tooltip_how-it-looks': 'See how it looks',
  'se.bs.modal_form_post-title': 'Your Post Title',
  'se.bs.modal_form-error_title-required': 'This field is required',
  'se.bs.modal_form-error_title-max-length': 'Max length is {{n}} symbols',
  'se.bs.confirm_delete_title': 'Delete Post',
  'se.bs.confirm_delete_text':
    'Are you sure you want to delete this post? Deleted posts are gone forever.',
  'se.bs.confirm_unpublish_title': 'Unpublish Post',
  'se.bs.confirm_unpublish_text':
    'Make your post unavailable. It will not be found using a search engine.',
  'se.bs.confirm_save_title': 'Confirm save',
  'se.bs.confirm_save_text': 'Do you want to save it?',
  'se.bs.confirm_publish_title': 'Confirm publish',
  'se.bs.confirm_publish_text': 'Do you want to publish changes?',
  'se.bs.confirm-cancel_open-title': 'Cancel opening',
  'se.bs.confirm-cancel_open-text':
    'Do you really want to cancel post opening?',
  'se.bs.modal_status_draft': 'Draft',
  'se.bs.modal_status_published': 'Published',
  'se.bs.modal_status_unpublished-changes': 'Unpublished changes',
  // #endregion BLOG SIDEBAR

  // #region POST SETTINGS
  'se.pe.post_settings-title': 'Post Settings',
  'se.pe.post_settings-tags': 'Tags',
  'se.pe.post_settings-tags_input-placeholder':
    'Start typing, hit comma, tab or return to complete.',
  'se.pe.post_settings-tags_add-option-text': 'Add',
  'se.pe.post_settings-tags_description':
    'Add up to {{limit}} tags so visitors know what your post is about.',
  'se.pe.post_settings-tags_placeholder':
    'Start typing, hit comma, tab or return to complete.',
  'se.pe.post_settings-tags-validation_text':
    'Special characters are not allowed.',
  'se.pe.post_settings-tags-validation_length':
    'The name is too long (maximum is 40 characters).',
  'se.pe.post_settings-preview_photo': 'Preview Photo',
  'se.pe.post_settings-preview_photo_placeholder': 'Upload Image',
  'se.pe.post_settings-preview_photo_upload': 'Change Preview',
  'se.pe.post_settings-display_cover_photo': 'Display Cover Photo',
  'se.pe.post_settings-display_cover_photo_description':
    'Choose whether to show cover photo for this post.',
  'se.pe.post_settings-excerpt': 'Excerpt',
  'se.pe.post_settings-excerpt_placeholder': 'Add custom excerpt here.',
  'se.pe.post_settings-social_sharing_buttons': 'Social Sharing Buttons',
  'se.pe.post_settings-post_comments': 'Post Comments',
  // @endregion POST SETTINGS

  // #region ECOMMERCE PRODUCT DASH
  'se.wf.ecom_product_dash_shipping_price': 'Shippable',
  'se.wf.ecom_product_dash_shipping_none': 'Non Shippable',
  'se.wf.ecom_product_dash_shipping_free': 'Free Shipping',
  'se.wf.ecom_product_dash_shipping_local': 'Local Pickup',
  'se.wf.ecom_product_dash_shipping_locals': 'Local Pickups',
  'se.wf.ecom_product_dash_shipping_days': 'days',
  'se.wf.ecom_product_dash_shipping_hours': 'hours',
  // #endregion ECOMMERCE PRODUCT DASH

  // #region BOOKING
  'se.bo.booking_catalogue.category_all_products': 'All',
  'se.bo.booking_catalogue.button_text': 'Book now',
  'se.bo.booking_catalogue.design_hover_effect_highlight_border': 'Highlight border',

  'se.bo.booking_catalog.pagination_of': 'of',
  'se.bo.booking_catalog.pagination_items': 'items',
  'se.bo.booking_catalog.pagination_prev': 'Prev',
  'se.bo.booking_catalog.pagination_back': 'Back',
  'se.bo.booking_catalog.pagination_next': 'Next',

  'se.bo.booking_product_duration_prefix': 'mins',
  // #endregion BOOKING

  // COUNTDOWN
  'se.wf.countdown_times_up_message': 'Countdown finished!',
  // #end COUNTDOWN
};
